import { Button, Card, Comment, Popconfirm, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ProjectDashboardStore } from '../../stores';
import ReactQuill from 'react-quill';
import { Utils } from '../../../common/misc/Utils';
import './ProjectCommentsTable.less';
import { ProjectCommentData } from '../../types';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

type Props = {
    store: ProjectDashboardStore
};

const ProjectCommentsTable: React.FC<Props> = ({store}) => {
    const [newComment, setNewComment] = React.useState<string>('');
    const [editingCommentId, setEditingCommentId] = React.useState<string | undefined>(undefined);
    const [editingCommentText, setEditingCommentText] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        store.getProjectComments();
        return ()=> {
            setNewComment('');
        };
    }, [store, store.projectId]);

    React.useEffect(() => {
        setNewComment('');
    }, [store.isLoadingProjectComments]);

    const getCommentActions = (comment: ProjectCommentData) => {
        if (editingCommentId === comment.id) {
            return [];
        }

        if (comment.createdByUserId !== store.currentUserId) {
            return [];
        }

        return [
            <Button
                data-id="edit-comment-button"
                key="edit-comment-button"
                type="link"
                size='small'
                onClick={() => editComment(comment)}
            >
                <EditOutlined />
            </Button>,
            <Popconfirm 
                key="delete-comment-button" 
                title="Are you sure you want to delete this comment?"
                onConfirm={() => store.deleteComment(comment.id)}      
            >
                <Button
                    data-id="delete-comment-button"
                    
                    type="link"
                    size='small'
                >
                    <DeleteOutlined />
                </Button>
            </Popconfirm>
            
        ];
    };

    const resetCommentEditing = () => {
        setEditingCommentId(undefined);
        setEditingCommentText(undefined);
    };

    const editComment = (comment: ProjectCommentData) => {
        setEditingCommentId(comment.id);
        setEditingCommentText(comment.text);
    };

    const getCommentContent = (comment: ProjectCommentData) => {
        if (editingCommentId === comment.id) {
            return (
                <>
                    <ReactQuill
                        onChange={setEditingCommentText}
                        modules={Utils.getQuilModules()}
                        className={'comment-input alpha-quill'}
                        value={editingCommentText}
                    />
                    <div className="comment-button-container">
                        <Button
                            data-id="cancel-comment-button"
                            type="default"
                            size="large"
                            onClick={resetCommentEditing}
                        >
                            Cancel
                        </Button>

                        <Button
                            data-id="save-comment-button"
                            type="primary"
                            size="large"
                            disabled={!editingCommentText}
                            onClick={() => {
                                store.editComment(comment.id, editingCommentText || '').then(() => resetCommentEditing());
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </>
            );
        }

        return <div dangerouslySetInnerHTML={{__html: comment.text}} />;
    };

    const getCommentDate = (comment: ProjectCommentData) => {
        if (comment.updateDate) {
            return `Updated: ${Utils.formatDateStringShort(comment.updateDate)}`;
        }

        return Utils.formatDateStringShort(comment.createdTime);
    };

    const loadingContent = <Spin />;

    return (

        <Card
            data-id="Project-Comments"
            title="Comments"
            className="specifiaction-container project-comments-container"
            bordered={false}
        >
            <div className="comments-container">
                {store.isLoadingProjectComments ? loadingContent : store.projectComments.map(comment => (
                    <Comment
                        key={comment.id}
                        content={getCommentContent(comment)}
                        datetime={getCommentDate(comment)}
                        author={comment.createdByUserName}
                        actions={getCommentActions(comment)}
                    />
                ))}
            </div>
            <div className="new-comment-container">
                <ReactQuill
                    onChange={setNewComment}
                    modules={Utils.getQuilModules()}
                    className={'comment-input alpha-quill'}
                    value={newComment}
                /> 
                
                <div className="comment-button-container">
                    <Button
                        data-id="add-comment-button"
                        type="primary"
                        size="large"
                        disabled={!newComment || store.isLoadingProjectComments}
                        onClick={() => store.addCommentToProject(newComment)}
                    >
                        Add Comment
                    </Button>
                </div>
            </div>
        </Card>
    );
};

export default observer(ProjectCommentsTable);