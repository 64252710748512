import { Button, Card, message, Popconfirm, Table, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import './ProjectDocumentsTable.less';
import { ProjectDashboardStore } from '../../stores';
import { Utils } from '../../../common/misc/Utils';
import { ProjectDocumentData } from '../../types';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import Upload, { RcFile } from 'antd/lib/upload';

type Props = {
    store: ProjectDashboardStore
};

const ProjectDocumentsTable: React.FC<Props>= ({store}) => {
    React.useEffect(() => {
        store.getProjectOtherDocuments();
    }, [store, store.projectId]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string) => <span>{name}</span>
        },
        {
            title: 'Date Uploaded',
            dataIndex: 'uploadedTime',
            key: 'uploadedTime',
            render: (uploadedTime: string) => <span>{Utils.formatDateStringShort(uploadedTime)}</span>
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploadedBy',
            key: 'uploadedBy',
            render: (uploadedBy: string) => <span>{uploadedBy}</span>
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_: unknown, record: ProjectDocumentData) => {
                return (
                    <div className='actions'>
                        <Tooltip title='Download'>
                            <Button type='link' onClick={() => store.downloadDocument(record.id)}>
                                <DownloadOutlined/>
                            </Button>
                        </Tooltip>
                        <Tooltip title='Delete'>
                            <Popconfirm
                                title="Are you sure you want to delete this document?"
                                onConfirm={() => store.deleteOtherDocument(record.id)}
                            >
                                <Button type='link'>
                                    <DeleteOutlined/>
                                </Button>
                            </Popconfirm>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];

    const handleBeforeUpload = async(file: RcFile) => {
        const resp = await store.addDocumentToProject(file);

        if (resp && resp.isErr()) {
            message.error(resp.error.data?.title ?? 'Failed to upload document');
        }

        return false;
    };

    const getUploadButton = () => {
        return (
            <Upload
                showUploadList={false}
                beforeUpload={(file: RcFile) => handleBeforeUpload(file)}
                headers={store.headers}
            >
                <Button>
                    Upload document
                </Button>                                    
            </Upload>
        );
    };

    return (
        <Card
            data-id="Project-Documents"
            title="Other Documents"
            className="specifiaction-container"
            bordered={false}
            extra={getUploadButton()}
        >
            <div className='table-container-content'>
                <Table
                    className='roche-table specification-table'
                    columns={columns}
                    dataSource={store.projectDocuments}
                    pagination={false}
                    rowKey={r => r.id}
                    loading={store.isLoadingProjectDocuments}
                />
            </div>
        </Card>
    );
};

export default observer(ProjectDocumentsTable);