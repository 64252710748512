import React from 'react';
import './App.less';
import { configure } from 'mobx';
import MainLayout from './modules/common/components/MainLayout';
import { bootstrap } from './modules/common';
import { BrowserRouter } from 'react-router-dom';
import { context } from './modules/common/stores';
import { Route, Routes } from 'react-router';
import { ProjectDocumentViewer } from './modules/projects/screens';
import 'react-quill/dist/quill.snow.css';
import { message } from 'antd';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import service from './modules/common/services/SecurityService';
import { Utils } from './modules/common/misc/Utils';
configure({ enforceActions: 'observed' });
message.config({top: 60});
function App() {

    if (process.env.REACT_APP_MATOMO_URL && Utils.isValidHttpUrl(process.env.REACT_APP_MATOMO_URL) && Utils.isNumber(process.env.REACT_APP_MATOMO_SITE_ID)) {
        const trackerUrl = process.env.REACT_APP_MATOMO_URL.endsWith('/') 
            ? process.env.REACT_APP_MATOMO_URL + 'matomo.php' 
            : process.env.REACT_APP_MATOMO_URL + '/matomo.php';

        const instance = createInstance({
            urlBase: '/',
            siteId: Number(process.env.REACT_APP_MATOMO_SITE_ID),
            userId: service.preferredUsername,
            trackerUrl: trackerUrl,
            disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
            heartBeat: { // optional, enabled by default
                active: true, // optional, default value: true
                seconds: 10 // optional, default value: `15
            },
            linkTracking: false, // optional, default value: true
            configurations: { // optional, default value: {}
                // any valid matomo configuration, all below are optional
                disableCookies: true,
                setSecureCookie: true,
                setRequestMethod: 'POST'
            }
        });

        return (
            <MatomoProvider value={instance}>
                <bootstrap.StoreProvider>
                    <BrowserRouter>
                        <context.StoreProvider>
                            <Routes>
                                <Route
                                    path="/projects/:projectId/:docType/:documentId/:documentName"
                                    element={
                                        <ProjectDocumentViewer />
                                    }
                                />
                                <Route path='/*' element={<MainLayout/>} />
                            </Routes>
                        </context.StoreProvider>
                    </BrowserRouter>
                </bootstrap.StoreProvider>
            </MatomoProvider>
        );
    }


    return (
        <bootstrap.StoreProvider>
            <BrowserRouter>
                <context.StoreProvider>
                    <Routes>
                        <Route
                            path="/projects/:projectId/:docType/:documentId/:documentName"
                            element={
                                <ProjectDocumentViewer />
                            }
                        />
                        <Route path='/*' element={<MainLayout/>} />
                    </Routes>
                </context.StoreProvider>
            </BrowserRouter>
        </bootstrap.StoreProvider>
    );
}

export default App;
