import SpecficationType from '../../common/types/SpecificationType';
import { TopicsBySpec } from '../types/TopicsBySpecs';
import { action, computed, makeObservable, observable, runInAction, IReactionDisposer } from 'mobx';
import { SummaryReportTableModel } from '../components/SummaryReportView';
import { LotType, TopicFieldMeta, TopicLotResult, TopicLotResultData } from '../types/TopicLotResult';
import { ProjectsService } from '../../projects/services';
import { ProjectsStore } from '../../projects/stores';
import { DocumentStateChange } from '../../common/types/DocumentStateChange';
import { PackageState } from '../../common/types/PackageState';
import SummaryReportService from '../services/SummaryReportService';
import CommentStore from '../../common/stores/CommentsStore';
import { CommentData, CellType } from '../../projects/types/CommentData';
import { Subject } from 'rxjs';
import { ReportStatus, SpecificationTypes } from '../../projects/types/ApplicationReport';
import { UserProfileStore } from '../../common/stores';
import ErrorStore from '../../common/stores/ErrorStore';
import { SpecificationTypeTopicsStatistics } from '../../projects/types/DocumentTopicsResult';
import { FinalResultParams } from '../types/SetFinalResult';
import { MethodSheetSummaryData } from '../../method_sheet_report/models/types';
import MethodSheetReportService from '../../method_sheet_report/services/MethodSheetReportService';
import ResultHelpers from '../../common/misc/ResultHelpers';
import _ from 'lodash';
import { message } from 'antd';

type TableData = {
    [key: string]: SummaryReportTableModel[]
};

type SpecDocMapping = {
    [specTypeId: string]: string
};

type SelectedDocumentInfo = {
    documentId: string;
    documentName: string;
    type: 'documents' | 'specification_documents'
};

type HighlightedBlockInfo = {
    fieldIndex: number;
    page: number;
    x: number;
    y: number;
    w: number;
    h: number;
    pageWidth: number;
    pageHeight: number;
    blockType: string;
    blockId: string
};

type Statistics = {
    [key: string]: SpecificationTypeTopicsStatistics
};

class SummaryReportStore {
    currentValue: string | null;

    editableTopic: string;

    currentDocId: string | undefined;

    specificationTypes: SpecficationType[];

    isLoading: boolean = true;

    projectId: string;

    currentSpecTypeId: string;

    tableData: TableData = {};

    // lots: TopicLotResult;

    currentCommentsTopic: string;

    selectedDocument: SelectedDocumentInfo | undefined = undefined;

    highlightedBlock: HighlightedBlockInfo | undefined = undefined;

    highlightedBlockSubject: Subject<HighlightedBlockInfo | undefined>;

    specDocMapping: SpecDocMapping = {};

    editableCellType: CellType;

    editableLotType: LotType;

    editableSection: string;

    editableSectionName: string;

    isConciseViewEnabled: boolean = false;

    activeSections: string[] = [];

    editedDataModalNewValue: string | null;

    editedDataModalComment: string;

    statistics: Statistics = {};

    sectionsInitiated: boolean = false;

    scrollPosition: number = 0;

    currentFinalResultParams: FinalResultParams;

    isPreviewCollapsed: boolean = false;

    methodSheetReportData: MethodSheetSummaryData;

    numberOfResultChanges: number = 0;

    reactions: IReactionDisposer[] = [];

    constructor(private projectService: ProjectsService, private projectStore: ProjectsStore, 
                private summaryReportService: SummaryReportService, private commentsStore: CommentStore, private errorStore: ErrorStore, 
                private mmthodSheetReportService: MethodSheetReportService, private userProfileStore?: UserProfileStore) {        
        makeObservable(this, {
            getInitialData: action.bound,
            setCurrentSpecType: action.bound,
            updateSpecification: action.bound,
            createComment: action.bound,
            highlightBlock: action.bound,
            setHighlightedBlock: action.bound,
            editCellData: action.bound,
            setConciseView: action.bound,
            setActiveSections: action.bound,
            handleDownloadReport: action.bound,
            setScrollPosition: action.bound,
            addNewComment: action.bound,
            setIsPreviewCollapsed: action.bound,
            setProjectId: action.bound,
            setEditedDataModalNewValue: action.bound,
            setCurrentDocId: action.bound,
            currentDocId: observable,
            currentSpecTypeId: observable,
            isLoading: observable,
            tableData: observable,
            projectId: observable,
            selectedDocument: observable,
            highlightedBlock: observable,
            isConciseViewEnabled: observable,
            activeSections: observable,
            editedDataModalComment: observable,
            statistics: observable,
            isPreviewCollapsed: observable,
            methodSheetReportData: observable,
            editedDataModalNewValue: observable,
            tableDataSource: computed,
            currentProject: computed,
            currentProjectResultRange: computed,
            currentSpecTypeHiddenParts: computed,
            hiddenSections: computed,
            tableDataSourceWithHiddenParts: computed
        });
        this.highlightedBlockSubject = new Subject<HighlightedBlockInfo | undefined>();
        this.projectStore.specificationDoc.subscribe(this.updateSpecification);
        this.projectStore.newCommentData.subscribe(this.addNewComment);
        this.projectStore.verificationDoc.subscribe(this.updateTableModel.bind(this));
        this.projectStore.documentTopicChange.subscribe(this.updateTableModel.bind(this));

        // this.setupResultChangeReaction();
    }

    get tableDataSource() {
        return this.tableData[this.currentSpecTypeId];
    }

    get protocolDocs() {
        let docs = this.tableData[SpecificationTypes.Protocol]?.map(t => t.protocolData.map(p => {
            return {
                documentId: p.documentId,
                documentName: p.documentName
            };
        })).flat();

        docs = _.uniqBy(docs, 'documentId');
        return docs;
    }

    get tableDataSourceWithHiddenParts() {
        if (!this.currentSpecTypeHiddenParts) {
            return this.tableDataSource;
        }

        return this.tableDataSource.filter(t=> !this.currentSpecTypeHiddenParts?.hiddenReportParts?.[t.sectionName]?.includes(t.topic));
    }

    get hiddenSections() {
        if (!this.currentSpecTypeHiddenParts) {
            return [];
        }

        let sectionsWithoutVisibleTopics: string[] = [];

        Object.keys(this.currentSpecTypeHiddenParts.hiddenReportParts ?? {}).forEach(s => {
            const topicsCount = [... new Set(this.tableDataSource.filter(t=> t.sectionName === s).map(t => t.topic))].length;
            if (this.currentSpecTypeHiddenParts?.hiddenReportParts?.[s]?.length === topicsCount) {
                sectionsWithoutVisibleTopics.push(s);
            } 
        });

        return sectionsWithoutVisibleTopics;
    }

    get currentSpecTypeHiddenParts() {
        if (!this.currentProject || !this.currentProject.hiddenReportParts || !this.currentSpecTypeId) {
            return undefined;
        }

        return this.currentProject.hiddenReportParts.find(p=> p.specificationTypeId === this.currentSpecTypeId);
    }

    get sections() {
        return Array.from(new Set(this.tableData[this.currentSpecTypeId]?.map(t=> t.sectionId)))
            .map(id=> ({sectionId: id, sectionName: this.tableData[this.currentSpecTypeId].find(t=> t.sectionId === id)?.sectionName }));
    }

    get currentProject() {
        return this.projectStore.projects.find(p=> p.id === this.projectId);
    }

    get currentProjectResultRange() {
        return ResultHelpers.getRange(this.currentProject);
    }
    
    // get reagentHasUnreadComment() {
    //     return this.hasUnreadComments(this.tableData[SpecificationTypes.Reagent]);
    // }

    // get calibratorsHasUnreadComment() {
    //     return this.hasUnreadComments(this.tableData[SpecificationTypes.Calibrators]);
    // }

    // get preciControlHasUnreadComment() {
    //     return this.hasUnreadComments(this.tableData[SpecificationTypes.PreciControl]);
    // }

    dispose() {
        this.reactions.forEach(disposer => disposer());
    }

    // setupResultChangeReaction() {
    //     this.reactions.push(
    //         reaction(
    //             () => this.currentProject?.resultCount,
    //             () => {
    //                 if (!this.currentProject?.resultCount) {
    //                     return;
    //                 }

    //                 if (this.numberOfResultChanges > 0) {
    //                     this.updateTableModel();
    //                 }

    //                 this.numberOfResultChanges++;
    //             }
    //         )
    //     );
    // }

    setCurrentDocId(id: string | undefined) {
        this.currentDocId = id;
    }

    setScrollPosition(position: number) {
        this.scrollPosition = position;
    }

    setIsPreviewCollapsed(val: boolean) {
        this.isPreviewCollapsed = val;
    }

    getSectionsForSpecType(specTypeId: string) {
        let sections = Array.from(new Set(this.tableData[specTypeId]?.map(t=> t.sectionId)))
            .map(id=> ({sectionId: id, sectionName: this.tableData[specTypeId].find(t=> t.sectionId === id)?.sectionName }));

        if (this.hiddenSections.length) {
            sections = sections.filter(s=> !this.hiddenSections.includes(s.sectionId) && !this.hiddenSections.includes(s.sectionName ?? ''));
        }
        
        return sections;
    }

    specDocExists() {
        return !!this.specDocMapping[this.currentSpecTypeId];
    }

    // getLotValues(section: string, topic: string, lotType: LotType, specTypeId: string, comments: CommentData[]) {
    //     const lotResult = this.lots.topicLotResultData.find((l: TopicLotResultData)  => 
    //         l.specificationTypeId === specTypeId && l.section == section && l.topic === topic && l.lotType === lotType);

    //     const lotValues = lotResult?.lotValue || null;
    //     const oldLotValues = lotResult?.oldLotValue || null;
    //     const isEdited = lotResult?.isEdited || false;
    //     const filteredComments = comments.filter(c=> c.specificationTypeId === specTypeId && c.topic === topic && c.lot === lotType && c.section === section);
    //     return {
    //         lotValue: lotValues, 
    //         oldLotValue: oldLotValues,
    //         isEdited: isEdited, 
    //         fieldMetas: lotResult?.lotFieldMetas,
    //         unavailable: lotResult?.documentReportStatus === ReportStatus.NotAvailable, 
    //         commentsCount: filteredComments.length,
    //         notReadCommentsCount: this.userProfileStore ?  filteredComments.filter(c=> !c.usersRead.includes(this.userProfileStore!.userInfo.userId)).length : 0,
    //         editComment: filteredComments.find(c=> c.id === lotResult?.editCommentId)?.text || '',
    //         isFinalResult: lotResult?.isResult || false
    //     };
    // }

    getSpecValues(
        section: string,
        topic: string, 
        value: string | null, 
        oldValue: string| null, 
        specTypeId: string, 
        comments: CommentData[], 
        fieldMetas: TopicFieldMeta[] | undefined, 
        documentId: string, 
        documentName: string,
        isEdited: boolean,
        editCommentId: string | null,
        overridingPackageId?: string
    ) {
        // TODO: Add document id to comments to filter it out further
        const filteredComments = comments.filter(c=> c.specificationTypeId === specTypeId && c.section == section &&
             c.topic === topic && c.commentType === CellType.Specification);
        // eslint-disable-next-line max-len
        return {
            specValue: value, 
            oldValue,
            commentsCount: filteredComments.length,
            fieldMetas,
            documentId,
            documentName,
            isEdited,
            editComment: filteredComments.find(c=> c.id === editCommentId)?.text || '',
            notReadCommentsCount: this.userProfileStore ? filteredComments.filter(c=> !c.usersRead.includes(this.userProfileStore!.userInfo.userId)).length : 0,
            overridingPackageId
        };

    }

    async getInitialData() {
        try {
            const promises  =  [
                this.projectService.getSpecificationTypes(), 
                this.projectService.getTopicValues(this.projectId), 
                // this.projectService.getLots(this.projectId),
                this.commentsStore.getComments(this.projectId)
            ];

            // @ts-ignore
            const resp = await Promise.all(promises) as [SpecficationType[], TopicsBySpec[], CommentData[]];
            this.specificationTypes = resp[0];
            // this.lots = resp[2];
            const userIds = Array.from(new Set(resp[2].map(r=> r.createdByUserId)));
            if (this.userProfileStore) {
                await this.userProfileStore!.setUserProfilePictures(userIds);
            }
            if (!this.sectionsInitiated) {
                this.setActiveSections(Array.from(new Set(resp[1].map(t=> t.topics).filter(t=> t).flat().map(s=> s!.sectionId))));
                this.sectionsInitiated = true;
            }

            // console.log(resp[1]);
            const nonEmptyTopics = resp[1].filter(t=> t.topics && t.topics.length > 0);
            // TODO: Fetch table data from non empty topics which should be stored in the store

            // TODO: Write a new method to build table model
            this.createTableModel(nonEmptyTopics, resp[2]);
            if (!this.currentSpecTypeId) {
                this.setCurrentSpecType(SpecificationTypes.Protocol);
            }
            runInAction(() => this.isLoading = false);
        } catch(error) {
            this.errorStore.addBasicError(error);
        }
    }

    highlightBlock(record: SummaryReportTableModel, blockId: string, lotType?: LotType | undefined, documentId?: string) {
        let lotData: TopicLotResultData | undefined;

        var doc = this.protocolDocs.find(d=> d.documentId === documentId);

        var protocolData = record.protocolData.find(p => p.documentId === documentId);

        if (!protocolData) {
            return;
        }

        var versionIndex = record.protocolData.findIndex(p => p.documentId === documentId);

        lotData = {
            section: record.sectionName,
            lotValue: protocolData.specValue,
            oldLotValue: protocolData.oldValue,
            lotFieldMetas: protocolData.fieldMetas ?? [],
            documentId: documentId ?? record.specification.documentId,
            documentName: doc?.documentName ?? record.specification.documentName,
            lotType: `Protocol v.${versionIndex + 1}`,
            specificationTypeId: this.currentSpecTypeId,
            isEdited: false,
            documentReportStatus: ReportStatus.Approved,
            editCommentId: null,
            topic: 'topic',
            isResult: false
        };

        if (!lotData) {
            return;
        }

        let index = blockId === this.highlightedBlock?.blockId ? this.highlightedBlock.fieldIndex : 0;

        if (blockId === this.highlightedBlock?.blockId && lotData.lotFieldMetas.length > index) {
            index++;
        } else if (blockId === this.highlightedBlock?.blockId && lotData.lotFieldMetas.length <= index + 1) {
            index = 0;
        }

        index = index >= lotData.lotFieldMetas.length ? 0 : index;

        const blockInfo = {fieldIndex: index, blockId, ...lotData.lotFieldMetas[index]};

        this.setHighlightedBlock(blockInfo);
        this.highlightedBlockSubject.next(blockInfo);

        if (lotData.documentId !== this.selectedDocument?.documentId) {
            this.setSelectedDocument({documentId: lotData.documentId, documentName: lotData.documentName, type: 'specification_documents'});
        }
    }


    setCurrentValue(val: string | null) {
        this.currentValue = val;
    }

    setHighlightedBlock(blockInfo: HighlightedBlockInfo | undefined) {
        this.highlightedBlock = blockInfo;
    }

    setSelectedDocument(documentInfo: SelectedDocumentInfo | undefined) {
        this.selectedDocument = documentInfo;
    }

    setEditableTopic(val: string) {
        this.editableTopic = val;
    }

    setEditableSection(val: string, sectionName: string) {
        this.editableSection = val;
        this.editableSectionName = sectionName;
    }

    setProjectId(id: string) {
        this.projectId = id;
    }

    setEditableCellType(type: CellType) {
        this.editableCellType = type;
    }

    setEditableLotType(type: LotType) {
        this.editableLotType = type;
    }

    setCurrentSpecType(id: string) {
        this.currentSpecTypeId = id;
    }

    setActiveSections(vals: string[]) {
        this.activeSections = vals;
    }

    setEditedDataModalNewValue(val: string | null) {
        this.editedDataModalNewValue = val;
    }

    setEditedDataModalComment(val: string) {
        this.editedDataModalComment = val;
    }

    updateSpecification(doc: DocumentStateChange) {
        if (doc.projectId === this.projectId && [PackageState.Broken, PackageState.Completed, PackageState.UploadedWithoutAnalysis].includes(doc.status)) {
            this.getInitialData();
        }
    }

    addNewComment(comment: CommentData) {
        if (comment.projectId === this.projectId) {
            this.commentsStore.addComment(comment);
            this.updateTableModel();
        }
    }
    
    // eslint-disable-next-line no-unused-vars
    handleDownload(lotType: LotType | undefined, topic: string, section: string) {
        if (lotType) {
            // const data = this.lots.topicLotResultData.find(t=> t.lotType === lotType && t.specificationTypeId === this.currentSpecTypeId 
            //     && t.topic === topic && t.section === section)!;
            // this.summaryReportService.downloadDocumentForLot(this.projectId, data.documentId);
        } else {
            this.projectService.handleSpecDownload(this.projectId, this.specDocMapping[this.currentSpecTypeId], true);
        }
    }

    setConciseView(isEnabled: boolean) {
        this.isConciseViewEnabled = isEnabled;
    }

    async createComment() {
        const resp = await this.commentsStore.createComment(this.projectId, this.currentSpecTypeId);
        if (resp.isOk()) {
            const record = this.tableData[this.currentSpecTypeId].find(t=> t.topic === this.commentsStore.topic)!;

            if (this.commentsStore.commentType === CellType.Specification) {
                record.specification = { ...record.specification, commentsCount: ++record.specification.commentsCount };
                return;
            }

            if (this.commentsStore.commentType === CellType.Lot) {
                if (this.commentsStore.lot === 'MethodSheet') {
                    record.methodSheet = { ...record.methodSheet, commentsCount: ++record.methodSheet.commentsCount };
                    return;
                }
                
                const resultLot = record.resultLots.find(l => l.lotType === this.commentsStore.lot)!;

                resultLot.lotCell.commentsCount += 1;
                record.resultLots = [...record.resultLots];
            }
        }
    }

    async editCellData(sectionName: string, value: string, newComment: string) {
        let resp;
        if (this.editableCellType === CellType.Specification) {
            // eslint-disable-next-line max-len
            resp = await this.summaryReportService.editSpecData(this.projectId, this.currentSpecTypeId, this.currentDocId ?? this.specDocMapping[this.currentSpecTypeId], this.editableTopic, sectionName, value, newComment);
        } else {
            // const doc = this.lots.topicLotResultData.find((l: TopicLotResultData)  => l.specificationTypeId === this.currentSpecTypeId && 
            //     l.topic === this.editableTopic && l.lotType === this.editableLotType && l.section === sectionName);
            // eslint-disable-next-line max-len
            resp = await this.summaryReportService.editLotData(this.projectId, undefined, this.currentSpecTypeId, 
                this.editableSection, this.editableSectionName, this.editableTopic, this.editableLotType, value, newComment);
        }

        if (resp.isOk()) {
            try {
                runInAction(() => {
                    this.tableData = {};
                    this.isLoading = true;
                });
                await this.getInitialData();
                message.success('Data updated successfully');
            } catch {
                message.error('Failed to update data. Please, reload the page.');
            } finally {
                runInAction(() => {
                    this.isLoading = false;
                });
            }
        }
    }

    async markReadComments(currentUserId: string) {
        const ids = this.commentsStore.commentsForCurrentCell.filter(c=> !c.usersRead.includes(currentUserId)).map(c=> c.id);
        if (ids.length) {
            const resp = await this.commentsStore.markReadComments(ids);
            if (resp.isOk()) {
                this.updateTableModel();
            }
        }
    }

    handleDownloadReport() {
        this.summaryReportService.downloadSummaryReport(this.projectId);
    }

    getStatisticsBySpec(specId: string) {
        return (`${this.statistics[specId].total}/${this.statistics[specId]?.uploaded}`);
    }

    async getMethodSheetSummary() {
        this.methodSheetReportData = await this.mmthodSheetReportService.getMethodSheetSummary(this.projectId);
    }

    // setFinalResultParams(section: string, topic: string, lot: LotType, isResult: boolean) {
    //     const doc = this.lots.topicLotResultData.find(t=> t.lotType === lot && t.specificationTypeId === this.currentSpecTypeId 
    //         && t.section === section && t.topic === topic)!;
    //     this.currentFinalResultParams = {documentId: doc.documentId, section, topic, lot, isResult: !isResult };
    // }

    async setFinalResult(comment: string ) {
        const resp = await this.summaryReportService.setFinalResult({...this.currentFinalResultParams, projectId: this.projectId, 
            specificationTypeId: this.currentSpecTypeId, comment });
        if (resp.isOk()) {
            const commentData = resp.unwrapOr(null);
            if (commentData !== null) {
                this.commentsStore.addComment(commentData);
            }
            this.updateTableModel();
        }

    }

    private createTableModel(topics: TopicsBySpec[], comments: CommentData[]) {
        // this.lots.specificationTypeTopicsStatistics.forEach(s => this.statistics[s.specificationTypeId] = s);
        topics.filter(r=> r.topics).forEach(r => {
            this.specDocMapping[r.specificationTypeId] = r.documentId;
            let mapped = r.topics!.map(t=> {
                const val: SummaryReportTableModel = {  
                    topic: t.topic, 
                    specification: this.getSpecValues(
                        t.sectionName,
                        t.topic,
                        t.value,
                        t.oldValue,
                        r.specificationTypeId,
                        comments,
                        t.fieldMetas,
                        r.documentId,
                        r.documentName,
                        t.isEdited,
                        t.editCommentId,
                        r.overridingPackageId
                    ),
                    protocolData: [this.getSpecValues(
                        t.sectionName,
                        t.topic,
                        t.value,
                        t.oldValue,
                        r.specificationTypeId,
                        comments,
                        t.fieldMetas,
                        r.documentId,
                        r.documentName,
                        t.isEdited,
                        t.editCommentId,
                        r.overridingPackageId
                    )],
                    resultLots: [],
                    methodSheet: {
                        lotValue: '',
                        oldLotValue: '',
                        isEdited: false,
                        commentsCount: 0,
                        notReadCommentsCount: 0,
                        editComment: '',
                        isFinalResult: false,
                        unavailable: false,
                        fieldMetas: [],
                    },
                    sectionId: t.sectionId,
                    sectionName: t.sectionName
                };
                return val;
            });
            if (this.tableData[r.specificationTypeId]) {
                this.tableData[r.specificationTypeId].forEach(t => {
                    const index = mapped.findIndex(m => m.topic === t.topic);
                    if (index !== -1) {
                        t.protocolData = [...t.protocolData, ...mapped[index].protocolData];
                    }
                });
            } else {
                this.tableData[r.specificationTypeId] = mapped;
            }
        });
    }

    private async updateTableModel() {
        if (!this.projectId) {
            return;
        }
        const promises  =  [
            this.projectService.getTopicValues(this.projectId), 
            this.projectService.getLots(this.projectId),
            this.commentsStore.getComments(this.projectId)
        ];

        const resp = await Promise.all(promises) as [TopicsBySpec[], TopicLotResult, CommentData[]];
        // this.lots = resp[1];
        this.createTableModel(resp[0], resp[2]);
    }

    // private hasUnreadComments(data: SummaryReportTableModel[]) {
    //     return data.some(
    //         t => t.resultLots.some(l => l.lotCell.notReadCommentsCount > 0) || t.specification.notReadCommentsCount > 0
    //     );
    // }

    // Alternative way of updating topic values (updating ui based on received push message instead of issuing a nre call to backend )
    
    // private updateTopicValue(documentTopicChange: DocumentTopicChange) {
    //     this.
    // const index = this.tableData[documentTopicChange.specificationTypeId].findIndex(t=> t.topic === documentTopicChange.topicName)!;
    // const record = this.tableData[documentTopicChange.specificationTypeId][index];
    // const updated = {
    //     ...record, 
    //     resultLot1: {...record.resultLot1, lotValue: documentTopicChange.lot === LotType.Result1 ? documentTopicChange.currentValue: record.resultLot1.lotValue },
    //     resultLot2: {...record.resultLot2, lotValue: documentTopicChange.lot === LotType.Result2 ? documentTopicChange.currentValue: record.resultLot2.lotValue },
    //     resultLot3: {...record.resultLot3, lotValue: documentTopicChange.lot === LotType.Result3 ? documentTopicChange.currentValue: record.resultLot3.lotValue }
    // };

    // const copy  = this.tableData[this.currentSpecTypeId].slice();
    // copy[index] = updated!;
    // this.tableData[this.currentSpecTypeId] = copy;
    // }
}

export default SummaryReportStore;
