import { LotType } from '../../summary_report/types/TopicLotResult';
import appClient from '../../common/services/AppClient';
import { CommentData, CellType } from '../types/CommentData';

export default class CommentsService {
    async getComments(projectId: string) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/comments`;
        const resp = await appClient.get<CommentData[]>(url);
        return resp.unwrapOr(Array<CommentData>());
    }

    async createComment (projectId: string, text: string, specificationTypeId: string, section: string, topic: string, lot: LotType, commentType: CellType) {
        const request = {
            projectId,
            text,
            specificationTypeId,
            section,
            topic,
            lot,
            commentType
        };
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/comments`;
        return appClient.post<CommentData>(url, request);
    }

    async markReadComments(projectId: string, commentIds: string[]) {
        const request = { commentIds};
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/comments/mark-as-read`;
        return appClient.post(url, request);
    }
}