import { observer } from 'mobx-react-lite';
import * as React from 'react';
import SummaryReportStore from '../stores/SummaryReportStore';

type Props = {
    store: SummaryReportStore
};

const ExcelPreview: React.FC<Props> = ({store}) => {
    if (!store.selectedDocument) {
        return null;
    }

    return (
        <div>
            Excel Content
            {store.selectedDocument.documentName}
        </div>
    );
};

export default observer(ExcelPreview);
