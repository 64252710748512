import { Button, Col, Form, Input, message, Modal, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import ModalTitle from '../../common/components/ModalTitle';
import { ProjectsStore } from '../stores';

type Props = {
    setVisible: (visible: boolean) => void;
    store: ProjectsStore
};

const ProjectCreateDialog: React.FC<Props> = ({ setVisible, store}) => {
    const [form] = Form.useForm();
    const [isTouched, setIsTouched] = React.useState(false);

    React.useEffect(() => {
        if (!store.analyzers?.length) {
            store.getAnalyzers();
        } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreateSubmit = () => {
        form.validateFields().then((formValues) => {
            const obj = {
                ...formValues
            };
            store.createNewProject(obj).then(() => {
                message.success('Project has been successfully created');
                handleCancel();
                store.getProjects();
            }).catch(err => {
                console.error(err);
                message.error('Failed to create project');
            });
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleUpdateSubmit = () => {
        form.validateFields().then((formValues) => {
            const obj = {
                ...formValues
            };
            store.updateProject(store.selectedProject, obj).then(() => {
                message.success('Changes have been successfully saved');
                handleCancel();
                store.getProjects();
            }).catch(err => {
                console.error(err);
                message.error('Failed to update project');
            });
        }).catch((err) => {
            console.log(err);
        });
    };


    const handleCancel = () => {
        setVisible(false);
        form.resetFields();
        store.setSelectedProject('');
    };

    const editableProject = React.useMemo(
        () => store.projects.find(p => p.id === store.selectedProject),
        [store.projects, store.selectedProject]
    );

    const getInitialValues = () => {
        if (!editableProject) {
            return {};
        }
        return {
            ...editableProject
        };
    };
    
    return (
        <Modal
            className="roche-client-dialog large-input"
            onCancel={handleCancel}
            visible={!store.isProjectDataLoading}
            closable={false}
            maskClosable={false}
            width={550}
            title={<ModalTitle title={editableProject ? 'Edit Project' : 'New Project'} onCloseCallback={handleCancel}/>}
            footer={[(
                <Button 
                    key="cancel-button"
                    className="light"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            ), (
                <Button 
                    data-testid="project-create-dialog-submit"
                    key="submit-button"
                    type="primary"
                    size="large"
                    onClick={editableProject ? handleUpdateSubmit : handleCreateSubmit}
                    disabled={editableProject ? !isTouched : false}
                >
                    {editableProject ? 'Save changes' :  'Create project'}
                </Button>
            )
            ]}
        >
            <Form
                form={form}
                initialValues={getInitialValues()}
                layout="vertical"
                data-testid="create-project-form"
                onFieldsChange={() => setIsTouched(true)}
            >
                <Form.Item
                    name="name"
                    label="Project Name"
                    rules={[{ required: true, message: 'Please input project name!', whitespace: true }]}
                >
                    <Input />
                </Form.Item>

                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item
                            name="protocolTitle"
                            label="Protocol Title"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item name="phase" label="Phase">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item name="sponsorProtocolId" label="Sponsor Protocol Id">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="responsibleParty" label="Responsible Party">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col span={12}>
                        <Form.Item name="indication" label="Indication">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="intervention" label="Intervention">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default observer(ProjectCreateDialog);