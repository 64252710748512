import { LotType } from '../../summary_report/types/TopicLotResult';

export type CommentData = {
    id: string;
    projectId: string;
    text: string;
    specificationTypeId: string;
    section: string;
    topic: string;
    lot: LotType | null;
    isRead: boolean;
    createdDate: string;
    createdByUserName: string;
    createdByUserId: string;
    commentType: CellType;
    usersRead: string[]
};

export enum CellType {
    Specification = 'Specification',
    Lot = 'Lot',
    MethodSheet = 'MethodSheet'
}