import { UserInfo } from '../types/UserInfo';
import appClient from './AppClient';

class UserInfoService {
    async getUsersInfo() {
        const url = `${process.env.REACT_APP_MANAGE_URL}user-info`;
        const resp = await appClient.get<UserInfo>(url);  
        return resp.unwrapOr({} as UserInfo);
    }
}

const UserService = new UserInfoService();
export default UserService ;

