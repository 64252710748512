import * as React from 'react';
import { observer } from 'mobx-react-lite';
import {  Modal } from 'antd';
import ModalTitle from '../../common/components/ModalTitle';
import AuditStore from '../stores/AuditStore';
import { Utils } from '../../common/misc/Utils';

type Props = {
    store: AuditStore;
    closeDialog: () => void;
    isModalVisible: boolean
};

const CommentsModal: React.FC<Props> = ({ store, closeDialog, isModalVisible}) => {
    return (
        <Modal
            data-testid='comments-dialog'
            title={<ModalTitle title={`${store.currentTopic}-Comments`} onCloseCallback={closeDialog}/>}
            visible={isModalVisible}
            className="roche-client-dialog audit-comments-dialog"
            closable={false}
            bodyStyle={{height: '50vh', display:'flex'}}
            width={700}
            footer={null}
        >
            <div>
                {store.currentComments.map(c=> 
                    <div key={c.id}> 
                        <div><span>{`[${c.createdByUserName}] `}</span><span>{Utils.formatDateStringShort(c.createdDate, false, true)}</span></div>
                        <div className='comment' dangerouslySetInnerHTML={{__html: c.text}}/>
                    </div>
                )}
            </div>
        </Modal>

    );
    
};

export default observer(CommentsModal);

