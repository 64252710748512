export type AlphaPackage = {
    id: string;
    projectId: string;
    fileName: string;
    uploadedTime: string;
    state: AlphaPackageStateResult;
    fileSizeBytes: number;
    indexDate: string;
    userTags: string[]
};

export enum AlphaPackageStateResult {
    Ready = 'Ready',
    Parsed = 'Parsed',
    Importing = 'Importing',
    Processing = 'Processing',
    Uploading = 'Uploading',
    Broken = 'Broken'
}

