import { Col, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import AuditTable from '../../audit/components/AuditTable';
import { useStore } from '../../common/stores/context';
import { WrappedMatomoTracker } from '../../common/components';

const AuditPage: React.FC = () => {
    const { auditStore } = useStore();

    return(
        <div className='table-container'>
            <WrappedMatomoTracker documentTitle={'Audit'} />
            <Row style={{alignItems: 'center', marginBottom: 30}}>
                <Col>
                    <h1 style={{fontSize: 34, marginBottom: 0}}>Audit</h1>
                </Col>
            </Row>
            <AuditTable auditStore={auditStore}/>
        </div>
    );
};

export default observer(AuditPage);