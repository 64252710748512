export type HistoryData = {
    id: string;
    projectId: string;
    area: string;
    activity: string;
    parameters: Parameter[];
    userId: string;
    userName: string;
    createDate: string;
    diffMins: number
};

export enum ParameterNameType {
    Id = 'Id',
    Name = 'Name',
    Text = 'Text',
    AnalyzerId = 'AnalyzerId',
    ResultCount = 'ResultCount',
    CertificationId = 'CertificationId',
    SpecificationTypeId = 'SpecificationTypeId',
    Analyzer = 'Analyzer',
    TopicName = 'TopicName',
    Lot = 'Lot',
    Topic = 'Topic',
    CommentType = 'CommentType',
    CurrentValue = 'CurrentValue',
    EditedTopicKeyValue = 'EditedTopicKeyValue',
    EditedValueTopicName = 'EditedValueTopicName',
    IsResult = 'IsResult',
    ProductName = 'ProductName',
    DevelopmentLead = 'DevelopmentLead',
    ApplicationIsNormal = 'ApplicationIsNormal',
    ApplicationIsStat = 'ApplicationIsStat',
    Gpid = 'Gpid',
    Lpn =  'Lpn',
    DiaDocVersion = 'DiaDocVersion',
    KitSize = 'KitSize',
    MaterialNumberAssay = 'MaterialNumberAssay',
    MsVersionAssay = 'MsVersionAssay',
    MaterialNumberCalSet = 'MaterialNumberCalSet',
    MsVersionCalSet = 'MsVersionCalSet',
    NameOfTheCalSet = 'NameOfTheCalSet',
    Inpack = 'Inpack',
    MaterialNumbersPreciControl1 = 'MaterialNumbersPreciControl1',
    NamesPreciControl1 = 'NamesPreciControl1',
    MsVersionPreciControl1 = 'MsVersionPreciControl1',
    MaterialNumbersPreciControl2 = 'MaterialNumbersPreciControl2',
    NamesPreciControl2 = 'NamesPreciControl2',
    MsVersionPreciControl2 = 'MsVersionPreciControl2',
    DuplicateProjectShortId = 'DuplicateProjectShortId',
    OverridingPackageName = 'OverridingPackageName',
    DocumentName = 'DocumentName',
}

export type Parameter = {
    lastValue: string | null;
    newValue: string;
    parameterName: ParameterNameType
};