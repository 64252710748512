import * as React from 'react';

export const generateStatusIcon = (state: string | null) => {
    switch (state) {
    case 'Completed':
        return (
            <div data-id-name="Status" className="project-status-large" style={{background: '#9BA0AA'}}>Completed</div>
        );
    case 'InReview':
        return (
            <div data-id-name="Status" className="project-status-large" style={{background: '#FA8C16'}}>In review</div>
        );
    case 'NotStarted':
        return (
            <div data-id-name="Status" className="project-status-large" style={{background: '#9BA0AA'}}>Not started</div>
        );
    case 'InProgress':
        return (
            <div data-id-name="Status" className="project-status-large" style={{background: '#162242'}}>In progress</div>
        );
    default: return;
    }
};