import { Table } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ProjectDashboardStore } from '../stores';
import DocumentTypeDialog from './DocumentTypeDialog';
import ProjectDvpMatrixCell from './ProjectDvpMatrixCell';

type Props = {
    store: ProjectDashboardStore
};

const ProjectDvpMatrix: React.FC<Props> = ({store}) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [matrixColumns, setMatrixColumns] = React.useState<any[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [matrixData, setMatrixData] = React.useState<any[]>([]);

    const [documentTypeDialogVisible, setDocumentTypeDialogVisible] = React.useState(false);
    const [currentMatrixField, setCurrentMatrixField] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if(store.project) {
            store.getProjectDocuments().then(() => {
                store.fetchDvpMatrixConfig().then(() => {
                    initializeMatrix();
                }).catch(err => {
                    store.setDvpMatrixConfig(undefined);
                    console.error(err);
                });
            }).catch(err => {
                console.error(err);
            });        
        } else {
            store.setDvpMatrixConfig(undefined);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.project]);

    const beforeUpload = (file: RcFile) => {
        store.setFileToUpload(file);
        setDocumentTypeDialogVisible(true);
        return false;
    };
  
    const initializeMatrix = () => {
        if (!store.dvpMatrixColumns.length || !store.dvpMatrixRows.length) {
            return;
        }

        let columns: unknown[] = [];
        let data = store.dvpMatrixRows.map(r => {
            return {
                lots: r.name
            };
        });
        store.dvpMatrixColumns.forEach((col, colIndex) => {    
        
            columns.push({
                dataIndex: colIndex === 0 ? 'lots' : colIndex,
                title: col.name,
                render: (text: string) => {
                    return (
                        <span>
                            {colIndex === 0 
                                ? text : 
                                <ProjectDvpMatrixCell 
                                    handleDelete={handleDelete}
                                    document={store.getDocumentByMatrixField(text)} 
                                    matrixField={text} 
                                    setCurrentMatrixField={setCurrentMatrixField} 
                                    beforeUpload={beforeUpload} 
                                />}
                        </span>
                    );
                }
            });

            data.forEach((element, index) => {
                const rowId = store.dvpMatrixRows[index]?.rowId;
                const colId = store.dvpMatrixColumns[colIndex]?.colId;
                element[colIndex] = `${rowId}:${colId}`;
            });
        });

        setMatrixColumns(columns);
        setMatrixData(data);
    };

    const handleTypeDialogCancel = () => {
        setDocumentTypeDialogVisible(false);
    };

    const handleTypeDialogSubmit = (type: string) => {
        if (currentMatrixField) {
            store.uploadFile(type, currentMatrixField).then(() => {
                setCurrentMatrixField(undefined);
                initializeMatrix();
            });
            handleTypeDialogCancel();
        }
    };

    const handleDelete = (documentId: string) => {
        store.deleteDocument(documentId).then(() => {
            setCurrentMatrixField(undefined);
            initializeMatrix();
        });
    };

    return (
        <>
            <DocumentTypeDialog 
                visible={documentTypeDialogVisible} 
                handleCancel={handleTypeDialogCancel}
                handleSubmit={handleTypeDialogSubmit}
            />
            <Table 
                data-testid='project-dvp-matrix-table'
                columns={matrixColumns}
                dataSource={matrixData}
                loading={store.isLoadingConfig || store.isLoadingDocuments}
                pagination={false}
                rowKey={r => r.lots}
            />
        </>
    );
};

export default observer(ProjectDvpMatrix);