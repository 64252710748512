import * as React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { observer } from 'mobx-react-lite';
import { useStore } from '../stores/context';
import UserProfileModal from '../../user_profile/components/UserProfileModal';

const UserProfile: React.FC = () => {
    const store = useStore().userProfileStore;
    const [imageUrl, setimageUrl] = React.useState('');

    React.useEffect(() => {
        let hasCancelled = false;  
        const avatarIconFileId = store.userInfo && store.userInfo.avatarIconFileId;
        if (avatarIconFileId) {
            store.getAvatarIcon(avatarIconFileId).then(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (val: any) => hasCancelled ? () => {
                    return; 
                } : setimageUrl(URL.createObjectURL(val) || ''),
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (error: any) => hasCancelled ? () => {
                    return; 
                } : () => console.log(error)
            );      
        }

        return () => {
            hasCancelled = true;
        };
    },              [store.userInfo, store]);

    const menu = () => {
        return(
            <Menu data-id='profile-list-menu'> 
                <Menu.Item 
                    key="1"
                    onClick={() => store.setEditUserProfileVisible(true)}
                >My Profile
                </Menu.Item>
                <Menu.Item 
                    key="1"
                    onClick={store.logout}
                >Logout
                </Menu.Item>
            </Menu>
        );
    };

    const getUserInitial = () => {
        return store.userInfo && store.userInfo.firstName &&
        store.userInfo.firstName.charAt(0).toUpperCase() || 
        store.userInfo?.userName && store.userInfo?.userName.charAt(0).toUpperCase();
    };


    return (
        <>
            <UserProfileModal store={store}/>
            <div className="avatar-icon">
                <Dropdown overlayStyle={{zIndex: 100000}} overlay={() => menu()} trigger={['click']}>
                    {imageUrl ? <Avatar size={32} src={imageUrl} data-id="avatar-icon"/> : <Avatar size={32} data-id="avatar-icon">{getUserInitial()}</Avatar>}
                </Dropdown>
            </div> 
        </>);
};

export default observer(UserProfile);