import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, Modal } from 'antd';
import { Utils } from '../../common/misc/Utils';
import CommentForm from './CommentForm';
import ModalTitle from '../../common/components/ModalTitle';
import SummaryReportStore from '../stores/SummaryReportStore';

type Props = {
    store: SummaryReportStore;
    isModalVisible: boolean;
    closeDialog: () => void
};

const SetFinalResultDialog: React.FC<Props> = ({store, isModalVisible, closeDialog}) => {
    const [form] = Form.useForm();    

    const handleDialogClose = () => {
        closeDialog();
        form.resetFields();
    };

    const isFinalResult = store.currentFinalResultParams?.isResult;

    const handleSubmit = () => {
        closeDialog();
        const comment = Utils.removeNonBreakingSpaces(form.getFieldValue('comment'));
        store.setFinalResult(comment);
        form.resetFields();
    };

    return (
        <Modal
            title={<ModalTitle title={isFinalResult ? 'Set as the final result': 'Remove from the final results'} onCloseCallback={handleDialogClose}/>}
            visible={isModalVisible}
            className="roche-client-dialog large-input comment-in-modal"
            closable={false}
            footer={[
                <Button 
                    data-id="assign-cancel-button"
                    key="assign-cancel-button" 
                    className="light"
                    onClick={handleDialogClose}
                    size='large'
                >
                    Cancel
                </Button>,
                <Button 
                    data-id="assign-save-button"
                    key="assign-save-button" 
                    type="primary"
                    size='large'
                    onClick={handleSubmit}
                >
                    <span>{isFinalResult ? 'Set as the final result' : 'Remove'}</span>
                </Button>
            ]}
        >
            <Form form={form} className="alpha-form" layout='vertical'>
                <CommentForm form={form} label='Optional comment'/>
            </Form>
        </Modal>

    );
    
};

export default observer(SetFinalResultDialog);

