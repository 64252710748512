import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Modal, Button, Input, Form, Alert} from 'antd';
import { ProjectsStore } from '../stores';
import ModalTitle from '../../common/components/ModalTitle';

interface Props {
    store: ProjectsStore;
    visible: boolean;
    setVisible: (visible: boolean) => void
}

interface FormValues {
    projectName: string
}

const DuplicateProjectDialog: React.FC<Props> = ({ store, visible, setVisible }) => {
    const [form] = Form.useForm<FormValues>();

    const [loading, setLoading] = React.useState(false);

    const [projectIsBusy, setProjectIsBusy] = React.useState(false);

    const onSave = async (values: FormValues) => {
        if (!project) {
            return;
        }

        try {
            setLoading(true);
            await store.duplicateProject(project.id, values.projectName);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
            onClose();
        }
    };

    const onClose = () => {
        setVisible(false);
        setProjectIsBusy(false);
        form.resetFields();
        store.setSelectedProject('');
    };

    const project = React.useMemo(
        () => store.projects.find(p => p.id === store.selectedProject),
        [store.projects, store.selectedProject]
    );

    // TODO: we should probably setup some kind of service to determine busy state and send updates using signalr
    const getProjectBusyState = React.useCallback(async () => {
        if (!project) {
            return;
        }

        const isBusy = await store.isProjectBusy(project.id);

        setProjectIsBusy(isBusy);
    }, [store, project]);

    React.useEffect(() => {
        if (visible && project) {
            getProjectBusyState();

            form.setFieldsValue({ projectName: `Copy of ${project.name}` });
        }
    }, [visible, project, form, getProjectBusyState]);

    React.useEffect(() => {
        if (!visible || !projectIsBusy) {
            return;
        }

        const interval = setInterval(getProjectBusyState, 5000);

        return () => clearInterval(interval);
    }, [visible, projectIsBusy, getProjectBusyState]);

    return (
        <Modal
            className="roche-client-dialog"
            width={500}
            visible={visible}
            closable={false}
            title={<ModalTitle title="Duplicate a Project" onCloseCallback={onClose} />}
            footer={[
                <Button key="cancel-button" className="light" size="large" onClick={onClose} disabled={loading}>
                    Cancel
                </Button>,
                <Button
                    key="submit-button"
                    type="primary"
                    size="large"
                    form="duplicate-project-form"
                    htmlType="submit"
                    loading={loading}
                    disabled={projectIsBusy}
                >
                    Save
                </Button>
            ]}
        >
            <Form form={form} id="duplicate-project-form" layout="vertical" onFinish={onSave}>
                <Form.Item
                    name="projectName"
                    label="Project Name"
                    rules={[{ required: true, message: 'Please input project name!', whitespace: true }]}
                >
                    <Input disabled={projectIsBusy} />
                </Form.Item>

                {projectIsBusy && (
                    <Alert message="Project is busy and cannot be duplicated at the moment." type="error" />
                )}
            </Form>
        </Modal>
    );
};

export default observer(DuplicateProjectDialog);
