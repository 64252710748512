import { Spin, Tooltip } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../../common/misc/Utils';
import AuditStore from '../stores/AuditStore';
import { ProjectHistoryItem } from '../types';
import { AuditTopicsTable } from './AuditTopicsTable';
import AuditConstants from './constants/AuditConstants';

type Props = {
    auditStore: AuditStore
};
export const AuditTable: React.FC<Props> = ({auditStore}) => {
   

    React.useEffect(() => {
        auditStore.getHistory();
    },[]);    

    
    const loadingSpinner = (
        <div data-testid='projects-layout-loader' style={{textAlign: 'center'}}>
            <Spin />
        </div>
    );

    const getStatus = (status: string) => {
        switch (status) {
        case 'NotStarted':
            return (
                <span data-id-name="Status" className="project-status" style={{background: '#9BA0AA', fontSize: 11}}>Not started</span>
            );
        case 'InProgress':
            return (
                <span data-id-name="Status" className="project-status" style={{background: '#162242', fontSize: 11}}>In progress</span>
            );
        case 'Completed':
            return (
                <span data-id-name="Status" className="project-status" style={{background: '#9BA0AA', fontSize: 11}}>Completed</span>
            );
        case 'InReview':
            return (
                <span data-id-name="Status" className="project-status" style={{background: '#FA8C16', fontSize: 11}}>In review</span>
            );
        default: return '';
        }
    };

    const handleExportAuditClick = (e:  React.MouseEvent, projectId: string) => {
        e.stopPropagation();
        auditStore.exportAuditLog(projectId);
    };
        

    const columns: ColumnProps<ProjectHistoryItem>[] = [
        {
            dataIndex: 'projectId',
            title: 'ID',
            render: (id: string) => {
                return {
                    props: {
                        'data-id-cells': 'ID'
                    },
                    children: id
                };
            }
        },
        {
            dataIndex: 'name',
            title: 'Project Name',
            render: (name: string) => {
                return {
                    props: {
                        'data-id-cells': 'Project Name'
                    },
                    children: name
                };
            }
        },
        {
            dataIndex: 'creationDate',
            title: 'Creation Date/Time',
            render: (dateString: string) => {
                return {
                    children: Utils.formatDateStringShort(dateString, false, true),
                    props: {
                        'data-id-cells': 'Creation Date/Time',
                        'data-id-timestamp': dateString
                    }
                };
            }
        },
        {
            dataIndex: 'analyzers',
            title: 'Instrument(s)',
            render: (_: unknown, record: ProjectHistoryItem) => {
                return {
                    children: record.analyzers.map(a=> a.name).join('/'),
                    props: {
                        'data-id-cells': 'Instrument(s)'
                    }
                };
            }
        },
        {
            dataIndex: 'developmentLead',
            title: 'Development Lead',
            render: (developmentLead: string) => {
                return {
                    props: {
                        'data-id-cells': 'Development Lead'
                    },
                    children: developmentLead
                };
            }
        },
        {
            dataIndex: 'status',
            title: 'Status',
            render: (_: unknown, record: ProjectHistoryItem) => {
                return {
                    children: getStatus(record.status),
                    props: {
                        'data-id-cells': 'Status'
                    }
                };
            }

        }, 
        {
            key: 'actions',
            width: 200,
            render: (_: unknown, record: ProjectHistoryItem) => {
                return (
                    <div className='table-actions'>
                        <div>
                            <Tooltip title="Export audit">     
                                <i 
                                    className='roche-icon md export' 
                                    style={{transform: 'translateY(-1px)', marginLeft: 12}} 
                                    onClick={(e) => handleExportAuditClick(e, record.projectFullId)}
                                />
                            </Tooltip>
                        </div>
                    </div>
                );
            }
        }

    ];

    const expandedRowRender =  (item: ProjectHistoryItem) => {
        return <AuditTopicsTable store={auditStore} projectItem={item}/>;
    };

    const onExpand = (expanded: boolean, record: ProjectHistoryItem) => {
        auditStore.setExpandedProject(expanded, record.projectId);
    };

    return (
        <div className='table-container-content'>
            {auditStore.isLoading && !auditStore.history?.projectItems?.length ? loadingSpinner :
                <Table
                    data-testid='audit-table'
                    data-id="table-project-list"
                    className='roche-table lg-title no-border-top cursor-pointer nested audit-table'
                    dataSource={auditStore.history.projectItems}
                    columns={columns}
                    rowKey={r => r.projectId}
                    loading={auditStore.isLoading}
                    pagination={{
                        current: auditStore.currentPage,
                        showSizeChanger: false,
                        pageSize: AuditConstants.AuditPageSize,
                        total: auditStore.totalItemsCount,
                        hideOnSinglePage: true,
                        onChange: (page: number) => auditStore.setCurrentPage(page)
                    }}
                    expandable={{expandRowByClick: true, expandedRowRender: expandedRowRender, onExpand: onExpand}}
                />}
        </div>
    );
};

export default observer(AuditTable);