import * as React from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import ProjectsStore from './ProjectsStore';
import ProjectsService from '../services/ProjectsService';
import SummaryReportStore from '../../summary_report/stores/SummaryReportStore';
import SummaryReportService from '../../summary_report/services/SummaryReportService';
import CommentStore from '../../common/stores/CommentsStore';
import CommentsService from '../services/CommentsService';
import ErrorStore from '../../common/stores/ErrorStore';
import MethodSheetReportService from '../../method_sheet_report/services/MethodSheetReportService';

function createStore() {
    const projectsService = new ProjectsService();
    const commentService = new CommentsService();
    const commentsStore = new CommentStore(commentService);
    const summaryReportService = new SummaryReportService();
    const projectsStore = new ProjectsStore(projectsService);
    const errorStore = new ErrorStore();
    const methodSheetReportService =  new MethodSheetReportService();
    const summaryReportStore = new SummaryReportStore(projectsService, projectsStore, summaryReportService, commentsStore, errorStore, methodSheetReportService);

    return {
        projectsStore,
        summaryReportStore,
        commentsStore
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(null);
type Props = {
    children: React.ReactNode
};

export const ProjectsStoreProvider: React.FC<Props> = ({ children }) => {
    const store = useLocalObservable(createStore);
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useProjectsStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }
    
    return store;
};