import { makeObservable, action, observable } from 'mobx';
import SummaryReportService from '../../summary_report/services/SummaryReportService';
import { ProjectsService } from '../../projects/services';
import { ProjectDashboardStore, ProjectsStore } from '../../projects/stores';
import SummaryReportStore from '../../summary_report/stores/SummaryReportStore';
import CommentStore from './CommentsStore';
import ApplicationReportsService from '../../projects/services/ApplicationReportsService';
import ApplicationReportsStore from '../../application_report/stores/ApplicationReportsStore';
import UserProfileStore from './UserProfileStore';
import AlphaPackagesStore from './AlphaPackagesStore';
import HistoryService from '../../projects/services/HistoryService';
import MethodSheetReportStore from '../../method_sheet_report/stores/MethodSheerReportStore';
import ErrorStore from './ErrorStore';
import MethodSheetReportService from '../../method_sheet_report/services/MethodSheetReportService';

export enum TabType {
    Project = 'project',
    Report = 'report'
}

export type Tab = {
    name: string; 
    tabId: string;
    type: TabType;
    subTitle?: string
};

class TabStore {

    selectedTab: string;

    tabs: Tab[] = [];

    summaryReportStores: { [tabId: string]: SummaryReportStore} = {};

    reportStores: { [tabId: string]: ApplicationReportsStore} = {};

    dashboardStores: { [tabId: string]: ProjectDashboardStore} = {};

    errorStore: ErrorStore;

    constructor() {
        this.errorStore = new ErrorStore();
        makeObservable(this, {
            addTab: action.bound,
            tabs: observable,
            selectedTab: observable,
            summaryReportStores: observable,
            reportStores: observable,
            dashboardStores: observable,
            setSelectedTab: action.bound,
            closeTab: action.bound,
            addSummaryReportStore: action.bound,
            addApplicationReportStore: action.bound

        });
    }

    addSummaryReportStore(tabId: string, projectsStore: ProjectsStore, commentsStore: CommentStore, userProfileStore: UserProfileStore) {
        if (!this.summaryReportStores[tabId]) {
            const projectsService = new ProjectsService();
            const summaryReportService = new SummaryReportService();    
            const obj = this.summaryReportStores;
            const methodSheetReportService =  new MethodSheetReportService();
            obj[tabId] = new SummaryReportStore(projectsService, projectsStore, summaryReportService, commentsStore, this.errorStore, methodSheetReportService, userProfileStore);
            this.summaryReportStores = obj;
        }
    }

    addApplicationReportStore(
        tabId: string, 
        projectsStore: ProjectsStore, 
        commentsStore: CommentStore, 
        userProfileStore: UserProfileStore, 
        alphaPackageStore: AlphaPackagesStore,
        methodSheetReportStore: MethodSheetReportStore
    ) {
        if (!this.reportStores[tabId]) {
            const projectsService = new ProjectsService();
            const applicationReportsService = new ApplicationReportsService();
            // eslint-disable-next-line max-len
            this.reportStores[tabId] =  new ApplicationReportsStore(projectsStore, applicationReportsService, projectsService, commentsStore, userProfileStore, alphaPackageStore, this.errorStore, methodSheetReportStore);
        }
    }

    addProjectDashboardStore(
        tabId: string, 
        projectsStore: ProjectsStore, 
        userProfileStore: UserProfileStore, 
        alphaPackageStore: AlphaPackagesStore, 
        methodSheetReportStore: MethodSheetReportStore
    ) {
        if (!this.reportStores[tabId]) {
            const projectsService = new ProjectsService();
            const historyService = new HistoryService();
            const appReportService = new ApplicationReportsService();
            methodSheetReportStore.setProjectId(tabId);
            // eslint-disable-next-line max-len
            this.dashboardStores[tabId] =  new ProjectDashboardStore(alphaPackageStore, projectsService, projectsStore, historyService, userProfileStore, methodSheetReportStore, appReportService);
        }
    }

    addTab(tabId: string, name: string, type: TabType, subTitle?: string) {
        if (!this.tabs.map(t=> t.tabId).includes(tabId)) {
            this.tabs.push({tabId, name, type, subTitle});
        }

        this.setSelectedTab(tabId);
    }

    setSelectedTab(id: string) {
        this.selectedTab = id;
    }

    isSelected(tab: Tab) {
        return this.selectedTab === tab.tabId;
    }

    closeTab(tabId: string, navigateToPrevTab: (id: string) => void) {
        const index = this.tabs.map(t=> t.tabId).findIndex(x=> x === tabId);
        if (this.tabs.length > 1) {
            const projId = index !== 0 ? this.tabs[index - 1].tabId : this.tabs[index + 1].tabId;
            if (this.selectedTab === tabId) {
                this.setSelectedTab(projId);
            }
            navigateToPrevTab(projId);
        } else {
            navigateToPrevTab('');
        }

        if (this.summaryReportStores[tabId]) {
            this.summaryReportStores[tabId].dispose();
            delete this.summaryReportStores[tabId];
        }

        if (this.reportStores[tabId]) {
            delete this.reportStores[tabId];
        }

        if (this.dashboardStores[tabId]) {
            delete this.dashboardStores[tabId];
        }

        this.tabs.splice(index, 1);
    }

}

export default TabStore;
