import { action, makeObservable, observable, runInAction } from 'mobx';
import { MethodSheetSummaryData } from '../models/types';
import MethodSheetReportService from '../services/MethodSheetReportService';

class MethodSheetReportStore {
    
    projectId: string;

    methodSheetReportData: MethodSheetSummaryData;

    isLoading: boolean = true;

    constructor(private service: MethodSheetReportService) {
        makeObservable(this, {
            isLoading: observable,
            methodSheetReportData: observable,
            getMethodSheetSummary: action.bound
        });
    }

    get projectStatus() {
        return this.methodSheetReportData.status;
    }   


    setProjectId(id: string) {
        this.projectId = id;
    }

    async getMethodSheetSummary() {
        this.methodSheetReportData = await this.service.getMethodSheetSummary(this.projectId);
        runInAction(() => this.isLoading = false);
    }
}

export default MethodSheetReportStore;