import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useParams } from 'react-router';
import { TabType } from '../../common/stores/TabsStore';
import { useStore } from '../../common/stores/context';
import ProjectDashboardView from '../components/ProjectDashboardView';
import { useProjectsStore } from '../stores/context';
import AlphaPackagesStore from '../../common/stores/AlphaPackagesStore';
import PreviewsVisualStore from '../../application_report/stores/PreviewVisualStore';
import ImageService from '../../common/services/ImageService';
import MethodSheetReportService from '../../method_sheet_report/services/MethodSheetReportService';
import MethodSheetReportStore from '../../method_sheet_report/stores/MethodSheerReportStore';
import { WrappedMatomoTracker } from '../../common/components';

const ProjectDashboard: React.FC = () => {
    const { projectsStore, commentsStore } = useProjectsStore();
    const { tabsStore, userProfileStore } = useStore();
    let { projectId } = useParams();

    React.useEffect(() => {
        if (projectsStore.projects?.length) {
            assignProjectToStores();
        } else {
            projectsStore.getProjects(undefined, undefined, true).then(() => {
                assignProjectToStores();
            }).catch(err => {
                console.error(err);
            });
        }        
        tabsStore.setSelectedTab(projectId!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    const assignProjectToStores = () => {
        if (!tabsStore.dashboardStores[projectId!]) {
            const project = projectsStore.projects.find(p => p.id === projectId);
            const service = new MethodSheetReportService();
            const methodSheetReportStore = new MethodSheetReportStore(service);
            const alphaPackageStore = createAlphaPackageStore();
            tabsStore.addProjectDashboardStore(projectId!, projectsStore, userProfileStore, alphaPackageStore, methodSheetReportStore);
            const projectDashboardStore = tabsStore.dashboardStores[projectId!];
            projectDashboardStore.setProject(project);
            projectDashboardStore.getSpecificationDocuments();
            projectDashboardStore.getHistory();

            tabsStore.addTab(projectId!, projectDashboardStore.project!.name, TabType.Project);
            tabsStore.addApplicationReportStore(projectId!, projectsStore, commentsStore, userProfileStore, alphaPackageStore, methodSheetReportStore);
            const appReportStore = tabsStore.reportStores[projectId!];
            appReportStore.setProjectId(projectId!);
            appReportStore.getInitialData();
        }
        const reportStore = tabsStore.reportStores[projectId!];
        reportStore.updateTable();
    };

    const createAlphaPackageStore = () => {
        const imageService = new ImageService();
        const previewVisualStore = new PreviewsVisualStore(imageService);
        const alphaPackageStore = new AlphaPackagesStore(previewVisualStore);
        return alphaPackageStore;
    };

    if (!tabsStore.reportStores[projectId!]) {
        return null;
    }

    return(
        <>
            <WrappedMatomoTracker documentTitle={`Project Dashboard - ${tabsStore.dashboardStores[projectId!].project?.name}`} />
            <ProjectDashboardView store={tabsStore.dashboardStores[projectId!]} applicationReportsStore={tabsStore.reportStores[projectId!]} />
        </>
    );
};

export default observer(ProjectDashboard);