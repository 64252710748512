import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { CommentData } from '../../projects/types/CommentData';
import { AuditService } from '../services/AuditService';
import { HistoryResult, TopicHistoryItem, TopicHistoryItemType } from '../types';
import AuditConstants from '../components/constants/AuditConstants';

export default class AuditStore {
    isLoading: boolean = true;

    history: HistoryResult;

    expandedProjects: string[] = [];

    currentComments: CommentData[] = [];

    currentTopic: string;

    expandedTopics: string[] = [];

    currentPage: number = 1;

    constructor(private service: AuditService) {
        makeObservable(this, {
            getHistory: action.bound,
            setExpandedProject: action.bound,
            setExpandedTopic: action.bound,
            setCurrentPage: action.bound,
            currentPage: observable,
            isLoading: observable,
            expandedProjects: observable,
            expandedTopics: observable,
            projectItems: computed,
            totalItemsCount: computed
        });
    }

    get projectItems() {
        return this.history.projectItems;
    }

    get totalItemsCount() {
        return this.history.totalCount;
    }

    setCurrentPage(page: number) {
        this.currentPage = page;
        this.getHistory();
    }

    setExpandedProject(isExpanded: boolean, id: string) {
        if (isExpanded) {
            this.expandedProjects.push(id);
        } else {
            const index = this.expandedProjects.findIndex(x=> x === id);
            this.expandedProjects.splice(index, 1);
        }
    }

    setExpandedTopic(isExpanded: boolean, section: string, topic: string, type: TopicHistoryItemType) {
        const key = `${section}-${topic}-${type}`;
        if (isExpanded) {
            this.expandedTopics.push(key);
        } else {
            const index = this.expandedTopics.findIndex(x=> x === key);
            this.expandedTopics.splice(index, 1);
        }
    }

    setCurrentComments(comments: CommentData[]) {
        this.currentComments = comments;
    }

    setCurrentTopic(topic: string) {
        this.currentTopic = topic;
    }

    getTopicSources(projectId: string, topicItem: TopicHistoryItem) {
        const currentProjectitems = this.projectItems.find(p => p.projectId === projectId)!.topicHistoryItems;
        return currentProjectitems.find(c=> c.topic === topicItem.topic && c.section === topicItem.section)!.topicHistorySources;
    }

    async getHistory() {
        runInAction(() => this.isLoading = true);

        const skip = (this.currentPage - 1) * AuditConstants.AuditPageSize;
        const resp = await this.service.getAudit(AuditConstants.AuditPageSize, skip);
    
        runInAction(() => { 
            this.history = resp;
            this.isLoading = false;
        });
    }

    exportAuditLog(projectId: string) {
        this.service.exportAuditLog(projectId);
    }
}