import * as React from 'react';
import { Image } from 'antd';
import ImageService from '../../services/ImageService';
import './HTMLRenderer.less';

interface Props {
    html: string;
    maxImageWidth?: number;
    imagePaddingRight?: number;
    conciseViewEnabled?: boolean
}

const HTMLRenderer: React.FC<Props> = ({ html, maxImageWidth, imagePaddingRight, conciseViewEnabled }) => {
    const [selectedImage, setSelectedImage] = React.useState<HTMLImageElement | null>(null);
    const [hasImages, setHasImages] = React.useState(false);

    const imageService = React.useMemo(() => new ImageService(), []);

    const ref = React.useRef<HTMLDivElement>(null);

    const getImages = () => {
        if (!ref.current) {
            return [];
        }

        return ref.current.querySelectorAll('img');
    };

    const fetchImage = React.useCallback(
        (image: HTMLImageElement) => {
            const src = image.getAttribute('src');

            if (src && !src.includes('blob') && !src.includes('base64')) {
                imageService.getImage(src).then(blob => {
                    if (blob) {
                        image.src = URL.createObjectURL(blob);
                    }
                });
            }
        },
        [imageService]
    );

    const handleImageClick = React.useCallback((image: HTMLImageElement) => setSelectedImage(image), []);

    const filteredHtml = React.useMemo(() => {
        if (conciseViewEnabled) {
            return html
                .replace(/<img[^>]*>/gi, '')
                .replace(/<br>/gi, '')
                .replace(/<p>/gi, '')
                .replace(/<\/p>/gi, ' ');
        }

        return html;
    }, [html, conciseViewEnabled]);

    React.useEffect(() => {
        const images = getImages();

        images.forEach(image => {
            fetchImage(image);

            image.addEventListener('click', () => handleImageClick(image));

            if (maxImageWidth && image.clientWidth > maxImageWidth) {
                image.style.width = `${maxImageWidth}px`;
            }

            if (imagePaddingRight) {
                image.style.paddingRight = `${imagePaddingRight}px`;
            }
        });

        setHasImages(images.length > 0);

        return () => images.forEach(image => image.removeEventListener('click', () => handleImageClick(image)));
    }, [html, maxImageWidth, imagePaddingRight, fetchImage, handleImageClick]);

    return (
        <>
            {conciseViewEnabled !== undefined && (
                <div
                    dangerouslySetInnerHTML={{ __html: filteredHtml }}
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: conciseViewEnabled ? undefined : 'none'
                    }}
                />
            )}

            <div
                ref={ref}
                className="html-renderer"
                dangerouslySetInnerHTML={{ __html: html }}
                style={{ whiteSpace: 'pre-wrap', display: conciseViewEnabled ? 'none' : undefined }}
            />

            {hasImages && !conciseViewEnabled && (
                <Image
                    preview={{
                        visible: !!selectedImage,
                        src: selectedImage?.src,
                        onVisibleChange: () => setSelectedImage(null)
                    }}
                />
            )}
        </>
    );
};

export default HTMLRenderer;
