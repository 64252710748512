import { CommentData } from '../../projects/types/CommentData';
import appClient from '../../common/services/AppClient';
import security from '../../common/services/SecurityService';
import { LotType } from '../types/TopicLotResult';
import { SetFinalResult } from '../types/SetFinalResult';

export default class SummaryReportService {
    downloadDocumentForLot(projectId: string, docId: string) {
        const encodedDocId = encodeURIComponent(docId);
        const encodedProjId = encodeURIComponent(projectId);
        security.invoke((token) => {
            const t = '?access_token=' + encodeURIComponent(token);
            const url = `${process.env.REACT_APP_SERVICE_URL}projects/${encodedProjId}/documents/${encodedDocId}/download${t}`;
            window.open(url);
            return Promise.resolve();
        });
    }
    
    editSpecData(projectId: string, specTypeId: string, documentId: string, topicName: string, sectionName: string, newValue: string, comment: string) {
        const request = {
            newValue,
            sectionName,
            topicName,
            comment
        };
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/specification_types/${specTypeId}/specification_documents/${documentId}/edit`;
        return appClient.post<CommentData>(url, request);
    }

        
    editLotData(projectId: string, documentId: string | undefined, specTypeId: string, sectionId: string, sectionName: string, 
                topicName: string, lot: LotType, newValue: string, comment: string) {
        const request = {
            newValue,
            sectionName,
            topicName,
            comment,
            lot,
            documentId,
            sectionId
        };
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/specification_types/${specTypeId}/documents/edit`;
        return appClient.post<CommentData>(url, request);
    }

    downloadSummaryReport(projectId: string) {
        const encodedProjId = encodeURIComponent(projectId);
        security.invoke((token) => {
            const t = '?access_token=' + encodeURIComponent(token);
            const url = `${process.env.REACT_APP_SERVICE_URL}projects/${encodedProjId}/documents/method_sheet_summary/download${t}`;
            window.open(url);
            return Promise.resolve();
        });
    }

    setFinalResult(request: SetFinalResult) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${request.projectId}/documents/${request.documentId}`;
        return appClient.update<CommentData | null>(url, request);
    }
}