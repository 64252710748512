import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { UserProfileStore } from '../../common/stores';
import AlphaPackagesStore from '../../common/stores/AlphaPackagesStore';
import { Utils } from '../../common/misc/Utils';
import { DocumentStateChange } from '../../common/types/DocumentStateChange';
import { ProjectsService } from '../services';
import HistoryService from '../services/HistoryService';
import { DvpMatrixColumn, DvpMatrixConfig, Project, ProjectCommentData, ProjectDocumentData, RocheDocument } from '../types';
import { HistoryData } from '../types/History';
import Specification from '../types/Specification';
import { SpecificationDocument } from '../types/SpecificationDocument';
import ProjectsStore from './ProjectsStore';
import MethodSheetReportStore from '../../method_sheet_report/stores/MethodSheerReportStore';
import { PackageState } from '../../common/types/PackageState';
import _ from 'lodash';
import ApplicationReportsService from '../services/ApplicationReportsService';
import { ResultApi } from '../../common/services/AppClient';
const ACTIVIY_INITIAL_VIEW_COUNT = 6;

class ProjectDashboardStore {
    project: Project | undefined = undefined;

    config: DvpMatrixConfig | undefined = undefined;

    isLoadingConfig: boolean = true;

    isLoadingDocuments: boolean = true;

    isLoadingSpecifications: boolean = true;

    isLoadingHistory: boolean = true;

    documents: RocheDocument[] = [];

    specifications: Specification[] = [];

    specificationDocuments: SpecificationDocument[] = [];

    specTableModel: SpecificationDocument[] = [];

    fileToUpload: RcFile | undefined = undefined;
    
    history: HistoryData[];    

    headers: {};

    viewAllHistory: boolean = false;

    uploadSpecTypeId: string;

    specDocToRemove: string;

    projectComments: ProjectCommentData[] = [];

    isLoadingProjectComments: boolean = true;

    projectDocuments: ProjectDocumentData[] = [];

    isLoadingProjectDocuments: boolean = true;

    showFileProcessSuccessMsg: () => void;

    constructor(public alphaPackageStore: AlphaPackagesStore, private service: ProjectsService, private projectsStore: ProjectsStore,  private historyService: HistoryService,
                private userProfileStore: UserProfileStore, public methodSheetReportStore: MethodSheetReportStore, private apprReportService: ApplicationReportsService) {
        makeObservable(this, {
            config: observable,
            isLoadingConfig: observable,
            isLoadingDocuments: observable,
            documents: observable,
            fileToUpload: observable,
            project: observable,
            headers: observable,
            specifications: observable,
            specTableModel: observable,
            isLoadingSpecifications: observable,
            isLoadingHistory: observable,
            history: observable,
            viewAllHistory: observable,
            uploadSpecTypeId: observable,
            projectComments: observable,
            isLoadingProjectComments: observable,
            projectDocuments: observable,
            isLoadingProjectDocuments: observable,
            fetchDvpMatrixConfig: action.bound,
            getDocumentByMatrixField: action.bound,
            setFileToUpload: action.bound,
            setProject: action.bound,
            setIsLoadingConfig: action.bound,
            setIsLoadingDocuments: action.bound,
            setDvpMatrixConfig: action.bound,
            setDocuments: action.bound,
            setSpecTableModel: action.bound,
            setHeaders: action.bound,
            setHistory: action.bound,
            updateSpecification: action.bound,
            setViewAllHistory: action.bound,
            setUploadSpecType: action.bound,
            addExistingDocument: action.bound,
            updateHistory: action.bound,
            setProjectComments: action.bound,
            setIsLoadingProjectComments: action.bound,
            setProjectDocuments: action.bound,
            setIsLoadingProjectDocuments: action.bound,
            getProjectComments: action.bound,
            addCommentToProject: action.bound,
            editComment: action.bound,
            deleteComment: action.bound,
            getProjectDocuments: action.bound,
            addDocumentToProject: action.bound,
            deleteDocument: action.bound,
            dvpMatrixColumns: computed,
            dvpMatrixRows: computed,
            // instrumentName: computed,
            isLoadingInstruments: computed,
            isLoadingMedhotSheetSummaryData: computed,
            filteredHistory: computed,
            currentUserId: computed,
        });

        this.projectsStore.specificationDoc.subscribe((p) => {
            if (p.projectId === this.project?.id) {
                this.updateSpecification(p); 
            }
        });

        this.projectsStore.activityData.subscribe((p) => {
            if (p.projectId === this.project?.id) {
                this.updateHistory(p); 
            }
        });
        
        this.showFileProcessSuccessMsg =  _.debounce(() => message.success('File has been successfully processed.'), 1000);

    }

    get currentUserId() {
        return this.userProfileStore.userInfo.userId;
    }

    get isLoadingInstruments() {
        return this.projectsStore.isLoadingAnalyzers;
    }

    get isLoadingMedhotSheetSummaryData() {
        return this.methodSheetReportStore.isLoading;
    }

    get projectStatus() {
        return this.methodSheetReportStore.projectStatus;
    }

    get projectId () {
        return this.project?.id;
    }

    // get instrumentName() {
    //     if (!this.project) {
    //         return null;
    //     }
    //     const instrument = this.projectsStore.analyzers.find(i => i.id === this.project?.instrument);
    //     return instrument?.name;
    // }

    get dvpMatrixColumns() {
        if (!this.config?.columns?.length) {
            return [];
        }

        const lotsCol: DvpMatrixColumn = {
            colId: 'lots',
            name: 'Lots'
        };

        return [lotsCol, ...this.config.columns];
    }

    get dvpMatrixRows() {
        if (!this.config?.rows?.length) {
            return [];
        }

        return this.config.rows;
    }

    get filteredHistory() {
        return (!this.viewAllHistory ? this.history.slice(0, ACTIVIY_INITIAL_VIEW_COUNT) : this.history).filter(h => h.activity !== 'MarkedAsRead');
    }

    setFileToUpload(file: RcFile | undefined) {
        this.fileToUpload = file;
    }

    setProject(project: Project | undefined) {
        this.project = project;
    }

    setIsLoadingConfig(isLoading: boolean) {
        this.isLoadingConfig = isLoading;
    }

    setIsLoadingDocuments(isLoading: boolean) {
        this.isLoadingDocuments = isLoading;
    }

    setDvpMatrixConfig(config: DvpMatrixConfig | undefined) {
        this.config = config;
    }

    setDocuments(documents: RocheDocument[]) {
        this.documents = documents;
    }

    setSpecTableModel(model: SpecificationDocument[]) {
        this.specTableModel = model;
    }

    setViewAllHistory(viewAll: boolean) {
        this.viewAllHistory = viewAll;
    }

    setUploadSpecType(id: string) {
        this.uploadSpecTypeId = id;
    }

    setSpecDocToRemove(id: string) {
        this.specDocToRemove = id;
    }

    getDocumentByMatrixField(matrixField: string) {
        if (!this.documents.length) {
            return undefined;
        }

        return this.documents.find(d => d.matrixField === matrixField);
    }

    async fetchDvpMatrixConfig() {
        this.setIsLoadingConfig(false);
        return;
        // if (!this.project?.instrument) {
        //     console.error('Project was not set up');
        //     return;
        // }
        // this.setIsLoadingConfig(true);
        // try {
        //     var resp = await this.service.getDvpMatrixConfig(this.project.instrument);
        //     this.setDvpMatrixConfig(resp);
        // } catch (err) {
        //     this.setDvpMatrixConfig(undefined);
        // } finally {
        //     this.setIsLoadingConfig(false);
        // }
    }

    async getProjectDocuments() {
        if (!this.project?.id) {
            console.error('Project was not set up');
            return;
        }
        this.setIsLoadingDocuments(true);
        try {
            var resp = await this.service.getProjectDocuments(this.project.id);
            this.setDocuments(resp);
        } catch (err) {
            this.setDocuments([]);
        } finally {
            this.setIsLoadingDocuments(false);
        }
    }

    getMethodSheetSummary() {
        this.methodSheetReportStore.getMethodSheetSummary();
    }

    resetNewContractDialogState() {
        this.alphaPackageStore.resetNewContractDialogState();
    }

    async uploadFile(type: string, matrixField: string) {
        if (!this.project?.id) {
            console.error('Project was not set up');
            return;
        }

        if (!this.fileToUpload) {
            console.error('File was not selected');
            return;
        }

        try {
            var resp = await this.service.uploadProjectDocument(this.project.id, matrixField, type, this.fileToUpload);
            if (resp.isOk()) {
                await this.getProjectDocuments();
            } else {
                message.error('Failed to upload file.');
                console.error(resp.error);
            }
        } catch (err) {
            console.error(err);
        }
    }

    
    async addExistingDocument(selectedPackageId: string) {
        await this.service.addExisitingDocument(this.project!.id, selectedPackageId, this.uploadSpecTypeId);
        
    }

    async deleteDocument(documentId: string) {
        try {
            var resp = await this.service.deleteProjectDocument(this.project?.id!, documentId);
            if (resp.isOk()) {
                message.success('Document has been successfully deleted.');
                await this.getProjectDocuments();
            } else {
                message.error('Failed to delete document.');
                console.error(resp.error);
            }
        } catch (err) {
            console.error(err);
        }
    }

    async getSpecificationDocuments() {
        this.isLoadingSpecifications = true;
        const specs = await this.service.getSpecificationDocuments(this.project!.id);
        this.setSpecTableModel(specs);
        this.isLoadingSpecifications = false;
    }

    getSpecDocById(id: string) {
        return this.specTableModel.find(s=> s.id === id)!;
    }

    async setHeaders() {
        const token =  await Utils.getAuthToken();
        this.headers  = {Authorization: 'Bearer ' + token};
    }

    async updateSpecification(doc: DocumentStateChange) {
        const index = this.specTableModel.findIndex(s => s.id === doc.documentId);
        const specs = this.specTableModel.slice();

        if (index < 0) {
            this.getSpecificationDocuments();
            return;
        }

        const completedStatuses = [PackageState.Completed, PackageState.UploadedWithoutAnalysis];

        if (completedStatuses.includes(doc.status) && (!completedStatuses.includes(specs[index].status) || specs[index].id !== doc.documentId)) {
            if (doc.uploadedBy === this.userProfileStore.userInfo.userName) {
                this.showFileProcessSuccessMsg();
            }
            this.methodSheetReportStore.getMethodSheetSummary();
            const resp = await this.apprReportService.getAllPackages(process.env.REACT_APP_ALPHA_PROJECT_ID!);
            this.alphaPackageStore.setPackages(resp);
        }
        if (index >= 0 && (!completedStatuses.includes(specs[index].status) || specs[index].id !== doc.documentId)) {
            specs[index] = {
                id: doc.documentId,
                specificationTypeName: specs[index].specificationTypeName, 
                name: specs[index].name, 
                packageId: doc.documentId,
                documentHistories: specs[index].documentHistories,
                ...doc };
            this.specTableModel = specs;
        }
    }

    async deleteSpecificationDocument() {
        await this.service.deleteSpecificationDocument(this.project!.id, this.specDocToRemove);
        await this.getSpecificationDocuments();
        this.getMethodSheetSummary();
    }

    handleSpecDownload(projectId: string, docId: string, useOverrideDocument: boolean = false) {
        this.service.handleSpecDownload(projectId, docId, useOverrideDocument);
    }
    
    async getHistory(hideLoader?: boolean) {
        if (!hideLoader) {
            runInAction(() => this.isLoadingHistory = true);
        }
       
        const history = (await this.historyService.getHistory(this.project!.id)).reverse();
        this.setHistory(history);
        const userIds = Array.from(new Set(history.map(h => h.userId)));
        await this.userProfileStore.setUserProfilePictures(userIds);

        if (!hideLoader) {
            runInAction(() => this.isLoadingHistory = false);
        }
    }

    setHistory = (history: HistoryData[]) => {
        this.history = history;
    };

    async uploadSpecDoc(specTypeId: string, file: RcFile, skipAnalysis: boolean = false, comment?: string)  {
        return await this.service.uploadSpecDoc(this.project!.id, specTypeId, file, skipAnalysis, comment);
    }

    updateHistory(activity: HistoryData) {
        this.history.unshift(activity);
    }

    setProjectComments(comments: ProjectCommentData[]) {
        this.projectComments = comments;
    }

    setIsLoadingProjectComments(val: boolean) {
        this.isLoadingProjectComments = val;
    }

    setProjectDocuments(documents: ProjectDocumentData[]) {
        this.projectDocuments = documents;
    }

    setIsLoadingProjectDocuments(val: boolean) {
        this.isLoadingProjectDocuments = val;
    }

    async getProjectComments() {
        try {
            if (this.projectId) {
                this.setIsLoadingProjectComments(true);
                const result = await this.service.getProjectComments(this.projectId);
                this.setProjectComments(result);
            }
        } catch(error) {
            message.error('Failed to load project comments');
            console.error(error);
        } finally {
            this.setIsLoadingProjectComments(false);
        }
    }

    async addCommentToProject(comment: string) {
        try {
            if (this.projectId) {
                const result = await this.service.addProjectComment(this.projectId, comment);
                await this.handleCommentOperation(result);
            }
        } catch(error) {
            message.error('Failed to add comment');
            console.error(error);
        }
    }

    async editComment(commentId: string, comment: string) {
        try {
            if (this.projectId) {
                const result = await this.service.editProjectComment(this.projectId, commentId, comment);
                await this.handleCommentOperation(result);
            }
        } catch(error) {
            message.error('Failed to edit comment');
            console.error(error);
        }
    }

    async deleteComment(commentId: string) {
        try {
            if (this.projectId) {
                const result = await this.service.deleteProjectComment(this.projectId, commentId);
                await this.handleCommentOperation(result);
            }
        } catch(error) {
            message.error('Failed to delete comment');
            console.error(error);
        }
    }

    async getProjectOtherDocuments() {
        try {
            if (this.projectId) {
                this.setIsLoadingProjectDocuments(true);
                const result = await this.service.getProjectOtherDocuments(this.projectId);
                this.setProjectDocuments(result);
            }
        } catch(error) {
            message.error('Failed to load project documents');
            console.error(error);
        } finally {
            this.setIsLoadingProjectDocuments(false);
        }
    }

    async addDocumentToProject(file: RcFile) {
        try {
            if (this.projectId) {
                const result = await this.service.uploadProjectOtherDocument(this.projectId, file);
                await this.handleDocumentOperation(result);

                return result;
            }

            return null;
        } catch(error) {
            message.error('Failed to add document');
            console.error(error);

            return null;
        }
    }

    async deleteOtherDocument(documentId: string) {
        try {
            if (this.projectId) {
                const result = await this.service.deleteProjectOtherDocument(this.projectId, documentId);
                await this.handleDocumentOperation(result);
            }
        } catch(error) {
            message.error('Failed to delete document');
            console.error(error);
        }
    }

    async downloadDocument(documentId: string) {
        try {
            if (this.projectId) {
                await this.service.handleOtherDocumentownload(this.projectId, documentId);
            }
        } catch(error) {
            message.error('Failed to download document');
            console.error(error);
        }
    }

    private async handleCommentOperation(result: ResultApi<unknown>) {
        if (result.isOk()) {
            await this.getProjectComments();
        } else {
            message.error('Failed to add comment');
            console.log(result);
        }
    }

    private async handleDocumentOperation(result: ResultApi<unknown>) {
        if (result.isOk()) {
            await this.getProjectOtherDocuments();
        } else {
            message.error('Failed to delete document');
            console.log(result);
        }
    }
}

export default ProjectDashboardStore;