import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Modal } from 'antd';
import SummaryReportStore from '../stores/SummaryReportStore';
import ModalTitle from '../../common/components/ModalTitle';
import EditValueForm from './EditValueForm';

type Props = {
    isModalVisible: boolean;
    isDataChangeModalVisible: boolean;
    store: SummaryReportStore;
    closeDialog: () => void
};

const EditValueDialog: React.FC<Props> = ({isModalVisible, isDataChangeModalVisible, store, closeDialog}) => {
    return (
        <Modal
            width={800}
            title={<ModalTitle title={`${!isDataChangeModalVisible ? 'Edit' : ''} ${store.editableTopic}`} onCloseCallback={closeDialog}/>}
            visible={isModalVisible || isDataChangeModalVisible}
            className="roche-client-dialog large-input comment-in-modal"
            closable={false}
            footer={isModalVisible && [
                <Button 
                    data-id="assign-cancel-button"
                    key="assign-cancel-button" 
                    className="light"
                    size="large"
                    onClick={closeDialog}
                >
                    Cancel
                </Button>,
                <Button 
                    data-id="assign-save-button"
                    key="assign-save-button" 
                    type="primary"
                    size="large"
                    htmlType="submit"
                    form="summary-report-edit-value-form"
                >
                    Save changes
                </Button>
            ]}
        >
            <EditValueForm store={store} isDataChangeModalVisible={isDataChangeModalVisible} closeDialog={closeDialog} />
        </Modal>
    );
};

export default observer(EditValueDialog);

