import Table, { ColumnProps } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../../common/misc/Utils';
import AuditStore from '../stores/AuditStore';
import { TopicHistoryItem, TopicHistoryItemType, TopicHistorySource } from '../types';
type Props = {
    store: AuditStore;
    topicItem: TopicHistoryItem;
    projectId: string
};

export const TopicSourceTable: React.FC<Props> = ({store, topicItem, projectId}) => {
    const columns: ColumnProps<TopicHistorySource>[] = [
        {
            dataIndex: 'source',
            title: 'Source(s)',
            render: (source: string, record: TopicHistorySource) => {
                return { 
                    children: record.isManual ? 'Manual Edit' : source,
                    props: {
                        'data-id-cells': 'Source'
                    }
                };
            }
        },
        {
            dataIndex: 'value',
            title: 'Value',
            render: (value: string) => {
                return { 
                    children: <div style={{whiteSpace: 'pre-wrap'}}>{value}</div> ,
                    props: {
                        'data-id-cells': 'Value'
                    }
                };
            }
        },
        {
            dataIndex: 'page',
            title: 'Page',
            render: (page: string) => {
                return {
                    children: page,
                    props: {
                        'data-id-cells': 'Page'
                    }
                };
            }
        },
        {
            dataIndex: 'userName',
            title: 'User',
            render: (userName: string) => {
                return {
                    children: userName,
                    props: {
                        'data-id-cells': 'User'
                    }
                };
            }
        },
        {
            dataIndex: 'changeDate',
            title: 'Date/Time',
            render: (dateString: string) => {
                return { 
                    children: Utils.formatDateStringShort(dateString, false, true), 
                    props: {
                        'data-id-cells': 'Date/Time',
                        'data-id-timestamp': dateString
                    }
                };
            }
        },
        {
            dataIndex: 'documentUrl',
            title: 'View in Document',
            render: (documentUrl: string, record: TopicHistorySource) => {
                const docRoute = topicItem.topicHistoryItemType === TopicHistoryItemType.PSD ? 'specification_documents' : 'documents';
                return { 
                    children: (<a 
                    // eslint-disable-next-line max-len
                        href={`${process.env.PUBLIC_URL}/projects/${record.projectId}/${docRoute}/${encodeURIComponent(record.documentId)}/${encodeURIComponent(record.source)}`} 
                        target="_blank" rel="noreferrer"
                    >
                        <div>Click to Preview</div>
                    </a>),
                    props: {
                        'data-id-cells': 'View in Document'
                    }
                };
            }
        },
    ];

    return (
        <Table
            data-testid='audit-table'
            data-id="table-source-list"
            data-id-cells={`${projectId}-${topicItem.topic}`}
            className='roche-table lg-title no-border-top cursor-pointer'
            dataSource={store.getTopicSources(projectId, topicItem)}
            columns={columns}
            rowKey={r => `${r.source}-${r.changeDate}`}
            rowClassName="nested-row"
            pagination={{
                showSizeChanger: false,
                pageSize: 20,
                hideOnSinglePage: true
            }}
        />
    );
};

export default observer(TopicSourceTable);
