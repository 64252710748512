import { UserInfo } from '../types/UserInfo';
import appClient from './AppClient';

export class UserService {
    async getUserInfo() {
        const url = `${process.env.REACT_APP_MANAGE_URL}user-info`;
        const resp = await appClient.get<UserInfo>(url);  
        return resp.unwrapOr({} as UserInfo);
    }

    updateUserProfile(data: FormData) {
        const url = `${process.env.REACT_APP_MANAGE_URL}user-info`;
        return appClient.post(url, data);
    }

    async getAvatarIcon(fileId: string) {
        const url = `${process.env.REACT_APP_MANAGE_URL}user-info/icon/${fileId}`;
        const resp = await appClient.get<Blob | undefined>(url, 'blob');
        return resp.unwrapOr(undefined);
    }

    async getUsersInfo(userIds: string[]) {
        const request ={
            userIds
        };
        const url = `${process.env.REACT_APP_MANAGE_URL}users-info`;
        const resp = await appClient.post<UserInfo[]>(url, request);
        return resp.unwrapOr([] as UserInfo[]);
    }
}