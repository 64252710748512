import Table, { ColumnProps } from 'antd/lib/table';
import { Observer, observer } from 'mobx-react-lite';
import * as React from 'react';
import { Utils } from '../../common/misc/Utils';
import { CommentData } from '../../projects/types/CommentData';
import AuditStore from '../stores/AuditStore';
import { ProjectHistoryItem, TopicHistoryItem } from '../types';
import CommentsModal from './CommentsModal';
import TopicSourceTable from './TopicSourceTable';
type Props = {
    store: AuditStore;
    projectItem: ProjectHistoryItem
};

export const AuditTopicsTable: React.FC<Props> = ({store, projectItem}) => {
    const [isCommentsDialogVisible, setIsCommentsDialogVisible] = React.useState<boolean>(false);

    const handleCommentViewClick = (e: React.MouseEvent, topic: string, comments: CommentData[]) => {
        e.stopPropagation();
        store.setCurrentComments(comments);
        store.setCurrentTopic(topic);
        setIsCommentsDialogVisible(true);
    };

    const columns: ColumnProps<TopicHistoryItem>[] = [
        {
            dataIndex: 'topic',
            title: 'Topic',
            width: '15%',
            render: (topic: string) => {
                return {
                    props: {
                        'data-id-cells': 'Topic'
                    },
                    children: topic
                };
            }
        },
        {
            dataIndex: 'currentValue',
            title: 'Value',
            render: (value: string, item: TopicHistoryItem) => {
                return {
                    children: (<Observer>
                        {() => (
                            <div className={!store.expandedTopics.includes(`${item.section}-${item.topic}-${item.topicHistoryItemType}`) ? 'result-container'
                                : 'result-container expanded'}>{value}</div>
                        )}
                    </Observer>),
                    props: {
                        'data-id-cells': 'Value'
                    }
                };
            },
            width: '20%'
        },
        {
            dataIndex: 'topicHistoryItemType',
            title: 'Type',
            render: (type: string) => {
                return {
                    props: {
                        'data-id-cells': 'Type'
                    },
                    children: type
                };
                        
            }
        }, {
            dataIndex: 'updateDate',
            title: 'Updated',
            render: (dateString: string) => {
                return { 
                    children: Utils.formatDateStringShort(dateString, true, true),
                    props: {
                        'data-id-cells': 'Updated'
                    }
                };
            }
        },
        {
            dataIndex: 'isEdited',
            title: 'Edit',
            render: (isEdited: boolean) => {
                return { 
                    children: (isEdited ? 
                        <div className='edited-container'>
                            <i className='roche-icon sm cell-warning' /> 
                            <span>Edited</span>
                        </div>
                        : ''),
                    props: {
                        'data-id-cells': 'Edit'
                    }
                };
            }
        },
        {
            dataIndex: 'comments',
            title: 'Comment(s)',
            width: '10%',
            render: (comments: CommentData[], record: TopicHistoryItem) => {
                return { 
                    children: (comments.length ? <span onClick={(e) => handleCommentViewClick(e, record.topic, record.comments)}>View comments</span> : ''),
                    props: {
                        'data-id-cells': 'Comment(s)'
                    }
                };
            }
        }
    ];

    const expandedRowRender =  (item: TopicHistoryItem) => {
        return <TopicSourceTable store={store} topicItem={item} projectId={projectItem.projectId}/>;
    };

    const handleOnExpand = (isExpanded: boolean, item: TopicHistoryItem) => {
        store.setExpandedTopic(isExpanded, item.section, item.topic, item.topicHistoryItemType);
    };

    return (
        <>
            <CommentsModal store={store} isModalVisible={isCommentsDialogVisible} closeDialog={() => setIsCommentsDialogVisible(false)}/>
            <Table
                data-testid='audit-table'
                data-id="table-topic-list"
                data-id-cells={projectItem.name}
                className='roche-table lg-title no-border-top cursor-pointer audit-topics-table concise'
                dataSource={store.projectItems.find(t=> t.projectId === projectItem.projectId)!.topicHistoryItems}
                columns={columns}
                rowKey={r => `${r.section}-${r.topic}-${r.topicHistoryItemType}`}
                rowClassName="nested-row"
                pagination={{
                    showSizeChanger: false,
                    pageSize: 20,
                    hideOnSinglePage: true
                }}
                expandable={{expandRowByClick: true, expandedRowRender: expandedRowRender, onExpand: handleOnExpand}}
            />
        </>
    );
};

export default observer(AuditTopicsTable);
