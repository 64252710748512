import {
    observable,
    runInAction,
    action,
    reaction,
    IReactionDisposer,
    makeObservable,
} from 'mobx';
import ImageService from '../../common/services/ImageService';
import { ImagePreviewSize } from '../../common/types/Document';
import { AlphaPackage, AlphaPackageStateResult } from '../../common/types/AlphaPackage';

type PackagePreviewPendingModel = {
    packageId: string; 
    imageSize: ImagePreviewSize
};
export default class PreviewsVisualStore {    
    smallPreviewUrls: {[packageId: string]: string} = {};

    largePreviewUrls: {[packageId: string]: string} = {};

    packagePreviewPending: PackagePreviewPendingModel | undefined;

    disposer: IReactionDisposer | null;

    previewLoadRetries: number = 0;

    packages: AlphaPackage[] = [];

    constructor(private imageService: ImageService) {
        makeObservable<PreviewsVisualStore, 'getImagePreviewById'>(this, {
            smallPreviewUrls: observable,
            largePreviewUrls: observable,
            packagePreviewPending: observable,
            setPackagePreviewPending: action.bound,
            getPreviews: action.bound,
            getImagePreviewById: action.bound
        });
    }

    setPackages(packages: AlphaPackage[]) {
        this.packages = packages;
    }

    setPackagePreviewPending(pkgPreviewPending: PackagePreviewPendingModel | undefined) {
        this.packagePreviewPending = pkgPreviewPending;
    }

    async getPreviews(packageId: string, imageSize: ImagePreviewSize) {
        const pkg = this.packages.find(p => p.id === packageId);
        if (pkg && pkg.state !== AlphaPackageStateResult.Ready) {
            if (!this.disposer) {
                this.disposer = reaction(() => pkg.state, (s) => {
                    if (s === AlphaPackageStateResult.Ready) {
                        this.getPreviews(pkg.id, imageSize);
                    }
                });
            }            
        } else if (pkg) {
            await this.getImagePreviewById(packageId, imageSize, pkg.indexDate);
        } else {
            await this.getImagePreviewById(packageId, imageSize);
        }
    }

    private async getImagePreviewById(packageId: string, imageSize: ImagePreviewSize, indexDate: string = '') {
        if (this.disposer) {
            this.disposer();
            this.disposer = null;
        }
        
        this.disposer = null;
        const resp = await this.imageService.getImagePaths(packageId, indexDate, 0, 1);
        resp.map(imageNames => {
            if (imageNames.length === 0 && this.previewLoadRetries < 5) {
                setTimeout(async () => {
                    await this.getPreviews(packageId, imageSize);
                    this.previewLoadRetries++;
                },         5000);
            } else {
                const url = this.imageService.getImageUrlFromPath(imageNames[0], imageSize, indexDate);
                runInAction(() => {
                    if (imageSize === ImagePreviewSize.Small) {
                        this.smallPreviewUrls[packageId] = url;
                    } else {
                        this.largePreviewUrls[packageId] = url;
                    }
                });
            }
        }).mapErr(() => {
            if (this.previewLoadRetries < 5) {
                setTimeout(async () => {
                    await this.getPreviews(packageId, imageSize);
                    this.previewLoadRetries++;
                },         5000);
            } else {
                this.previewLoadRetries = 0;
            }
        });
    }
}