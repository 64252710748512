import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { ProjectsList } from '../components';
import { useProjectsStore } from '../stores/context';
import { WrappedMatomoTracker } from '../../common/components';

const ProjectsPage: React.FC = () => {
    const projectsStore = useProjectsStore().projectsStore;

    return(
        <>
            <WrappedMatomoTracker documentTitle={'Projects List'} />
            <ProjectsList store={projectsStore} />
        </>
    );
};

export default observer(ProjectsPage);