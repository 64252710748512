import * as React from 'react';
import { observer, Observer } from 'mobx-react-lite';
import { Modal, Row, Col, AutoComplete, Button, Input, Spin } from 'antd';
import PackageList from './PackageList';
import { AlphaPackage, AlphaPackageStateResult } from '../../common/types/AlphaPackage';
import { ImagePreviewSize } from '../../common/types/Document';
import ImageWithFallback from './ImageWithFallback';
import errorImg from '../../common/resources/images/image-error.png';
import AlphaPackagesStore from '../../common/stores/AlphaPackagesStore';

export const NEW_ANALYSIS_CONTAINER_INITIAL_HEIGHT = 475;
const NEW_ANALYSIS_CONTAINER_INITIAL_WIDTH = 425;
type Props = {
    alphaPackagesStore: AlphaPackagesStore;
    addExistingDocument: (id: string) => Promise<void>;
    visible: boolean;
    closeDialog: () => void
};

export const NewContractDialog: React.FC<Props> = ({ alphaPackagesStore, addExistingDocument, visible, closeDialog }) => {   
    const { previewVisualStore } = alphaPackagesStore;
    React.useEffect(() => {
        if (alphaPackagesStore.selectedPackageId) {
            const selectedPackage = alphaPackagesStore.filteredPackages.find(p => p.id === alphaPackagesStore.selectedPackageId);

            if (selectedPackage && selectedPackage.state !== AlphaPackageStateResult.Ready) {
                previewVisualStore.setPackagePreviewPending({packageId: alphaPackagesStore.selectedPackageId, imageSize: ImagePreviewSize.Large});
            } else if (!previewVisualStore.largePreviewUrls[alphaPackagesStore.selectedPackageId]) {
                previewVisualStore.setPackagePreviewPending(undefined);
                previewVisualStore.getPreviews(alphaPackagesStore.selectedPackageId, ImagePreviewSize.Large);
            } else {
                previewVisualStore.setPackagePreviewPending(undefined);
            }
        } else {
            previewVisualStore.setPackagePreviewPending(undefined);
        }
    },              [previewVisualStore, alphaPackagesStore.filteredPackages, alphaPackagesStore.selectedPackageId]);


    const modalHeader = () => {
        return (
            <div className="label-with-icon">
                <span>New Analysis</span>
            </div>
        );
    };

    const handleAnalyseClick = () => {
        closeDialog();
        addExistingDocument(alphaPackagesStore.selectedPackageId);
        alphaPackagesStore.resetNewContractDialogState();
    };

    const handleOnCancel = () => {
        closeDialog();
        alphaPackagesStore.resetNewContractDialogState();
    };

    const modalFooter = () => {
        return (
            <div style={{marginTop: 4}}>
                <Button className="light" onClick={handleOnCancel}>Cancel</Button>                    
                <Button type="primary" disabled={!alphaPackagesStore.selectedPackageId} onClick={handleAnalyseClick}>Analyse</Button>                    
            </div>
        );
    };

    const selectAutocompleteVal = (val: string) => {
        alphaPackagesStore.setAutocompleteSearchKey(val); 
        alphaPackagesStore.setPackageSearchKey(val); 
    };

    const getSelectedPackagePreview = () => {
        return (
            <>
                {alphaPackagesStore.selectedPackageId
                    ? 
                    alphaPackagesStore.selectedPackageId && previewVisualStore.largePreviewUrls[alphaPackagesStore.selectedPackageId]
                        ? (
                            <ImageWithFallback 
                                src={previewVisualStore.largePreviewUrls[alphaPackagesStore.selectedPackageId]}
                                fallbackSrc={errorImg}
                            />
                        )
                        : <Spin data-testid="new-contract-dialog-sping" size="large" />
                    :
                    <div data-testid="document-preview-placeholder" className="document-preview-placeholder">
                        <div className="placeholder-icon-container">
                            <i className="document-placeholder-icon" />
                        </div>
                        <div className="document-placeholder-message">
                        Select a document to get a preview.
                        </div>
                    </div>
                }
            </>
        );
    };

    
    const selectPackage = (pkg: AlphaPackage) => {
        if (pkg.state !== AlphaPackageStateResult.Broken) {
            alphaPackagesStore.setSelectedPackageId(pkg.id);
        }
    };

    return (
        <Modal
            className="alpha-new-contract"
            maskClosable={false}
            closable={false}
            visible={visible}
            title={modalHeader()}
            footer={modalFooter()}
            onCancel={closeDialog}
            onOk={closeDialog}
            centered
            destroyOnClose
            width={850}
        >
            <Row>
                <Col span={12}>
                    <Row className="modal-header-container">
                        <Col offset={15} span={9} style={{ textAlign: 'right' }}>
                            <span>{alphaPackagesStore.packageSortingDirection === 'Asc' ?  
                                <i className="roche-icon sm arrow-up" style={{verticalAlign: 'middle', height: 19}}/> :  
                                <i className="roche-icon sm arrow-down" style={{verticalAlign: 'middle', height: 19}}/>}
                            </span>
                            <span style={{color: '#9BA0AA'}}>Sorted by&nbsp;</span>
                            <div
                                data-id="new-contract-sort-link-button"
                                className="label link analysis-sort"
                                onClick={alphaPackagesStore.togglePackageSorting}
                            >
                                {alphaPackagesStore.packageSorting}
                            </div>
                        </Col>
                    </Row>
                    <Row className="modal-controls-container">
                        <Col span={24}>
                            <AutoComplete
                                style={{width: '100%', height: 42}}
                                options={alphaPackagesStore.packagesForAutocomplete && alphaPackagesStore.packagesForAutocomplete.length 
                                    ? alphaPackagesStore.packagesForAutocomplete.map(s => {
                                        return {label: s, value: s}; 
                                    }) 
                                    : []}
                                onSearch={alphaPackagesStore.setAutocompleteSearchKey}
                                onSelect={selectAutocompleteVal}
                                value={alphaPackagesStore.autocompleteSearchKey}
                            >
                                <Input
                                    data-id="new-contract-search-input"
                                    className="alpha-search-input"
                                    placeholder="Search by name, tags, etc..."
                                    onPressEnter={() => alphaPackagesStore.setPackageSearchKey(alphaPackagesStore.autocompleteSearchKey)}
                                    value={alphaPackagesStore.autocompleteSearchKey}
                                    addonAfter={
                                        <>
                                            <i 
                                                className={'roche-icon sm circle-cross clear-search'} 
                                                // eslint-disable-next-line max-len
                                                style={!alphaPackagesStore.autocompleteSearchKey ? {visibility: 'hidden', verticalAlign: 'middle', marginRight: 15} : {verticalAlign: 'middle',  marginRight: 15}}
                                                onClick={() => {
                                                    alphaPackagesStore.setAutocompleteSearchKey(''); alphaPackagesStore.setPackageSearchKey(''); 
                                                }}
                                            />
                                            <i 
                                                className="roche-icon table-search sm" 
                                                style={{verticalAlign: 'middle', backgroundPosition: 'center'}} 
                                                onClick={() => alphaPackagesStore.setPackageSearchKey(alphaPackagesStore.autocompleteSearchKey)}/>
                                        </>
                                    }
                                />
                            </AutoComplete>
                        </Col>
                    </Row>
                    <div style={{ height: NEW_ANALYSIS_CONTAINER_INITIAL_HEIGHT, width: NEW_ANALYSIS_CONTAINER_INITIAL_WIDTH }}>
                        <PackageList alphaPackagesStore={alphaPackagesStore} selectPackage={selectPackage} visible={visible} />
                    </div>
                </Col>
                <Col span={12} className="alpha-document-preview">
                    <Observer>
                        {() => (
                            <div className='analysis-preview-container'>
                                {getSelectedPackagePreview()}
                            </div>
                        )}
                    </Observer>
                    <div className="file-info">
                        <span>{alphaPackagesStore.selectedPackageName}</span>;
                    </div>
                </Col>
            </Row>
        </Modal>
    );
};

export default observer(NewContractDialog);