import { Pagination } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { SecurityService } from '../../common/services';

type Props = {
    projectId?: string;
    documentId: string;
    docType: string
};

const PdfDocumentPreview: React.FC<Props> = ({projectId, documentId, docType}) => {
    const [pages, setPages] = React.useState(1);
    const [pageNumber, setPageNumber] = React.useState(1);

    function onDocumentLoadSuccess({ numPages }: { numPages: number}) {
        setPages(numPages);
    }

    return (
        <div style={{textAlign: 'center', marginTop: 20}}>
            {pages > 1 ? <Pagination defaultCurrent={1} showQuickJumper showLessItems pageSize={1} total={pages} onChange={setPageNumber} /> : null}
            <Document 
                file={{ 
                    url: process.env.REACT_APP_SERVICE_URL + `projects/${projectId}/${docType}/${documentId}/download?useOverrideDocument=true`, 
                    httpHeaders: { 'Authorization': 'Bearer ' + SecurityService.token } 
                }} 
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} />
            </Document>
        </div>
    );
};

export default observer(PdfDocumentPreview);