import React from 'react';
import { CellType } from '../types/CommentData';
import { HistoryData, Parameter, ParameterNameType } from '../types/History';
import { HTMLRenderer } from '../../common/components';

export const getFormattedHistory = (history: HistoryData) => {
    const parameter = history.parameters.find(p => [ParameterNameType.Name, ParameterNameType.Text, ParameterNameType.OverridingPackageName].includes(p.parameterName));
    const getCommentLocation = () => {
        const commentType = history.parameters.find(p => p.parameterName === ParameterNameType.CommentType);
        switch (commentType!.newValue) {
        case CellType.Specification:
            return 'Specification';
        case CellType.Lot:
            return history.parameters.find(p => p.parameterName === ParameterNameType.Lot)!.newValue;
        default: 
            return 'Method sheet';
        }
    };

    const getParsedEditSpecHistory = () => {
        const valueParam = history.parameters.find(p => p.parameterName === ParameterNameType.EditedTopicKeyValue)!;
        const topicParam = history.parameters.find(p => p.parameterName === ParameterNameType.EditedValueTopicName)!;
        const newVal = valueParam.newValue;
        const lastVal = valueParam.lastValue;

        return (
            <>
                <span className="light-grey"> changed </span> {topicParam.newValue}
                <span className="light-grey"> from</span> {lastVal ? <HTMLRenderer html={lastVal} maxImageWidth={150} /> : 'empty'}
                <span className="light-grey"> to</span> <HTMLRenderer html={newVal} maxImageWidth={150} />
            </>
        );
    };

    const getParsedLotHistory = () => {
        const topicName = history.parameters.find(p => p.parameterName === ParameterNameType.TopicName)!.lastValue;
        const lot = history.parameters.find(p => p.parameterName === ParameterNameType.Lot)!.lastValue;
        const lastValue = history.parameters.find(p => p.parameterName === ParameterNameType.CurrentValue)!.lastValue;
        const newValue = history.parameters.find(p => p.parameterName === ParameterNameType.CurrentValue)!.newValue;

        return (
            <>
                <span className="light-grey"> changed </span>
                {topicName}, {` ${lot} `}
                <span className="light-grey">from</span>
                <HTMLRenderer html={lastValue!} maxImageWidth={150} />
                <span className="light-grey"> to</span>
                <HTMLRenderer html={newValue!} maxImageWidth={150} />
            </>
        );
    };

    const getParsedAddLotHistory = () => {
        const newValue = history.parameters.find(p => p.parameterName === ParameterNameType.CurrentValue)!.newValue;
        const topic = history.parameters.find(p => p.parameterName === ParameterNameType.TopicName)!.newValue;
        const lot = history.parameters.find(p => p.parameterName === ParameterNameType.Lot)!.newValue;

        return (
            <>
                <span className="light-grey"> added value </span>
                <HTMLRenderer html={newValue} maxImageWidth={150} />
                <span className="light-grey"> to </span>
                {topic}
                {` ${lot} `}
            </>
        );
    };

    const getEditProjectActivites = () => {
        const getChangeActivity = (changeMessage: string, param: Parameter) => {
            return <><span className='light-grey'> {changeMessage}</span> {param!.lastValue || 'unset'} <span className='light-grey'>to</span> {param!.newValue}</>;
        };

        const activities = history.parameters.filter(p => p.lastValue !== p.newValue).map(p => {
            switch(p.parameterName) {
            case ParameterNameType.Name:
                return getChangeActivity('renamed project from', p);
            case ParameterNameType.Analyzer:
                return getChangeActivity('changed analyzer from', p);
            case ParameterNameType.ProductName:
                return getChangeActivity('changed product name from', p);
            case ParameterNameType.DevelopmentLead:
                return getChangeActivity('changed development lead from', p);
            case ParameterNameType.Gpid:
                return getChangeActivity('changed GPID from', p);
            case ParameterNameType.Lpn:
                return getChangeActivity('changed LPN from', p);
            case ParameterNameType.DiaDocVersion:
                return getChangeActivity('changed DIADoc version from', p);
            case ParameterNameType.KitSize:
                return getChangeActivity('changed Kit size from', p);
            case ParameterNameType.MaterialNumberAssay:
                return getChangeActivity('changed Material number assay from', p);
            case ParameterNameType.MsVersionAssay:
                return getChangeActivity('changed MS Version array from', p);
            case ParameterNameType.MaterialNumberCalSet:
                return getChangeActivity('changed Material number CalSet from', p);
            case ParameterNameType.MsVersionCalSet:
                return getChangeActivity('changed MS Version CalSet from', p);
            case ParameterNameType.NameOfTheCalSet:
                return getChangeActivity('changed Name of the CalSet from', p);
            case ParameterNameType.ApplicationIsNormal:
                if (p.newValue === 'True') {
                    return <><span className='light-grey'> set application to</span> normal</>;
                }
                return <span className='light-grey'> disabled normal application</span>;
            case ParameterNameType.ApplicationIsStat:
                if (p.newValue === 'True') {
                    return <span className='light-grey'> set application as STAT</span>;
                }
                return <span className='light-grey'> disabled STAT application</span>;
            case ParameterNameType.Inpack:
                return getChangeActivity('changed Inpack from', p);
            case ParameterNameType.MaterialNumbersPreciControl1:
                return getChangeActivity('changed Material numbers PreciControl 1 from', p);
            case ParameterNameType.NamesPreciControl1:
                return getChangeActivity('Names PreciControl 1 from', p);
            case ParameterNameType.MsVersionPreciControl1:
                return getChangeActivity('MS Version PreciControl 1 from', p);
            case ParameterNameType.MaterialNumbersPreciControl2:
                return getChangeActivity('Material numbers PreciControl 2 from', p);
            case ParameterNameType.NamesPreciControl2:
                return getChangeActivity('Names PreciControl 2 from', p);
            case ParameterNameType.MsVersionPreciControl2:
                return getChangeActivity('MS Version PreciControl 2 from', p);
            case ParameterNameType.ResultCount:
                return getChangeActivity('changed number of results from', p);
            default:
                return '';
            }
        });
        
        if (!activities.length) {
            return null;
        }

        const filtered = activities.filter(a=> a);
        return filtered.map((a, i)=> <>{a}<span>{i === filtered.length - 1 ? '.' : ','}</span></>);
    };

    switch(history.area) {
    case 'Project' :
        switch (history.activity) {
        case 'Create':
            return (<><span className='light-grey'> created a project</span> <span>{parameter!.newValue}</span></>);
        case 'Duplicate':
            // eslint-disable-next-line no-case-declarations
            const duplicateProjectShortId = history.parameters.find(
                p => p.parameterName === ParameterNameType.DuplicateProjectShortId
            );
            return (
                <>
                    <span className="light-grey"> duplicated a project</span> <span>{parameter!.newValue}</span>
                    {!!duplicateProjectShortId && (
                        <>
                            <span className="light-grey"> from project</span>
                            {` ${duplicateProjectShortId.newValue}`}
                        </>
                    )}
                </>
            );
        case 'Edit':
            return getEditProjectActivites();
        case 'UploadSpecificationDocument':
            return  <><span className='light-grey'> uploaded document </span> <span style={{whiteSpace: 'nowrap'}}>{parameter!.newValue}</span>
                <span className='light-grey'><br/>to</span>
                {` ${history.parameters.find(p => p.parameterName === ParameterNameType.SpecificationTypeId)!.newValue}`}</>;
        case 'UploadSpecificationDocumentWithoutAnalysis':
            return  <><span className='light-grey'> uploaded document </span> <span style={{whiteSpace: 'nowrap'}}>{parameter!.newValue}</span>
                <span className='light-grey'> without analysis </span>
                <span className='light-grey'><br/>to</span>
                {` ${history.parameters.find(p => p.parameterName === ParameterNameType.SpecificationTypeId)!.newValue}`}</>;
        case 'AddSpecificationDocument':
            return  <><span className='light-grey'> added document </span> <span style={{whiteSpace: 'nowrap'}}>{parameter!.newValue}</span>
                <br/><span className='light-grey'>to</span>
                {` ${history.parameters.find(p => p.parameterName === ParameterNameType.SpecificationTypeId)!.newValue}`}</>;
        case 'UploadDocument':
            return  <><span className='light-grey'> uploaded document</span> <span style={{whiteSpace: 'nowrap'}}>{parameter!.newValue}</span>
                <br/><span className='light-grey'>to</span> {`${history.parameters.find(p => p.parameterName === ParameterNameType.TopicName)!.newValue},  
                ${history.parameters.find(p => p.parameterName === ParameterNameType.Lot)!.newValue}`}</>;
        case 'DeleteSpecificationDocument':
        case 'DeleteDocument':
            return <><span className='light-grey'> deleted document </span> {parameter!.newValue}</>;
        case 'AddDocument':
            return  <><span className='light-grey'> added document</span> <span style={{whiteSpace: 'nowrap'}}>{parameter!.newValue}</span><br/>
                <span className='light-grey'>to</span>
                {` ${history.parameters.find(p => p.parameterName === ParameterNameType.TopicName)!.newValue}, 
                ${history.parameters.find(p => p.parameterName === ParameterNameType.Lot)!.newValue}`}</>;
        case 'EditSpecificationValue':
            return getParsedEditSpecHistory();
        case 'EditLotValue':
            return getParsedLotHistory();
        case 'AddLotValue':
            return getParsedAddLotHistory();
        case 'Archiving':
            return  <span className='light-grey'> added current project to archive</span>;
        case 'Restoring':
            return  <span className='light-grey'> restored current project</span>;
        case 'ChangeIsResult':
            return  <><span className='light-grey'>{history.parameters.find(p => p.parameterName === ParameterNameType.IsResult)!.newValue === 'True' ? 
                ' set value as final result in': ' removed final result from'}</span>
            {` ${history.parameters.find(p => p.parameterName === ParameterNameType.TopicName)!.newValue}, 
            ${history.parameters.find(p => p.parameterName === ParameterNameType.Lot)!.newValue}`}</>;
        default:
            return '';
        }
    case 'Comment':
        switch (history.activity) {
        case 'AddedRational':
            return <>
                <span className='light-grey'> added rational to </span> 
                <span style={{whiteSpace: 'nowrap'}}>{history.parameters.find(p => p.parameterName === ParameterNameType.DocumentName)?.newValue}</span>
                <br/>
                <span className='comment' dangerouslySetInnerHTML={{__html:parameter!.newValue}}/>
            </>;
        default:
            return <>
                <span className='light-grey'> posted a comment </span>
                <br/>
                <span className='comment' dangerouslySetInnerHTML={{__html:parameter!.newValue}}/>  
                <span className='light-grey'>to</span> {`${history.parameters.find(p => p.parameterName === ParameterNameType.Topic)?.newValue},
            ${getCommentLocation()}`}</>;
        }
    case 'Document':
        switch (history.activity) {
        case 'SetNotAvailableStatus':
            return <><span className='light-grey'> marked </span>{history.parameters.find(p => p.parameterName === ParameterNameType.TopicName)!.newValue},
                {` ${history.parameters.find(p => p.parameterName === ParameterNameType.Lot)!.newValue} as N/A `} </>;
        case 'SetAvailableStatus':
            return <><span className='light-grey'> marked </span>{history.parameters.find(p => p.parameterName === ParameterNameType.TopicName)!.newValue},
                {` ${history.parameters.find(p => p.parameterName === ParameterNameType.Lot)!.newValue} as Applicable `} </>;
        default:
            return '';
        }
    default:
        return '';
    }
};