import security from '../services/SecurityService';

export class Utils {
    static htmlTags = /<(.|\n)*?>/g;
    static formatDateStringShort (date: string | null, excludeTime: boolean = false, excludeSeconds: boolean = false) {
        if (date) {
            return Intl.DateTimeFormat( this.getRegionLocales(), {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: excludeTime ? undefined: '2-digit',
                minute: excludeTime ? undefined: '2-digit',
                second: excludeSeconds ? undefined : '2-digit',
            }).format(new Date(date));
        } else {
            return '';
        }
    }

    static safeStringLocaleCompare = (a: string | undefined , b: string | undefined ) => {
        if (!b && a) {
            return 1;
        } else if (b && !a) {
            return -1;
        } else if (!b && !a) {
            return 0;
        } else {
            return b!.localeCompare(a!);
        }
    };

    static safeDateCompare = (a: Date | undefined | null, b: Date | undefined | null) => {
        if (!a && b) {
            return -1;
        } else if (a && !b) {
            return 1;
        } else if (!a && !b) {
            return 0;
        } else {
            return a! > b! ? 1 : a! < b! ? - 1 : 0;
        }
    };    

    static getAuthToken() {
        return security.invoke((token) => {
            return Promise.resolve(token);
        });
    }

    static getQuilModules() {
        return {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
            ]
        };
    }

    static isExcelByExtension(name: string) {
        return name.toLowerCase().endsWith('xlsx') || name.toLowerCase().endsWith('xls') 
        || name.toLowerCase().endsWith('xltm') || name.toLowerCase().endsWith('xlsm');
    }

    static generateDocumentIconClass(documentName: string) {
        let colorClass: string = 'default';

        if (!documentName) {
            return colorClass;
        }

        const docNameLower = documentName.toLowerCase();

        if (docNameLower.endsWith('.pdf')) {
            colorClass = 'doc-pdf';
        }

        if (Utils.isExcelByExtension(docNameLower)) {
            colorClass = 'doc-xls';
        }

        if (docNameLower.endsWith('.doc') || docNameLower.endsWith('.docx')) {
            colorClass = 'doc-word';
        }

        if (docNameLower.endsWith('.xml')) {
            colorClass = 'doc-xml';
        }


        return colorClass;
    }

    static generateDocumentIcon(documentName: string) {
        const colorClass = Utils.generateDocumentIconClass(documentName);
        return `roche-icon md ${colorClass}`;
    }

    static readableFileSize(bytes: number, si: boolean) {
        var thresh = si ? 1000 : 1024;
        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1) + ' ' + units[u];
    }

    static capitalizeFirstLetter(text?: string) {
        if (!text) {
            return null;
        }

        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    static isValidHttpUrl(text?: string) {
        if (!text) {
            return false;
        }

        let url;

        try {
            url = new URL(text);
        } catch (_) {
            return false;
        }

        return url.protocol === 'http:' || url.protocol === 'https:';
    }

    static isNumber(value?: string) {
        if (!value) {
            return false;
        }

        return !isNaN(Number(value));
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static removeNonBreakingSpaces(value: any) {
        if (typeof value !== 'string') {
            return value;
        }

        return value.replace(/&nbsp;/g, ' ');
    }

    private static getRegionLocales() {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return timeZone.startsWith('America') ? 'en-Us' : 'en-Gb';
    }
}