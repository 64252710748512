import { ImagePreviewSize, ImagePreviewWithSize } from '../types/Document';
import appClient from './AppClient';
export default class ImageService {
    getImagePaths(packageId: string, indexDate: string, fromPage?: number, toPage?: number ) {
        const url = process.env.REACT_APP_MANAGE_URL + `paths/${packageId}/?fromPage=${fromPage}&toPage=${toPage}&indexDate=${indexDate}`;        
        return appClient.get<string>(url);  
    } 

    getImageUrlFromPath(path: string, imageSize: ImagePreviewSize, indexDate: string): string {
        const url = process.env.REACT_APP_MANAGE_URL + `preview/?path=${path}&imageSize=${imageSize}&indexDate=${indexDate}`;
        return url;
    }

    getImagePathsWithSizes(packageId: string, imageSize: ImagePreviewSize, indexDate: string, fromPage?: number, toPage?: number) {
        // eslint-disable-next-line max-len
        const url = process.env.REACT_APP_MANAGE_URL + `paths/${packageId}/sizes?fromPage=${fromPage}&toPage=${toPage}&indexDate=${indexDate}&imageSize=${imageSize}`;        
        return appClient.get<ImagePreviewWithSize[]>(url);
    }

    async getImage(fileId: string) {
        const url = `${process.env.REACT_APP_SERVICE_URL}images/${fileId}`;
        const resp = await appClient.get<Blob | undefined>(url, 'blob');
        return resp.unwrapOr(undefined);
    }
}