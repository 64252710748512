import appClient from '../../common/services/AppClient';
import { MethodSheetSummaryData } from '../models/types';

class MethodSheetReportService {
    async getMethodSheetSummary(projectId: string) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/documents/method_sheet_summary`;
        const resp = await appClient.get<MethodSheetSummaryData>(url);  
        return resp.unwrapOr({} as MethodSheetSummaryData);
    }
}

export default MethodSheetReportService;