import { observer } from 'mobx-react-lite';
import * as React from 'react';
import SummaryReportView from '../components/SummaryReportView';
import { useProjectsStore } from '../../projects/stores/context';
import { useParams } from 'react-router';
import { useStore } from '../../common/stores/context';
import { TabType } from '../../common/stores/TabsStore';
import { Spin } from 'antd';
import { WrappedMatomoTracker } from '../../common/components';


const SummaryReportPage: React.FC = () => {
    const { projectId } = useParams();
    const { projectsStore, commentsStore } = useProjectsStore();
    const { tabsStore, userProfileStore } = useStore();
    const tabId = `${projectId}/summary-report`;

    React.useEffect(() => {
        if (projectId) {
            init();
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const init = async () => {
        if (tabsStore.summaryReportStores[tabId]) {
            tabsStore.setSelectedTab(tabId);
            return;
        }
        tabsStore.addSummaryReportStore(tabId, projectsStore, commentsStore, userProfileStore);
        tabsStore.summaryReportStores[tabId].setProjectId(projectId!);
        if (!projectsStore.projects.length) {
            await projectsStore.getProjects(undefined, false, true);
        }
        const project = projectsStore.projects.find(p => p.id === projectId);
        tabsStore.addTab(tabId, 'Protocol Summary Report', TabType.Report, project?.name);
        tabsStore.summaryReportStores[tabId].isLoading = true;
        tabsStore.summaryReportStores[tabId].getInitialData(); 
    }; 

    const loadingLayout = (
        <div data-testid='projects-layout-loader' style={{textAlign: 'center'}}>
            <Spin />
        </div>
    );

    const reportStore = tabsStore.summaryReportStores[tabId];

    return (reportStore && !reportStore.isLoading 
        ? (
            <>
                <WrappedMatomoTracker documentTitle={`Summary Report - ${reportStore.currentProject?.name}`} />
                <SummaryReportView commentsStore={commentsStore} store={reportStore}/>
            </>
        ) 
        : loadingLayout
    );
};

export default observer(SummaryReportPage);