import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import security from  './modules/common/services/SecurityService';
import UserService from './modules/common/services/UserInfoService';
import { AuthProvider } from './modules/authorization/AuthContext';
import NoRolesError from './modules/common/types/CustomErrors';

security.init().then(() => UserService.getUsersInfo().then((userInfo) =>  {
    if (!userInfo!.permissions || !userInfo.permissions.length) {
        const noRolesError = new NoRolesError();
        ReactDOM.render(
            noRolesError.errorScreen,
            document.getElementById('root') as HTMLElement
        );
    } else {
        ReactDOM.render(
            <React.StrictMode>
                <AuthProvider value={{permissions: userInfo!.permissions, objectPermissions: userInfo!.objectPermissions}}>
                    <App />
                </AuthProvider>
            </React.StrictMode>,
            document.getElementById('root') as HTMLElement
        );
    }
})).catch(function(err: object) {
    if (err instanceof NoRolesError) {
        ReactDOM.render(
            (err as NoRolesError).errorScreen,
            document.getElementById('root') as HTMLElement
        );
    } else {
        console.log(err);
        alert('failed to initialize');
    }
});