import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useParams } from 'react-router';
import { ExcelDocumentPreview, PdfDocumentPreview } from '../components';
import { WrappedMatomoTracker } from '../../common/components';

const ProjectDocumentViewer: React.FC = () => {
    let { projectId, documentId, documentName, docType } = useParams();

    if (!documentId || !documentName) {
        return null;
    }

    return (
        <>
            <WrappedMatomoTracker documentTitle={`Document Viewer - ${documentName}`} />
            {
                documentName?.endsWith('pdf') && documentId 
                    ? <PdfDocumentPreview projectId={projectId} documentId={documentId} docType={docType!} />
                    : <ExcelDocumentPreview documentId={documentId} documentName={documentName} />
            }
        </>
    );
};

export default observer(ProjectDocumentViewer);