import { observer } from 'mobx-react-lite';
import * as React from 'react';
import SummaryReportStore from '../stores/SummaryReportStore';
import PdfPreview from './PdfPreview';
import ExcelPreview from './ExcelPreview';
import { Tooltip } from 'antd';
import { SelectOutlined } from '@ant-design/icons';

type Props = {
    store: SummaryReportStore
};

const DocumentPreview: React.FC<Props> = ({store}) => {
    const [detachedWindow, setDetachedWindow] = React.useState<boolean>(false);
    const isInitialMount = React.useRef(true);

    React.useEffect(() => {
        if (!detachedWindow) {
            const blockId = store.projectId + '-report-document-highlight-block';
            const block = document.getElementById(blockId);
            block?.scrollIntoView({behavior: 'smooth', block:'center'});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detachedWindow]);

    React.useEffect(() => {
        if (store.highlightedBlock  && store.isPreviewCollapsed && !detachedWindow && !isInitialMount.current) {
            store.setIsPreviewCollapsed(false);
        }
        isInitialMount.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.highlightedBlock]);

    if (!store.selectedDocument) {
        return null;
    }

    const isPdf = () => {
        return store.selectedDocument?.documentName.endsWith('.pdf');
    };

    const detachWindow = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        if (store.projectId && store.selectedDocument) {
            setDetachedWindow(true);
            store.setIsPreviewCollapsed(true);
            const url =`/preview/${store.projectId}/${store.selectedDocument.documentId}/${store.selectedDocument.type}`;
            const newWindow = window.open(url, '_blank', 'location=yes,height=700,width=1100,scrollbars=yes,status=yes');
            if (newWindow) {
                // Interval is needed due to redirections happening in a new window and onbeforeunload is triggered immediately.
                const timer = setInterval(function() {
                    if(newWindow.closed) {
                        store.setIsPreviewCollapsed(false);
                        setDetachedWindow(false);
                        clearInterval(timer);
                    }
                }, 500);
            }
        }
    };

    const renderDetach = () => {
        return (
            <Tooltip title="Pop out preview">
                <SelectOutlined style={{marginLeft: 12}} onClick={detachWindow} />
            </Tooltip>
        );
    };

    return (
        <>
            <div className={`document-preview-placeholder ${store.isPreviewCollapsed ? 'collapsed' : ''} ${detachedWindow ? 'detached' : ''}` } />
            <div className={`document-preview-wrapper ${store.isPreviewCollapsed ? 'collapsed' : ''} ${detachedWindow ? 'detached' : ''}`}>
                <div className="document-preview-header" onClick={() => store.setIsPreviewCollapsed(!store.isPreviewCollapsed)}> 
                    <i className="roche-icon collapse-arrow sm" style={{transform : store.isPreviewCollapsed ? 'rotate(-180deg)' : undefined }}/>   
                    <span className="panel-header-title">{store.selectedDocument.documentName}</span>
                    {renderDetach()}
                </div>
                <div className="document-preview-content">
                    {isPdf() ? <PdfPreview store={store} /> : <ExcelPreview store={store} />}
                </div>
            </div>
        </>
    );
};

export default observer(DocumentPreview);