import { action, makeObservable, observable } from 'mobx';
import PreviewsVisualStore from '../../application_report/stores/PreviewVisualStore';
import { AlphaPackage } from '../types/AlphaPackage';

class AlphaPackagesStore {
    packages: AlphaPackage[] = [];

    autocompleteSearchKey: string;

    packageSearchKey: string = '';

    selectedPackageId: string = '';

    packageSorting: string = 'Name';

    packageSortingDirection: string = 'Asc';

    stickedItemsPackageIds: string[] = [];

    isLoading: boolean = false;

    constructor(public previewVisualStore: PreviewsVisualStore) {
        makeObservable(this, {
            autocompleteSearchKey: observable,
            packageSearchKey: observable,
            packageSorting: observable,
            packageSortingDirection: observable,
            stickedItemsPackageIds: observable,
            selectedPackageId: observable,
            isLoading: observable,
            setSelectedPackageId: action.bound,
            setAutocompleteSearchKey: action.bound,
            togglePackageSorting: action.bound,
        });
    }

    get packagesForAutocomplete() {
        if (this.packages) {
            // const allPackages = this.packages.concat(this.newPackages);
            let listDistinct = [...new Set(this.packages.map(p => p.fileName))];

            if (this.autocompleteSearchKey && this.autocompleteSearchKey.trim() !== '') {
                listDistinct = listDistinct.filter(p => p.toLowerCase().includes(this.autocompleteSearchKey.toLowerCase()));
            }
            return listDistinct.slice(0, 10);
        }

        return [];
    }
    
    get selectedPackageName() {
        if (this.selectedPackageId) {
            return this.packages.find(p => p.id === this.selectedPackageId)!.fileName;
        } else {
            return '';
        }
    }

    get filteredPackages() {
        let packages: AlphaPackage[] = this.packages.slice();
        // let newFilteredPackages: AlphaPackage[] = this.newPackages.slice();
        let newFilteredPackages: AlphaPackage[] = [];
        if (this.packages.length > 0) {
            if (this.packageSearchKey && this.packageSearchKey.trim() !== '') {
                packages = packages.filter(p => this.packageFilter(p));
                newFilteredPackages = newFilteredPackages.filter(p => this.packageFilter(p));
            }

            if (this.packageSorting === 'Name') {
                packages = packages.sort((p1, p2) => this.comparePackageNames(p1, p2, this.packageSortingDirection === 'Asc'));
            } else {
                packages = packages.sort((p1, p2) => this.comparePackageDates(p1, p2, this.packageSortingDirection === 'Asc'));
            }

            newFilteredPackages.forEach(p => packages.unshift(p));
        }
        return packages.filter(x => !this.stickedItemsPackageIds.includes(x.id));
    }

    setPackages(packages: AlphaPackage[]) {
        this.previewVisualStore.setPackages(packages);
        this.packages = packages;
    }

    
    resetNewContractDialogState() {
        this.clearPackageSelection();
        this.removeStickyPackageItems();
        this.setAutocompleteSearchKey('');
        this.setPackageSearchKey('');
    }

    setAutocompleteSearchKey(key: string) {
        this.autocompleteSearchKey = key;
    }

    setPackageSearchKey(key: string) {
        this.packageSearchKey = key;
    }   

    removeStickyPackageItems() {
        this.stickedItemsPackageIds = [];
    }
    
    clearPackageSelection() {
        this.selectedPackageId = '';
    }

    togglePackageSorting() {
        if (this.packageSortingDirection === 'Asc') {
            this.packageSortingDirection = 'Desc';
        } else {
            this.packageSorting = this.packageSorting === 'Name' ? 'Date' : 'Name';
            this.packageSortingDirection = 'Asc';
        }
    }

    getSelectedPackageName(): string {
        return this.packages!.find(p => p.id === this.selectedPackageId)!.fileName;
    }

    setSelectedPackageId(id: string) {
        if (this.selectedPackageId === id) {
            this.selectedPackageId = '';
        } else {
            this.selectedPackageId = id;
        }
        
    }

    removeStickedPackageItem(id?: string) {
        if (!id) {
            this.stickedItemsPackageIds = [];
        } else {
            const index = this.stickedItemsPackageIds.indexOf(id);
            this.stickedItemsPackageIds.splice(index, 1);
        }
    
    }

    setStickedPackageItem(id: string) {
        this.stickedItemsPackageIds.push(id);
    }

    private packageFilter(p: unknown) {
        let pkg = p as AlphaPackage;
        let includeByFileName: boolean = pkg.fileName.toLowerCase().includes(this.packageSearchKey.trim().toLowerCase());
        let includeByTags: boolean = false;
        if (pkg.userTags && pkg.userTags.length > 0) {
            const foundTag = pkg.userTags.find(t => t.trim().toLowerCase().includes(this.packageSearchKey.trim().toLowerCase()));
            includeByTags = !!foundTag;
        }

        return includeByFileName || includeByTags;
    }

    private comparePackageNames(p1: AlphaPackage, p2: AlphaPackage, isAsc: boolean) {
        if (p1.fileName.toLowerCase() > p2.fileName.toLowerCase()) {
            return isAsc ? 1 : -1;
        }

        if (p1.fileName.toLowerCase() < p2.fileName.toLowerCase()) {
            return isAsc ? -1 : 1;
        }

        return 0;
    }

    private comparePackageDates(p1: AlphaPackage, p2: AlphaPackage, isAsc: boolean) {
        const d1 = p1.uploadedTime ? new Date(p1.uploadedTime) : null;
        const d2 = p2.uploadedTime ? new Date(p2.uploadedTime) : null;

        if ((!d2 && d1) || d1! > d2!) {
            return isAsc ? 1 : -1;
        }

        if ((!d1 && d2) || d1! < d2!) {
            return isAsc ? -1 : 1;
        }

        return 0;
    }
}

export default AlphaPackagesStore;