import Keycloak, { KeycloakInstance, KeycloakPromise } from 'keycloak-js';
// import NoRolesError from '../types/CustomErrors';

const keyConfig = require('./keycloak.json');
// const Keycloak = require('keycloak-js');
// const APPLICATION_ID = 'alpha-portal';

class SecurityService {
    inst: KeycloakInstance;
    cachedUserInfo: KeycloakPromise<{}, void> | undefined;

    constructor() {
        // TODO: Change keylcoak.json when new client for roche app is made
        keyConfig.url = process.env.REACT_APP_AUTH_URL;
        console.log(keyConfig);
        this.inst = Keycloak(keyConfig);
    }

    get token() {
        return this.inst.token!;
    }

    get preferredUsername() {
        return this.inst.tokenParsed?.preferred_username;
    }

    init() {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let cfg: any = { onLoad: 'login-required', checkLoginIframe: false };                          

        return new Promise((res, rej) => {
            const promise = this.inst.init(cfg);
            promise.then((auth) => {
                res(auth); 
            }).catch((err) => rej(err));
        });      
    }

    loadUserInfo() {
        if (!this.cachedUserInfo) {
            this.cachedUserInfo = this.inst.loadUserInfo();
        }

        return this.cachedUserInfo;
    }

    getOrRefreshToken(): Promise<{ token: string; refreshed: boolean }> {
        if (!this.inst.isTokenExpired) {
            return Promise.resolve({ token: this.inst.token!, refreshed: false });
        }

        return new Promise<{ token: string; refreshed: boolean }>((res, rej) => {
            this.inst.updateToken(30).then((refreshed) => {
                if (refreshed) {
                    console.log('Token was updated');
                }

                res({ token: this.inst.token!, refreshed: true });
            }).catch(err => {
                console.error('Error during token refresh');
                rej(err);
            });
        });
    }

    invoke<T>(func: (token: string) => Promise<T>): Promise<T> {
        return new Promise<T>((res, rej) => {
            if (!this.inst.isTokenExpired(30)) {
                func(this.inst.token!).then(s => res(s)).catch(err => rej(err));
                return;
            }

            console.log('Token is expired');
            this.inst.updateToken(30).then((refreshed) => {
                if (refreshed) {
                    console.log('Token was updated');
                }

                func(this.inst.token!).then(s => res(s)).catch(err => rej(err));
            }).catch(err => {
                console.error('Error during token refresh');
                rej(err);
            });
        });   
    }
}

const service = new SecurityService();

export default service;