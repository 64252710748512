export enum ReportStatus {
    Draft = 'Draft',
    InReview = 'InReview',
    Approved = 'Approved',
    NotAvailable = 'NotAvailable'
}

export enum SpecificationTypes {
    Protocol = 'protocol-document',
    Amendments = 'amendments'
}