import { Button, Col, Dropdown, Menu, message, Row, Switch } from 'antd';
import Search from 'antd/lib/input/Search';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { OnHitEventArgs } from 'react-drag-drop-container';
import DraggableItemWrapper from '../../common/components/DraggableItemWrapper';
import { ProjectsStore } from '../stores';

type Props = {
    store: ProjectsStore;
    setCreateDialogVisible: (visible: boolean) => void
};

const ProjectListHeaderControls: React.FC<Props> = ({store, setCreateDialogVisible}) => {
    let dragIndex = 0;
    
    const handleSearch = (searchTerm: string) => {
        store.getProjects(searchTerm, store.isArchivedViewEnabled);
    };

    const handleOnDrop = (index: number) => {
        dragIndex = index;
    };

    const handleOnHit = (args: OnHitEventArgs, index: number) => {
        store.handleColumnsReorder(dragIndex, index);
    };

    const setLayoutDefaultView = () => {
        store.setLayoutDefaultView().then(() => {
            message.success('Layout has been successfully reset.');
        }).catch((err) => {
            console.error(err);
            message.error('Failed to save layout.');
        });
    };

    const columnsMenu = (
        <Menu className="projects-columns-menu" data-id-type="popup-menu-list-root" data-id-name="columns">
            {store.projectColumns.map((x, i)=>  (
                <Menu.Item style={{padding: 15, paddingBottom: 11, paddingTop: 11}} key={x.name} data-id-type={x.name}>
                    <DraggableItemWrapper key={x.name} index={i} handleOnDrop={handleOnDrop} handleOnHit={handleOnHit}>
                        <div>
                            <div className='draggable-item'>
                                <i style={{verticalAlign: 'middle', height: 13}} className='roche-icon sm drag-handle' />
                                <span>{x.name}</span> 
                            </div>
                            <Switch onClick={(e, ev) => ev.stopPropagation()} checked={x.isVisible} onChange={(e) => store.handleVisibiltyOnChange(x.name, e)}/>
                        </div>
                    </DraggableItemWrapper>
                </Menu.Item>))}
        </Menu>   
    );  

    const actionsMenu = (
        <Menu>
            <Menu.Item data-testid="archived-projects" key="archived" onClick={() => store.getProjects(undefined, !store.isArchivedViewEnabled)}>
                <span style={{width: 160, display: 'inline-block'}}>{store.isArchivedViewEnabled ?  'Active projects' : 'Archived projects'}</span> 
            </Menu.Item>
            <Menu.Item data-testid="default-view" key="defaultView" onClick={setLayoutDefaultView}>
                <span style={{width: 160, display: 'inline-block'}}>Default view</span> 
            </Menu.Item>
        </Menu>
    );

    return (
        <Row style={{alignItems: 'center', marginBottom: 30}}>
            <Col>
                <h1 style={{fontSize: 34, marginBottom: 0}}>{store.isArchivedViewEnabled ? 'Archived Projects' : 'Projects'}</h1>
            </Col>
            <Col style={{marginLeft: 'auto'}}>
                <div className='table-container-header page-header'>
                    <Dropdown
                        trigger={['click']}
                        overlay={columnsMenu}
                        overlayClassName="alpha-dropdown-overlay"
                    >
                        <Button data-id="button-columns" className="dropdown-btn columns-btn" style={{border: 'none', marginRight: 10, fontSize: 14}} type="ghost">
                            <i className="roche-icon md columns"/>
                            <span>Columns</span>
                        </Button>
                    </Dropdown>
                    <Search 
                        data-testid="project-search" 
                        data-id="search area"
                        placeholder="Search" 
                        onSearch={handleSearch}
                        enterButton
                        maxLength={150}
                        allowClear
                        className="roche-search-input no-search-btn ghost"
                        prefix={<i className="roche-icon sm table-search" style={{marginRight: 10}}/>} 
                    />
                    <Dropdown
                        trigger={['click']}
                        overlay={actionsMenu}
                        overlayClassName="alpha-dropdown-overlay"
                    >
                        <Button data-testid="projects-more-actions" data-id="button-three-dots" className="dropdown-btn" style={{border: 'none', marginRight: 10}} type="ghost">
                            <i data-testid="spec-actions" className="roche-icon md action-more-dots" style={{height: 8}}/>
                        </Button>
                    </Dropdown>
                    <Button size="large" className='large' type='primary' onClick={() => setCreateDialogVisible(true)}>
                        New project
                    </Button>
                </div>
            </Col>
        </Row>
    );
};

export default observer(ProjectListHeaderControls);