import { message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { makeObservable, action, observable, computed, runInAction } from 'mobx';
import { ApplicationReportsTableModel } from '../components/ApplicationReport';
import { PackageState } from '../../common/types/PackageState';
import { DocumentStateChange } from '../../common/types/DocumentStateChange';
import SpecficationType from '../../common/types/SpecificationType';
import { LotType } from '../../summary_report/types/TopicLotResult';
import { ProjectsService } from '../../projects/services';
import ApplicationReportsService from '../../projects/services/ApplicationReportsService';
import { ReportStatus } from '../../projects/types/ApplicationReport';
import ProjectsStore from '../../projects/stores/ProjectsStore';
import { AlphaPackage } from '../../common/types/AlphaPackage';
import { DocumentTopicsResult, SpecificationTypeTopicsStatistics, TopicLotData } from '../../projects/types/DocumentTopicsResult';
import { ResultApi } from '../../common/services/AppClient';
import { ReportDocument } from '../../projects/types/Document';
import CommentStore from '../../common/stores/CommentsStore';
import { CommentData } from '../../projects/types/CommentData';
import { UserProfileStore } from '../../common/stores';
import AlphaPackagesStore from '../../common/stores/AlphaPackagesStore';
import ErrorStore from '../../common/stores/ErrorStore';
import MethodSheetReportStore from '../../method_sheet_report/stores/MethodSheerReportStore';
import _, { groupBy } from 'lodash';
import ResultHelpers from '../../common/misc/ResultHelpers';
import { HiddenReportPartModel } from '../../projects/types';

type TableData = {
    [key: string]: ApplicationReportsTableModel[]
};

type Statistics = {
    [key: string]: SpecificationTypeTopicsStatistics
};


class ApplicationReportsStore {

    projectId: string;
    
    fileToUpload: RcFile;

    tableData: TableData = {};

    documents: TopicLotData[] = [];

    specificationTypes: SpecficationType[];

    currentSpecTypeId: string;

    isLoading: boolean = true;

    currentLotData: { section: string; topic?: string; lot?: LotType};

    statistics: Statistics = {};

    currentCommentsTopic: string;

    showFileProcessSuccessMsg: () => void;

    docToRemove: string;

    deleteConfirmMessage: string;

    scrollPosition: number = 0;

    constructor(private projectsStore: ProjectsStore, private service: ApplicationReportsService, private projectService: ProjectsService, private commentsStore: CommentStore,
                private userProfileStore: UserProfileStore, public alphaPackagesStore: AlphaPackagesStore, 
                private errorStore: ErrorStore,  private methodSheetReportStore: MethodSheetReportStore) {

        makeObservable(this, {
            getInitialData: action.bound,
            setCurrentSpecType: action.bound,
            deleteDocument: action.bound,
            addUploadedDoc: action.bound,
            createComment: action.bound,
            addExistingDocument: action.bound,
            setProjectId: action.bound,
            setProjectHiddenReportParts: action.bound,
            currentSpecTypeId: observable,
            isLoading: observable,
            tableData: observable,
            statistics: observable,
            projectId: observable,
            tableDataSource: computed,
            statisticsData: computed,
            groupedTableDataSource: computed,
            currentProject: computed,
            currentProjectResultRange: computed,
            currentSpecTypeHiddenParts: computed,
        });

        this.projectsStore.verificationDoc.subscribe(this.addUploadedDoc.bind(this));
        this.showFileProcessSuccessMsg =  _.throttle(() => message.success('File has been successfully processed.'), 500);
    }

    get tableDataSource() {
        return this.tableData[this.currentSpecTypeId];
    }

    get groupedTableDataSource() {
        return groupBy(this.tableDataSource, (t)=> t.section);
    }

    get statisticsData() {
        return this.statistics[this.currentSpecTypeId];
    }

    get currentProject() {
        return this.projectsStore.projects.find(p=> p.id === this.projectId);
    }

    get currentProjectResultRange() {
        return ResultHelpers.getRange(this.currentProject);
    }

    // get reagentHasUnreadComment() {
    //     return this.hasUnreadComments(this.tableData[SpecificationTypes.Reagent]);
    // }

    // get calibratorsHasUnreadComment() {
    //     return this.hasUnreadComments(this.tableData[SpecificationTypes.Calibrators]);
    // }

    // get preciControlHasUnreadComment() {
    //     return this.hasUnreadComments(this.tableData[SpecificationTypes.PreciControl]);
    // }

    get currentSpecTypeHiddenParts() {
        if (!this.currentProject || !this.currentProject.hiddenReportParts || !this.currentSpecTypeId) {
            return undefined;
        }

        return this.currentProject.hiddenReportParts.find(p=> p.specificationTypeId === this.currentSpecTypeId);
    }

    getLotData(section: string, topic: string, type: LotType, specTypeId: string, comments: CommentData[]) {
        const doc = this.documents.find(l => l.specificationTypeId === specTypeId && l.section == section && l.topic === topic && l.lotType === type);
        const filteredComments = comments.filter(c=> c.specificationTypeId === specTypeId && c.topic === topic && c.lot === type && c.section === section);
        return {
            docId: doc?.documentId || '', 
            docName: doc?.documentName || '', 
            status: doc?.documentStatus || PackageState.NotUploaded,
            errorMessage: doc?.errorMessage || '',
            commentsCount: filteredComments.length,
            unavailable: doc?.documentReportStatus === ReportStatus.NotAvailable,
            notReadCommentsCount: filteredComments.filter(c=> !c.usersRead.includes(this.userProfileStore.userInfo.userId)).length,
            overridingPackageId: doc?.overridingDocumentId,
            overridingPackageName: doc?.overridingDocumentName
        };
        
    }

    getStatisticsBySpec(specId: string) {
        return (`${this.statistics[specId].total}/${this.statistics[specId]?.uploaded}`);
    }

    resetNewContractDialogState() {
        this.alphaPackagesStore.resetNewContractDialogState();
    }


    async getInitialData() {
        try {
            const promises  =  [
                this.projectService.getSpecificationTypes(), 
                this.service.getTopics(this.projectId), 
                // hardcoded for demo purposes
                this.service.getAllPackages(process.env.REACT_APP_ALPHA_PROJECT_ID!),
                this.commentsStore.getComments(this.projectId)
            ];
            const resp = await Promise.all(promises) as [SpecficationType[], DocumentTopicsResult, AlphaPackage[], CommentData[]];
            this.specificationTypes = resp[0];
            this.documents = resp[1].topicLotData;
            const packages = resp[2];
            const comments = resp[3];
            this.alphaPackagesStore.setPackages(packages);
            
            if (resp[1]?.specificationTypeTopicsStatistics) {
                resp[1].specificationTypeTopicsStatistics.forEach(s => this.statistics[s.specificationTypeId] = s);
            }
            
            this.createTableModel(resp[1], comments);
            const specType = this.specificationTypes.find(s=> s.name === 'Protocol')!;
            this.setCurrentSpecType(specType.id);
        } catch (error) {
            this.errorStore.addBasicError(error);
        } finally {
            runInAction(() => this.isLoading = false);
        }
    }

    async addUploadedDoc(newDoc: DocumentStateChange) {
        if (newDoc.projectId !== this.projectId) {
            return;
        }
        const doc =  this.documents.find(d => d.documentId == newDoc.documentId);
        if (!doc) {
            return;
        }

        const completedStatuses = [PackageState.Completed, PackageState.UploadedWithoutAnalysis];
        
        if (completedStatuses.includes(newDoc.status) && newDoc.uploadedBy === this.userProfileStore.userInfo.userName) {
            this.showFileProcessSuccessMsg();
            this.methodSheetReportStore.getMethodSheetSummary();
            await this.updateTableModel();
            const resp = await this.service.getAllPackages(process.env.REACT_APP_ALPHA_PROJECT_ID!);
            this.alphaPackagesStore.setPackages(resp);
        }
        const index = this.tableData[doc.specificationTypeId].findIndex(x => x.topic === doc.topic && x.section === doc.section);
        const tableRecord = this.tableData[doc.specificationTypeId][index];

        const lotData = tableRecord.lots.find(l => l.lotType === doc.lotType)!.lotData;

        lotData.docName = doc.documentName;
        lotData.docId = doc.documentId;
        lotData.status = newDoc.status;
        lotData.errorMessage = newDoc.errorMessage;
        lotData.overridingPackageId = newDoc.overridingPackageId;
        lotData.overridingPackageName = newDoc.overridingPackageName;

        tableRecord.lots = [...tableRecord.lots];
    }   

    setFileToUpload(file: RcFile) {
        this.fileToUpload = file;
    }

    async uploadDocument(reportStatus: ReportStatus, lot: LotType, section: string, topic: string, specTypeId: string, skipAnalysis: boolean = false) {
        const index = this.tableData[specTypeId].findIndex(x => x.topic === topic && x.section === section);
        const tableRecord = this.tableData[specTypeId][index];

        const lotData = tableRecord.lots.find(l => l.lotType === lot)!.lotData;

        lotData.docName = this.fileToUpload.name;
        lotData.status = PackageState.Uploading;
        lotData.docId = '';
        lotData.errorMessage = '';

        tableRecord.lots = [...tableRecord.lots];

        const resp = await this.service.uploadDocument(this.projectId, this.fileToUpload, reportStatus, lot, section, topic, specTypeId, skipAnalysis);
        this.handleUploadResponse(resp, lot, section, topic);
      
    }

    async addExistingDocument(selectedPackageId: string) {
        if (!this.currentLotData.lot || !this.currentLotData.topic) {
            return;
        }

        const resp = await this.service.addExisitingDocument(this.projectId, selectedPackageId, 
            ReportStatus.Approved, this.currentSpecTypeId, this.currentLotData.lot, this.currentLotData.section, this.currentLotData.topic);
        this.handleUploadResponse(resp, this.currentLotData.lot, this.currentLotData.section, this.currentLotData.topic);
        
    }

    setCurrentSpecType(id: string) {
        this.currentSpecTypeId = id;
    }

    setProjectId(id: string) {
        this.projectId = id;
    }

    async deleteDocument() {
        const resp = await this.service.deleteDocument(this.projectId, this.docToRemove);
        if (resp.isOk()) {
            const docIndex =  this.documents.findIndex(d => d.documentId === this.docToRemove)!;
            const doc = this.documents[docIndex];
            if (doc.documentStatus === PackageState.Completed) {
                this.methodSheetReportStore.getMethodSheetSummary();
                this.statistics[doc.specificationTypeId].uploaded--;
                this.statistics[doc.specificationTypeId].toBeAdded++;
            }
            this.documents.splice(docIndex, 1);
            const index = this.tableData[doc.specificationTypeId].findIndex(x => x.topic === doc.topic && x.section === doc.section);
            const tableRecord = this.tableData[doc.specificationTypeId][index];

            const lotData = tableRecord.lots.find(l => l.lotType === doc.lotType)!.lotData;

            lotData.docName = '';
            lotData.docId = '';
            lotData.status = PackageState.NotUploaded;
            lotData.errorMessage = '';

            tableRecord.lots = [...tableRecord.lots];
        }
    }
        
    downloadDocument(docId: string) {
        this.service.downloadDocument(this.projectId, docId);
    }

    async setLotNotAvailable(comment: string, status: ReportStatus) {
        if (!this.currentLotData.lot || !this.currentLotData.topic) {
            return;
        }

        const doc = this.documents.find(d=> d.section === this.currentLotData.section && d.topic === this.currentLotData.topic && 
            d.lotType === this.currentLotData.lot && d.specificationTypeId === this.currentSpecTypeId);
        const resp = await this.service.setLotStatus(this.projectId, this.currentSpecTypeId, this.currentLotData.section, 
            this.currentLotData.topic, this.currentLotData.lot, status, comment, doc?.documentId);
        if (resp.isOk()) {
            this.methodSheetReportStore.getMethodSheetSummary();
            this.updateTableModel();
            const commentData = resp.unwrapOr(null);
            if (commentData !== null) {
                this.commentsStore.addComment(commentData);
            }
        }
    }

    async setSectionStatus(comment: string, status: ReportStatus) {
        if (!comment || !status) {
            return;
        }

        const resp = await this.service.setSectionStatus(this.projectId, this.currentSpecTypeId, this.currentLotData.section, status, comment);
        if (resp.isOk()) {
            this.methodSheetReportStore.getMethodSheetSummary();
            this.updateTableModel();
            const commentData = resp.unwrapOr(null);
            if (commentData !== null) {
                commentData.forEach(c=> this.commentsStore.addComment(c));
            }
        }
    }

    async setTopicStatus(comment: string, status: ReportStatus) {
        if (!comment || !status || !this.currentLotData.topic) {
            return;
        }

        const resp = await this.service.setTopicStatus(this.projectId, this.currentSpecTypeId, this.currentLotData.section, this.currentLotData.topic, status, comment);
        if (resp.isOk()) {
            this.methodSheetReportStore.getMethodSheetSummary();
            this.updateTableModel();
            const commentData = resp.unwrapOr(null);
            if (commentData !== null) {
                commentData.forEach(c=> this.commentsStore.addComment(c));
            }
        }
    }

    setCurrentLotData(section: string, topic?: string, lot?: LotType) {
        this.currentLotData = {section, topic, lot};
    }

    setCurrentCommentTopic(topic: string) {
        this.currentCommentsTopic = topic;
    }

    setDocToRemove(docId: string) {
        this.docToRemove = docId;
    }

    getDocById(docId: string) {
        return this.documents.find(d=> d.documentId === docId)!;
    }

    setScrollPosition(position: number) {
        this.scrollPosition = position;
    }

    async createComment() {
        const resp = await this.commentsStore.createComment(this.projectId, this.currentSpecTypeId);
        if (resp.isOk()) {
            const comment = resp.unwrapOr(null);
            if (comment !== null) {
                this.commentsStore.addComment(comment);
            }
            const record = this.tableData[this.currentSpecTypeId].find(t=> t.topic === this.commentsStore.topic)!;

            const lotData = record.lots.find(l => l.lotType === this.commentsStore.lot)!.lotData;
            lotData.commentsCount += 1;

            record.lots = [...record.lots];
        }
    }

    async markReadComments(currentUserId: string) {
        const ids = this.commentsStore.commentsForCurrentCell.filter(c=> !c.usersRead.includes(currentUserId)).map(c=> c.id);
        if (ids.length) {
            const resp = await this.commentsStore.markReadComments(ids);
            if (resp.isOk()) {
                this.updateTableModel();
            }
        }
    }

    updateTable(){
        this.updateTableModel();
    }

    async setProjectHiddenReportParts(projectId: string, hiddenReportParts: HiddenReportPartModel) {
        return await this.projectsStore.setProjectHiddenReportParts(projectId, hiddenReportParts);
    }
        
    private handleUploadResponse(resp: ResultApi<ReportDocument>, lot: LotType, section: string, topic: string) {
        if (resp.isOk()) {
            const doc = resp.unwrapOr(null);
            if (doc) {
                const existingDoc = this.documents.find(d => d.documentId === doc.id);

                if (!existingDoc) {
                    this.documents.push({
                        documentId: doc.id, 
                        documentStatus: doc.status,
                        documentName: doc.name, 
                        documentReportStatus: doc.reportStatus,
                        specificationTypeId: doc.specificationTypeId,
                        lotType: lot,
                        section: section,
                        topic,
                        errorMessage: doc.errorMessage,
                        overridingDocumentId: doc.overridingDocumentId,
                        overridingDocumentName: doc.overridingDocumentName
                    });
                }
                
                this.addUploadedDoc({documentId: doc.id, ...doc});
            }
        } else {
            message.error(resp.error?.data?.title ?? 'Failed to upload file.');
            console.error(resp.error);
        }
    }

    private async updateTableModel() {
        const promises  =  [
            this.service.getTopics(this.projectId), 
            this.commentsStore.getComments(this.projectId)
        ];
        const resp = await Promise.all(promises) as [DocumentTopicsResult, CommentData[]];
        this.documents = resp[0].topicLotData;
        this.createTableModel(resp[0], resp[1]);
    }

    private createTableModel(topics: DocumentTopicsResult, comments: CommentData[]) {
        if (topics.specificationTypeTopicsStatistics) {
            topics.specificationTypeTopicsStatistics.forEach(s => this.statistics[s.specificationTypeId] = s);
        }
        topics.topics.forEach(r=> 
            this.tableData[r.specificationTypeId] = r.topics.map(t=> 
                ({  
                    section: t.section,
                    topic: t.topic,
                    lots: this.currentProjectResultRange.map(resultNumber => {
                        const lotType = ResultHelpers.createLotType(resultNumber);
                        return {
                            lotType,
                            lotData: this.getLotData(t.section, t.topic, lotType, r.specificationTypeId, comments)
                        };
                    })
                })
            )
        );
    }
    
    // private hasUnreadComments(data:  ApplicationReportsTableModel[]) {
    //     return data.some(t => t.lots.some(l => l.lotData.notReadCommentsCount > 0));
    // }
}

export default ApplicationReportsStore;