/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Form, FormInstance } from 'antd';
import { observer } from 'mobx-react-lite';
import ReactQuill, { Quill } from 'react-quill';
import BlotFormatter from 'quill-blot-formatter';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import SummaryReportStore from '../../stores/SummaryReportStore';
import { HTMLRenderer } from '../../../common/components';
import './ValueEditor.less';

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
Quill.register('modules/blotFormatter', BlotFormatter);

interface Props {
    form: FormInstance;
    store: SummaryReportStore;
    isDataChangeModalVisible: boolean
}

const ValueEditor: React.FC<Props> = ({ form, store, isDataChangeModalVisible }) => {
    const quillRef = React.useRef<ReactQuill>(null);

    const handleOnChange = (value: string) => {
        if (!quillRef.current) {
            return;
        }

        if (value === '<p><br></p>') {
            form.setFieldsValue({ newVal: '' });
            return;
        }

        let imageRemoved = false;

        const fragment = document.createRange().createContextualFragment(value);
        const paragraph = document.createElement('p');

        fragment.querySelectorAll('*').forEach(element => {
            if (element instanceof HTMLParagraphElement) {
                const images = element.getElementsByTagName('img');
                const firstImage = images[0];

                if (firstImage && element.innerText.length) {
                    // Prevent image and text on one line
                    paragraph.appendChild(firstImage.cloneNode());

                    element.removeChild(firstImage);

                    imageRemoved = true;
                } else if (images.length > 1) {
                    // Prevent two images on one line
                    const secondImage = images[1];

                    paragraph.appendChild(secondImage.cloneNode());

                    element.removeChild(secondImage);

                    imageRemoved = true;
                }
            }
        });

        // Insert removed image on a new line
        if (imageRemoved) {
            const div = document.createElement('div');

            div.appendChild(fragment);

            form.setFieldsValue({ newVal: div.innerHTML + paragraph.innerHTML });
        }
    };

    const imageHandler = (dataUrl: string) => {
        if (!quillRef.current) {
            return;
        }

        const editor = quillRef.current.getEditor();
        const selection = editor.getSelection();
        const index = selection ? selection.index : editor.getLength();

        editor.clipboard.dangerouslyPasteHTML(index, `<img src="${dataUrl}" width="200" />`);
    };

    const modules = React.useMemo(() => {
        return {
            toolbar: false,
            imageDropAndPaste: { handler: imageHandler },
            blotFormatter: {},
            clipboard: {
                matchVisual: false,
                matchers: [
                    [
                        Node.ELEMENT_NODE,
                        (_: any, delta: any) => {
                            // Copy of image - do nothing
                            if (delta.ops.some((op: any) => op.insert && op.insert.image)) {
                                return delta;
                            }

                            const ops = [] as any;

                            // Workaround to remove styles (colors, etc.) from copied text
                            delta.ops.forEach((op: any) => {
                                if (op.insert && typeof op.insert === 'string') {
                                    ops.push({
                                        insert: op.insert
                                    });
                                }
                            });

                            delta.ops = ops;

                            return delta;
                        }
                    ]
                ]
            }
        };
    }, []);

    return (
        <div className="value-editor-container">
            <Form.Item
                name="newVal"
                colon={false}
                initialValue={isDataChangeModalVisible ? store.editedDataModalNewValue : ''}
                label={isDataChangeModalVisible ? 'Current value' : 'New value'}
                rules={[{ required: true, message: 'Please input new value!', whitespace: true }]}
            >
                {isDataChangeModalVisible ? (
                    <HTMLRenderer html={store.editedDataModalNewValue ?? ''} />
                ) : (
                    <ReactQuill className="react-quill" ref={quillRef} modules={modules} onChange={handleOnChange} />
                )}
            </Form.Item>
        </div>
    );
};

export default observer(ValueEditor);
