import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useParams } from 'react-router';
import { useProjectsStore } from '../../projects/stores/context';
import PdfPreview from '../components/PdfPreview';
import { WrappedMatomoTracker } from '../../common/components';

const PreviewPage: React.FC = () => {
    const { projectId, documentId, documentName, documentType } = useParams();
    const { summaryReportStore } = useProjectsStore();
    const parentStorageKey = `parent-${projectId}`;
    const parentDocStorageKey = `parent-${projectId}-document`;
    const childStorageKey = `child-${projectId}`;

    const highlightInputFromStorage = React.useCallback(() => {
        const blockInfoJson = localStorage.getItem(parentStorageKey);

        if (!blockInfoJson) {
            return;
        }

        const blockInfo = JSON.parse(blockInfoJson);
        if (blockInfo) {
            summaryReportStore.highlightBlock(blockInfo.record, blockInfo.blockId, blockInfo.lotType);
        }

        const docInfoJson = localStorage.getItem(parentDocStorageKey);

        if (!docInfoJson) {
            return;
        }

        const docInfo = JSON.parse(docInfoJson);

        if (docInfo.documentId != summaryReportStore.selectedDocument?.documentId) {
            summaryReportStore.setSelectedDocument({
                ...docInfo
            });
        }

    }, [parentStorageKey, summaryReportStore, parentDocStorageKey]);

    React.useEffect(() => {
        if (projectId) {
            summaryReportStore.setProjectId(projectId);
            summaryReportStore.getInitialData().then(() => {
                if (documentId && documentType && documentName) {
                    summaryReportStore.setSelectedDocument({
                        documentId, 
                        documentName, 
                        // @ts-ignore
                        type: documentType
                    });
                }          
                highlightInputFromStorage();
            });
        }

        document.body.className = 'no-header';
        window.addEventListener('storage', highlightInputFromStorage);
        return (() => {
            window.removeEventListener('storage', highlightInputFromStorage);
            localStorage.removeItem(parentStorageKey);
            localStorage.removeItem(childStorageKey);
            localStorage.removeItem(parentDocStorageKey);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!summaryReportStore) {
        return null;
    }
    // Temporarily is for Pdf docs only. Can be extended if will be necessary.
    return (
        <div className="preview-doc-wrapper">
            <WrappedMatomoTracker documentTitle={'Summary Report Preview'} />
            <PdfPreview store={summaryReportStore} />
        </div>
    );
};

export default observer(PreviewPage);