import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { CommentData, CellType } from '../../projects/types/CommentData';
import { LotType } from '../../summary_report/types/TopicLotResult';
import CommentsService from '../../projects/services/CommentsService';
import { Utils } from '../misc/Utils';

export default class CommentStore {
    currentComent: string;
    section: string;

    topic: string;
    
    lot?: LotType;

    comments: CommentData[] = [];

    currentSpecTypeId: string;

    projectId: string;

    commentType: CellType;

    notReadCommentsCount: number;

    constructor(private service: CommentsService) {
        makeObservable(this, {
            currentComent: observable,
            comments: observable,
            topic: observable,
            lot: observable,
            section: observable,
            currentSpecTypeId: observable,
            commentType: observable,
            setCurrentComment: action.bound,
            setTopic: action.bound,
            setLot: action.bound,
            createComment: action.bound,
            setCommentDetails: action.bound,
            setCommentType: action.bound,
            addComment: action.bound,
            commentsForCurrentCell: computed

        });
    }

    get commentsForCurrentCell() {
        const filtered = this.comments.filter(c=> c.specificationTypeId === this.currentSpecTypeId && c.topic === this.topic && 
            c.commentType === this.commentType && this.section === c.section);
        return this.commentType === CellType.Lot ? filtered.filter(c=> c.lot === this.lot) : filtered;
    }

    setCommentDetails(section: string, topic: string, specTypeId: string, commentType: CellType, notReadCommentsCount: number, lotType?: LotType) {
        this.setTopic(section, topic);
        this.setLot(lotType);
        this.setCurrentSpecTypeId(specTypeId);
        this.setCommentType(commentType);
        this.setNotReadCommentsCount(notReadCommentsCount);
    }

    setCurrentComment(comment: string) {
        this.currentComent = comment;
    }

    setTopic(section: string, topic: string) {
        this.section = section;
        this.topic = topic;
    }

    setLot(lot?: LotType) {
        this.lot = lot;
    }

    setCurrentSpecTypeId(id: string) {
        this.currentSpecTypeId = id;
    }

    setCommentType(type: CellType) {
        this.commentType = type;
    }

    setNotReadCommentsCount(val: number) {
        this.notReadCommentsCount = val;
    }

    async getComments(projectId: string) {
        const comments = await this.service.getComments(projectId);
        runInAction(() => this.comments = comments);
        return this.comments;
    }

    async createComment(projectId: string, specTypeId: string) {
        const resp = await this.service.createComment(
            projectId,
            Utils.removeNonBreakingSpaces(this.currentComent),
            specTypeId,
            this.section,
            this.topic,
            this.lot!,
            this.commentType
        );
        this.setCurrentComment('');
        return resp;
    }

    addComment(comment: CommentData) {
        this.comments.push(comment);
    }

    setProjectId(id: string) {
        this.projectId = id;
    }

    async markReadComments(ids: string[]) {
        return this.service.markReadComments(this.projectId, ids);
    }

}