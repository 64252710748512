import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Avatar, Button, Divider, Modal } from 'antd';
import ModalTitle from '../../common/components/ModalTitle';
import CommentStore from '../../common/stores/CommentsStore';
import { Utils } from '../../common/misc/Utils';
import { CellType } from '../../projects/types/CommentData';
import { useStore } from '../../common/stores/context';
import ReactQuill from 'react-quill';

type Props = {
    isModalVisible: boolean;
    closeDialog: () => void;
    commentsStore: CommentStore;
    handleCommentSave: () => void
};

const CommentsDialog: React.FC<Props> = ({isModalVisible, closeDialog, commentsStore, handleCommentSave}) => {
    const { userProfileStore } = useStore();

    React.useEffect(() => {
        if (!isModalVisible) {
            commentsStore.setCurrentComment('');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible]);

    const handleDialogClose = () => {
        closeDialog();
    };

    const commentsAbout = (<div className='comments-topic'>Comments about <span>{commentsStore.commentType === CellType.Lot 
        ? `${commentsStore.topic}, ${commentsStore.lot}.` : ` ${commentsStore.topic}, Specification`}</span> </div>);
    const emptyCommentsContainer = (<div className='empty-comments-title'>There is no comments yet.</div>);

    const handleAddComment = () => {
        handleCommentSave();
        commentsStore.setNotReadCommentsCount(0);
    };
    return (
        <Modal
            data-testid='comments-dialog'
            title={<ModalTitle title='Comments' onCloseCallback={handleDialogClose}/>}
            visible={isModalVisible}
            className="roche-client-dialog large-input comments comment-in-modal"
            closable={false}
            bodyStyle={{height: '50vh', display:'flex', flexDirection: 'column-reverse'}}
            zIndex={10000}
            width={700}
            footer={
                <>
                    <div className="comment-input-container">
                        <div className="comment-input-wrapper" data-id={'input-comment'}>
                            <ReactQuill
                                modules={Utils.getQuilModules()}
                                // eslint-disable-next-line max-len
                                className={`comment-input alpha-quill tb-bottom ${commentsStore.currentComent && commentsStore.currentComent.replace(Utils.htmlTags, '').trim().length > 0 ? 'has-text' : ''}`}
                                onChange={commentsStore.setCurrentComment} 
                                value={commentsStore.currentComent}
                                placeholder='Add comment...'
                            />
                            <div className='comment-controls'>
                                <Button style={{zIndex: 10}} className='save-button' type='primary' size='large' onMouseDown={handleAddComment}>
                                    Add comment
                                </Button>
                            </div>
                        </div>
                    </div>
                </>

            }
        >
            {!commentsStore.commentsForCurrentCell?.length ? 
                <div>
                    {commentsAbout}
                    <Divider/>
                    {emptyCommentsContainer}
                </div>
                : 
                <div>
                    {commentsAbout}
                    {commentsStore.commentsForCurrentCell.length === commentsStore.notReadCommentsCount ? 
                        <div className='unread-message-container'>
                            <div className='message'>{commentsStore.notReadCommentsCount} unread message(s)</div>
                            <Divider/>
                        </div>
                        :
                        <Divider/>
                    }
                    {commentsStore.commentsForCurrentCell.map((c, index)=> 
                        <div key={c.id}>
                            <div className='comment-container'>
                                <div><Avatar size={24} src={userProfileStore.userProfilePictures[c.createdByUserId]} /></div>
                                <div style={{marginLeft: 10}}>
                                    <div className='author'>{c.createdByUserName}<span> posted a comment</span></div>
                                    <div className='date'>{Utils.formatDateStringShort(c.createdDate, false, true)}</div>
                                    <div dangerouslySetInnerHTML={{__html: c.text}}/>
                                </div>
                            </div>
                            {index !== commentsStore.commentsForCurrentCell.length - 1 &&
                            <div className='unread-message-container'>
                                {commentsStore.notReadCommentsCount > 0 && 
                                    index == commentsStore.commentsForCurrentCell.length - commentsStore.notReadCommentsCount - 1 && 
                                    <div className='message'>{commentsStore.notReadCommentsCount} unread message(s)</div>}
                                <Divider/>
                            </div>}
                        </div>)}
                </div>}
        </Modal>

    );
    
};

export default observer(CommentsDialog);

