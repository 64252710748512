import { Observer, Observable, Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { DocumentStateChange } from '../types/DocumentStateChange';
import { HistoryData } from '../../projects/types/History';
import { CommentData } from '../../projects/types/CommentData';
import { DocumentTopicChange } from '../types/DocumentTopicChange';

export default class PushClient {
    static RECONNECT_INTERVAL: number = 5000;
    static ConnectionAttempted: boolean = false;
    static ConnectionStatusSubject: Subject<boolean> = new Subject<boolean>();
    static Connection = new signalR.HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_PUSH_URL!)
        .build();

    constructor() {
        PushClient.Connection.onclose(() => {
            PushClient.connect();
        });
    }

    static connect() {
        PushClient.ConnectionAttempted = true;

        PushClient.Connection.start()
            .then(() => {
                PushClient.ConnectionStatusSubject.next(true);
            })
            .catch(() => {
                PushClient.ConnectionStatusSubject.next(false);
                console.info('Wasn\'t able to connect to SignalR service');
                setTimeout(() => {
                    this.connect(); 
                }, PushClient.RECONNECT_INTERVAL);
            });
    }

    createPackageListener(): Observable<DocumentStateChange> {
        return Observable.create((obs: Observer<DocumentStateChange>) => {
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            PushClient.Connection.on('SpecificationDocumentMessage', (data: string) => {
                console.info('Received data from push service on packages...');
                obs.next(JSON.parse(data));
            });
        });
    }

    createVerificationDocumentListener(): Observable<DocumentStateChange> {
        return Observable.create((obs: Observer<DocumentStateChange>) => {
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            PushClient.Connection.on('VerificationDocumentMessage', (data: string) => {
                console.info('Received data from push service on verificaion messages...');
                obs.next(JSON.parse(data));
            });
        });
    }

    createActivityDataListener(): Observable<HistoryData> {
        return Observable.create((obs: Observer<HistoryData>) => {
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            PushClient.Connection.on('HistoryDataMessage', (data: string) => {
                console.info('Received data from push service on activity messages...');
                obs.next(JSON.parse(data));
            });
        });
    }

    createNewCommentDataListener(): Observable<CommentData> {
        return Observable.create((obs: Observer<CommentData>) => {
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            PushClient.Connection.on('NewCommentMessage', (data: string) => {
                console.info('Received data from push service on new comment messages...');
                obs.next(JSON.parse(data));
            });
        });
    }
    
    createDocumentTopicChangeListener(): Observable<DocumentTopicChange> {
        return Observable.create((obs: Observer<DocumentTopicChange>) => {
            if (process.env.NODE_ENV === 'test') {
                return;
            }
            PushClient.Connection.on('DocumentTopicChanged', (data: string) => {
                console.info('Received data from push service on topic change messages...');
                obs.next(JSON.parse(data));
            });
        });
    }
}

if (process.env.NODE_ENV !== 'test') {
    if (!PushClient.ConnectionAttempted) {
        PushClient.connect();
    }
}
