import appClient from '../../common/services/AppClient';
import { HistoryResult } from '../types';
import security from '../../common/services/SecurityService';

export class AuditService {
    async getAudit(take: number, skip: number) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/history/topics?take=${take}&skip=${skip}`;
        const resp = await appClient.get<HistoryResult>(url);  
        return resp.unwrapOr({} as HistoryResult);
    }

    async exportAuditLog(projectId: string) {
        const encodedProjId = encodeURIComponent(projectId);
        security.invoke((token) => {
            const t = '?access_token=' + encodeURIComponent(token);
            const url = `${process.env.REACT_APP_SERVICE_URL}projects/${encodedProjId}/history/topics/download${t}`;
            window.open(url);
            return Promise.resolve();
        });
    }

}