import { observer } from 'mobx-react-lite';
import * as React from 'react';
import SummaryReportStore from '../stores/SummaryReportStore';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { SecurityService } from '../../common/services';

type Props = {
    store: SummaryReportStore
};

const PdfPreview: React.FC<Props> = ({store}) => {  
    const [pages, setPages] = React.useState(1);
    const showHighlightBlock = () => {     
        if (!store.highlightedBlock) {
            return;
        }
        const blockId = store.projectId + '-report-document-highlight-block';        
        const containerId = store.projectId + '-report-document';
        const {x, y, w, h, page, pageWidth} = store.highlightedBlock;
        const sizeCoefficient = 1000 / pageWidth;

        document.getElementById(blockId)?.remove();

        const newBlock = document.createElement('div');
        newBlock.className = 'highlight-block';
        newBlock.style.width = `${w * sizeCoefficient}px`;
        newBlock.style.height = `${h * sizeCoefficient}px`;
        newBlock.style.bottom = `${ ((y * sizeCoefficient) - (h * sizeCoefficient))}px`;
        newBlock.style.left = `${x * sizeCoefficient}px`;
        newBlock.id = blockId;

        const docContainer = document.getElementById(containerId);

        if (!docContainer) {
            return;
        }

        const pageContainer = docContainer?.querySelector(`[data-page-number='${page + 1}']`);

        if (!pageContainer) {
            return;
        }

        pageContainer.appendChild(newBlock);
        setTimeout(() => {
            newBlock.scrollIntoView({behavior: 'smooth', block:'center'});
        }, 200);
    };

    React.useEffect(() => {
        const sub = store.highlightedBlockSubject.subscribe(showHighlightBlock);

        return () => {
            sub.unsubscribe();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!store.selectedDocument) {
        return null;
    }

    function onDocumentLoadSuccess({ numPages }: { numPages: number}) {
        setPages(numPages);
        setTimeout(() => {
            showHighlightBlock();
        }, 200);
    }

    return (
        <div id={store.projectId + '-report-document'} style={{position: 'relative', width: 1000, margin: '30px auto'}}>
            <Document 
                file={{ 
                    url: process.env.REACT_APP_SERVICE_URL + `projects/${store.projectId}/${store.selectedDocument.type}/${store.selectedDocument.documentId}/download`, 
                    httpHeaders: { 'Authorization': 'Bearer ' + SecurityService.token } 
                }} 
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {Array.from(Array(pages).keys()).map((p) => (
                    <Page key={p + 1} pageNumber={p + 1} width={1000} renderTextLayer={false} renderAnnotationLayer={false} />
                ))}
            </Document>
        </div>
    );
};

export default observer(PdfPreview);
