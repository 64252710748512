import * as React from 'react';
import { Route } from 'react-router';
import PreviewPage from '../../summary_report/screens/PreviewPage';
import SummaryReportPage from '../../summary_report/screens/SummaryReportPage';
import { ProjectDashboard, ProjectsPage } from '../screens';
import AuditPage from '../screens/AuditPage';
import { context } from '../stores';
const projectsRoute = () => {
    return (
        <>
            <Route
                path={'/projects'}
                element={
                    <context.ProjectsStoreProvider>
                        <ProjectsPage  />
                    </context.ProjectsStoreProvider>
                }
            />
            <Route 
                path="/projects/:projectId" 
                element={
                    <context.ProjectsStoreProvider>
                        <ProjectDashboard  />
                    </context.ProjectsStoreProvider>
                }
            />
            <Route 
                path="/projects/:projectId/summary-report" 
                element={
                    <context.ProjectsStoreProvider>
                        <SummaryReportPage  />
                    </context.ProjectsStoreProvider>
                }
            />
            <Route
                path={'/archive'}
                element={
                    <context.ProjectsStoreProvider>
                        <div  />
                    </context.ProjectsStoreProvider>
                }
            />
            <Route 
                path="/preview/:projectId/:documentId/:documentType" 
                element={
                    <context.ProjectsStoreProvider>
                        <PreviewPage  />
                    </context.ProjectsStoreProvider>
                }
            />
            <Route
                path={'/audit'}
                element={
                    <context.ProjectsStoreProvider>
                        <AuditPage  />
                    </context.ProjectsStoreProvider>
                }
            />
        </>
    );
};

export default projectsRoute;