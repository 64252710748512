import * as React from 'react';
import { Row, Col } from 'antd';
import { observer } from 'mobx-react-lite';


type Props = {
    title: string;
    onCloseCallback: () => void
};

const ModalTitle: React.FC<Props> = ({title, onCloseCallback}) => {
    return (
        <Row style={{justifyContent: 'space-between'}}>
            <Col>
                <div>{title}</div>
            </Col>
            <Col>
                <div onClick={onCloseCallback}>
                    <i className='roche-icon sm close-modal-cross'/>
                </div>
            </Col>
        </Row>
    );
};


export default observer(ModalTitle);