import { observer } from 'mobx-react-lite';
import './UploadCommentDialog.less';
import * as React from 'react';
import { Button, Form, Modal } from 'antd';
import { Utils } from '../../../common/misc/Utils';
import CommentForm from '../CommentForm';

type Props = {
    isModalVisible: boolean;
    submitComment: (comment: string) => void;
    closeDialog: () => void
};

const UploadCommentDialog: React.FC<Props> = ({isModalVisible, closeDialog, submitComment}) => {
    const [form] = Form.useForm();

    React.useEffect(() => {
        if (isModalVisible) {
            form.resetFields();
        }
    }, [isModalVisible, form]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFormFinish = (values: any) => {
        const comment = Utils.removeNonBreakingSpaces(values.comment);
        submitComment(comment);
    };

    return (
        <Modal
            data-testid='upload-comments-dialog'
            title="Comment"
            visible={isModalVisible}
            onCancel={closeDialog}
            className="roche-client-dialog large-input comment-in-modal"
            closable={false}
            maskClosable={false}
            bodyStyle={{display:'flex', flexDirection: 'column-reverse'}}
            zIndex={10000}
            width={700}
            footer={[
                <Button 
                    data-id="upload-without-analysis-comment-cancel-button"
                    key="assign-cancel-button" 
                    className="light"
                    size="large"
                    onClick={closeDialog}
                >
                    Cancel
                </Button>,
                <Button 
                    data-id="upload-without-analysis-comment-save-button"
                    key="assign-save-button" 
                    type="primary"
                    size="large"
                    htmlType="submit"
                    form="upload-without-analysis-comment-form"
                >
                    Save
                </Button>
            ]}
        >
            <Form form={form} id="upload-without-analysis-comment-form" layout='vertical' onFinish={onFormFinish}>
                <CommentForm form={form} label='Comment' isDisabled={false} isRequired />
            </Form>
        </Modal>
    );
};

export default observer(UploadCommentDialog);