import { DeleteOutlined, FileDoneOutlined, FileOutlined, FileSearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Row, Upload, Col, Tooltip, Popconfirm, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { RocheDocument } from '../types';

type Props = {
    beforeUpload: (file: RcFile) => boolean;
    setCurrentMatrixField: (field: string | undefined) => void;
    handleDelete: (documentId: string) => void;
    matrixField: string;
    document?: RocheDocument
};

const ProjectDvpMatrixCell: React.FC<Props> = ({document, beforeUpload, matrixField, setCurrentMatrixField, handleDelete}) => {

    const getStatusIcon = (status: string) => {
        switch(status) {
        case 'Draft':
            return <FileOutlined />;
        case 'InReview':
            return <FileSearchOutlined />;
        case 'Approved':
            return <FileDoneOutlined />;
        default:
            return null;
        }
    };

    if (document) {
        return (
            <Row data-testid="dvp-matrix-cell-document">
                <Col style={{lineHeight: '32px'}}>
                    <a 
                        href={`${process.env.PUBLIC_URL}/document/${encodeURIComponent(document.id)}/${encodeURIComponent(document.name)}`} 
                        target="_blank" rel="noreferrer"
                    >
                        {document.name}
                    </a>
                </Col>
                <Col offset={1}>
                    <Tooltip title={document.reportStatus}>
                        {getStatusIcon(document.reportStatus)}
                    </Tooltip>
                    <Popconfirm 
                        title={`Are you sure you want to delete '${document.name}'?`}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDelete(document.id)}
                    >
                        <Tooltip title="Delete document">
                            <Button type="link">
                                <DeleteOutlined />
                            </Button>
                        </Tooltip>
                    </Popconfirm>
                </Col>
            </Row>
        );
    }

    const handleBeforeUpload = (file: RcFile) => {
        const isPdf = file.type === 'application/pdf';
        const isXls = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
            'application/vnd.ms-excel',
            'application/vnd.ms-excel.sheet.macroEnabled.12'].includes(file.type);
        if (!isPdf && !isXls) {
            message.error(`${file.name} is not a pdf or Excel file`);
            return false;
        }
        
        setCurrentMatrixField(matrixField);
        return beforeUpload(file);
    };

    return (
        <Upload
            showUploadList={false}
            beforeUpload={handleBeforeUpload}
            
        >
            <Button data-testid="dvp-matrix-cell-upload"><UploadOutlined/>Upload document</Button>
        </Upload>
    );
};

export default observer(ProjectDvpMatrixCell);