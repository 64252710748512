import * as React from 'react';
import { observer } from 'mobx-react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Utils } from '../../misc/Utils';

type Props = {
    documentTitle: string
};


const WrappedMatomoTracker: React.FC<Props> = ({documentTitle}) => {
    if (!process.env.REACT_APP_MATOMO_URL || !Utils.isValidHttpUrl(process.env.REACT_APP_MATOMO_URL)) {
        return null;
    }

    return (
        <MatomoTracker documentTitle={documentTitle} />
    );
};

const MatomoTracker: React.FC<Props> = ({documentTitle}) => {
    const { trackPageView } = useMatomo();

    React.useEffect(() => {
        trackPageView({
            documentTitle
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};


export default observer(WrappedMatomoTracker);