import { Button, Modal } from 'antd';
import React from 'react';
import ModalTitle from './ModalTitle';

type Props = {
    confirmation: {
        cancelHandler: () => void;
        confirmButtonText: string
    };
    visible: boolean;
    text: React.ReactElement;
    confirmHandler: () => void

};
const ConfirmationDialog: React.FC<Props> = ({confirmation, visible, confirmHandler, text}) => {
    const { cancelHandler, confirmButtonText} = confirmation;
    return (
        <Modal
            visible={visible}
            closable={false}
            maskClosable={false}
            title={<ModalTitle title='Please confirm' onCloseCallback={cancelHandler}/>}
            centered
            className="roche-client-dialog two-columns confirm"
            zIndex={2000}
            footer={[
                <Button 
                    data-id="remove-project-cancel" 
                    key="remove-project-cancel" 
                    onClick={cancelHandler} 
                    size="large"
                    className="light"
                >
                    Cancel
                </Button>,
                <Button 
                    data-id="remove-project-submit" 
                    key="remove-project-submit" 
                    size="large"
                    type="primary"
                    onClick={confirmHandler}
                >
                    {confirmButtonText}
                </Button>
            ]}
        >
            {text}
        </Modal>
    );
};

export default (ConfirmationDialog);