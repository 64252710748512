import * as React from 'react';
import  { DragDropContainer, DropTarget, OnHitEventArgs }  from 'react-drag-drop-container';
import { observer } from 'mobx-react';

type Props = {
    index: number;
    children: JSX.Element;
    handleOnDrop: (index: number) => void;
    handleOnHit: (args: OnHitEventArgs, index: number) => void
};

export const DraggableItemWrapper:  React.FC<Props> = ({index, children, handleOnDrop, handleOnHit}) => {      
    return(
        <div>
            <DragDropContainer
                targetKey="stepItem"
                dragData={{children: children, index: index}}
                onDrop={() => handleOnDrop(index)}
                disappearDraggedElement
                dragHandleClassName="draggable-item"
            >
                <DropTarget
                    onHit={(args: OnHitEventArgs) => handleOnHit(args, index)}
                    targetKey="stepItem"
                >
                    <div>
                        {children}
                    </div>
                
                </DropTarget>
            </DragDropContainer>
        </div>
    );
};

export default observer(DraggableItemWrapper);