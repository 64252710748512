import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip, Spin } from 'antd';
import { List, CellMeasurerCache, CellMeasurer, ListRowProps } from 'react-virtualized';
import { Utils } from '../../common/misc/Utils';
import { NEW_ANALYSIS_CONTAINER_INITIAL_HEIGHT } from './NewContractDialog';
import { AlphaPackage, AlphaPackageStateResult } from '../../common/types/AlphaPackage';
import AlphaPackagesStore from '../../common/stores/AlphaPackagesStore';
import stc from 'string-to-color';

class Constants {
    static documentSelectionLimit = 6;
}
const CONTAINER_TOP = 308;
const LARGE_NUMBER = 1000;
type Props = {
    alphaPackagesStore: AlphaPackagesStore;
    selectPackage: (pkg: AlphaPackage) => void;
    visible: boolean
};

export const PackageList: React.FC<Props> = ({ alphaPackagesStore, selectPackage, visible }) => {
    const [selectedItemsHeight, setSelectedItemsHeight] = React.useState<{[key: string]: number}>({});
    const [selectedDivs, setSelectedDiv] = React.useState({});
    const cache = React.useMemo(() => new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 55,
        keyMapper: rowIndex => alphaPackagesStore.filteredPackages[rowIndex].id
    }), [alphaPackagesStore.filteredPackages]);

    let listRef: React.RefObject<List> = React.useRef(null);

    React.useEffect(() => {
        if (!visible) {
            alphaPackagesStore.removeStickedPackageItem();
            setSelectedItemsHeight({});
            setSelectedDiv({});
        }
    },              [alphaPackagesStore, visible]);

    React.useEffect(() => {
        if (listRef && listRef.current) {
            cache.clearAll();
            listRef.current.recomputeRowHeights();
        }
    },              [cache, alphaPackagesStore.filteredPackages]);

    const generateDocumentIcon = (documentName: string) => {
        return (
            <i className={Utils.generateDocumentIcon(documentName)} />
        );
    };

    const handleSelect = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, item: AlphaPackage) => {
        if (alphaPackagesStore.selectedPackageId !== item.id) {
            Object.keys(selectedItemsHeight).forEach(x => delete selectedItemsHeight[x]);
            const id = alphaPackagesStore.stickedItemsPackageIds.find(x => x);
            if (id) {
                alphaPackagesStore.removeStickedPackageItem(id);
            }
            delete selectedDivs[id || ''];
            selectedItemsHeight[item.id] = ev.currentTarget.offsetHeight;
            const obj = {};
            obj[item.id] = ev.currentTarget;
            setSelectedDiv(obj);
            setSelectedItemsHeight(selectedItemsHeight);
        } else {
            delete selectedDivs[item.id];
            delete selectedItemsHeight[item.id];
            setSelectedItemsHeight(selectedItemsHeight);
            alphaPackagesStore.removeStickedPackageItem(item.id);
        }

        selectPackage(item);
    };

    const docItem = (item: AlphaPackage, style: React.CSSProperties) => {
        return( <>
            <div
                ref={el => {
                    if (alphaPackagesStore.selectedPackageId && alphaPackagesStore.selectedPackageId === item.id && visible) {
                        selectedDivs[item.id] = el; 
                    }
                }
                }
                style={{...style, borderBottom: '1px solid #E4E5ED', left: 6 }}
                key={item.id}
                className={`ant-list-item project-list-item 
                    ${alphaPackagesStore.selectedPackageId && alphaPackagesStore.selectedPackageId === item.id ? 'selected' : ''}
                    ${item.state !== AlphaPackageStateResult.Ready ? 'processing' : ''}
                    ${item.state === AlphaPackageStateResult.Broken ? 'disabled' : ''}`
                }
                onClick={(ev) => handleSelect(ev, item)}
            >
                <div>
                    {generateDocumentIcon(item.fileName)}
                </div>
                <div>
                    <div>
                        <span style={{ fontSize: 14 }}>{item.fileName}</span>
                        {item.state !== AlphaPackageStateResult.Ready && item.state !== AlphaPackageStateResult.Broken && (
                            <Tooltip title="Processing...">
                                <span className="info-label" style={{ marginLeft: 7 }}>
                                    <Spin
                                        indicator={<LoadingOutlined style={{ fontSize: 16, marginRight: 7 }} spin />}
                                    />
                                </span>
                            </Tooltip>
                        )}
                    </div>
                    <div className="additional-info">
                        {item.state === AlphaPackageStateResult.Uploading && (
                            <span className="info-label">
                            Uploading...
                            </span>
                        )}
                        {item.state !== AlphaPackageStateResult.Uploading && item.uploadedTime && (
                            <span className="info-label">
                                {Utils.formatDateStringShort(item.uploadedTime)}
                            </span>
                        )}
                        {item.state !== AlphaPackageStateResult.Uploading && item.fileSizeBytes > 0 && (
                            <span className="info-label">
                                {Utils.readableFileSize(item.fileSizeBytes, true)}
                            </span>
                        )}
                        {item.userTags && item.userTags.map((t) => (
                            <span key={`${item.id}-${t}`} className="project-tag" style={{background: `${stc(t)}`}}>{t}</span>
                        ))}
                        {item.state === AlphaPackageStateResult.Broken && (
                            <span key={`${item.id}-broken-tag`} className="project-tag broken-tag" style={{background: '#B80C02'}}>Broken</span>
                        )}
                    </div>
                </div>
            </div>
        </>
        );
    };
    
    const renderRow = ({ index, style, ...rest }: ListRowProps) => {
        return (
            <CellMeasurer cache={cache} rowIndex={index} {...rest}>
                {docItem(alphaPackagesStore.filteredPackages[index], style)}
            </CellMeasurer>
        );
    };

    const getContainerHeight = (itemsHeight: {}) => {
        const itemsSticked = Object.keys(itemsHeight).filter(x => alphaPackagesStore.stickedItemsPackageIds.includes(x));
        return NEW_ANALYSIS_CONTAINER_INITIAL_HEIGHT - itemsSticked.map(x => itemsHeight[x]).reduce((a, b) => a + b, 0);
    };

    const handleScroll = () => {
        const stickedItemsCount =  alphaPackagesStore.stickedItemsPackageIds.length;
        if (stickedItemsCount === Constants.documentSelectionLimit || !alphaPackagesStore.selectedPackageId) {
            return;
        }
        const boundaryValue = stickedItemsCount === 0 ? CONTAINER_TOP : selectedItemsHeight[alphaPackagesStore.stickedItemsPackageIds[0]] + CONTAINER_TOP;
        const ids =  Object.keys(selectedDivs).filter(x => !alphaPackagesStore.stickedItemsPackageIds.includes(x));
        if (!ids.length) {
            return;
        }
        const res = ids.map(id => {
            return {id: id, top: selectedDivs[id] && selectedDivs[id].getBoundingClientRect().top || LARGE_NUMBER}; 
        });
        const sorted  = res.sort((a, b) => (a.top > b.top) ? 1 : -1)[0];
        if (sorted.top < boundaryValue) {
            alphaPackagesStore.setStickedPackageItem(sorted.id);
        }
    };

    return (
        <>
            {alphaPackagesStore.isLoading ? (
                <div 
                    style={{
                        display: 'flex',
                        alignItems: 'middle',
                        flexFlow: 'column',
                        textAlign: 'center'
                    }}
                >
                    <Spin />
                    <div>
                        Loading packages...
                    </div>
                </div>
                
            ) : (
                <>
                    {alphaPackagesStore.stickedItemsPackageIds && alphaPackagesStore.stickedItemsPackageIds.map((x, index) => {
                        const style = {height: selectedItemsHeight[x]} as React.CSSProperties;
                        if (index === alphaPackagesStore.stickedItemsPackageIds.length - 1) {
                            style.boxShadow = '0px 2px 4px rgba(0, 0, 0, 0.15)';
                        }
                        return  (<div key="x">
                            {docItem([...alphaPackagesStore.packages ].find(s => s.id === x)!, style)}
                        </div>);
                    }) }
                    <List
                        ref={listRef}
                        className="ant-list ant-list-sm ant-list-split"
                        style={{ outline: 'none'}}
                        width={425}
                        height={getContainerHeight(selectedItemsHeight)}
                        rowHeight={cache.rowHeight}
                        deferredMeasurementCache={cache}
                        rowCount={alphaPackagesStore.filteredPackages.length}
                        overscanRowCount={15}
                        rowRenderer={renderRow}
                        onScroll={handleScroll}
                    />
                </>
            )}
        </>
    );
};

export default observer(PackageList);