/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Dropdown, Menu, Row, Table, Tabs, Tooltip, Collapse, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { observer } from 'mobx-react-lite';
import React from 'react';
import EditValueDialog from './EditValueDialog';
import SetFinalResultDialog from './SetFinalResultDialog';
import SummaryReportHeaderControls from './SummaryReportHeaderControls';
import SummaryReportStore from '../stores/SummaryReportStore';
import CommentsDialog from './CommentsDialog';
import CommentStore from '../../common/stores/CommentsStore';
import { CellType } from '../../projects/types/CommentData';
import { LotType, TopicFieldMeta } from '../types/TopicLotResult';
import DocumentPreview from './DocumentPreview';
import { useStore } from '../../common/stores/context';
import { SpecificationTypes } from '../../projects/types/ApplicationReport';
import _ from 'lodash';
import { UpCircleOutlined, RightOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
// import ResultHelpers from '../../common/misc/ResultHelpers';
import { HTMLRenderer } from '../../common/components';

type Props = {
    store: SummaryReportStore;
    commentsStore: CommentStore
};

type LotCell = {
    lotValue: string | null;
    oldLotValue: string | null;
    commentsCount: number;
    isEdited: boolean;
    unavailable: boolean;
    notReadCommentsCount: number;
    editComment: string;
    isFinalResult: boolean;
    fieldMetas: TopicFieldMeta[] | undefined
};

type SpecificationCell = {
    specValue: string | null;
    oldValue: string | null;
    commentsCount: number;
    fieldMetas: TopicFieldMeta[] | undefined;
    documentName: string;
    documentId: string;
    isEdited: boolean;
    editComment: string;
    notReadCommentsCount: number;
    overridingPackageId?: string
};

type ResultLot = {
    lotType: LotType;
    lotCell: LotCell
};

export type SummaryReportTableModel = {
    sectionId: string;
    sectionName: string;
    topic: string;
    specification: SpecificationCell;
    resultLots: ResultLot[];
    methodSheet: LotCell;
    protocolData: SpecificationCell[]
};


const { TabPane } = Tabs;
const { Panel } = Collapse;

type SpecValueDropdownProps = {
    store: SummaryReportStore;
    record: SummaryReportTableModel;
    val: SpecificationCell;
    cellBlockId: string;
    getCursorStyle: () => 'not-allowed' | 'pointer';
    showMissingSpecMsg: () => void;
    tableActionsMenu: (
        section: string,
        sectionName: string,
        topic: string,
        val: string | null,
        cellType: CellType,
        notReadCommentsCount: number,
        isEdited: boolean,
        hasFieldMeta: boolean,
        lotType?: LotType,
        docId?: string
    ) => JSX.Element
};

const SpecValueDropdown: React.FC<SpecValueDropdownProps> = ({
    store,
    record,
    val,
    cellBlockId,
    getCursorStyle,
    showMissingSpecMsg,
    tableActionsMenu
}) => {
    const [visible, setVisible] = React.useState(false);

    // We need to increase z-index of the cell when dropdown is open, otherwise it will be covered by other cells (position: sticky issue)
    React.useEffect(() => {
        const cellBlock = document.getElementById(cellBlockId);

        if (!cellBlock) {
            return;
        }

        if (visible) {
            cellBlock.style.zIndex = '990';
            return;
        }

        const timeout = setTimeout(() => {
            cellBlock.style.zIndex = '1';
        }, 500);

        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);
    
    return (
        <Dropdown
            visible={visible}
            onVisibleChange={setVisible}
            getPopupContainer={() =>
                document.getElementById(cellBlockId) as HTMLElement
            }
            overlayStyle={{ width: 170, zIndex: 999 }}
            trigger={['click']}
            overlay={tableActionsMenu(
                record.sectionId,
                record.sectionName,
                record.topic,
                val.specValue,
                CellType.Specification,
                val.notReadCommentsCount,
                val.isEdited,
                !!val.fieldMetas?.length,
                undefined,
                val.documentId
            )}
            disabled={store.currentProject?.isArchived}
        >
            <i
                className="roche-icon sm action-more-dots"
                style={{ cursor: getCursorStyle() }}
                onClick={showMissingSpecMsg}
            />
        </Dropdown>
    );
};

const SumaryReportView: React.FC<Props> = ({store, commentsStore}) => {
    const { userProfileStore } = useStore();

    const [isEditModalVisible, setIsEditModalVisible] = React.useState(false);
    const [isFinalResultModaltVisible, setIsFinalResultModaltVisible] = React.useState(false);
    const [isCommentsDialogVisible, setIsCommentsDialogVisible] = React.useState(false);
    const [isDataChangeModalVisible, setIsDataChangeModaVisible] = React.useState(false);
    
    React.useEffect(() => {
        store.getMethodSheetSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const parentDocStorageKey = `parent-${store.projectId}-document`;
        localStorage.setItem(parentDocStorageKey, JSON.stringify(store.selectedDocument));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.selectedDocument]);

    React.useEffect(() => {
        handleHighlightedBlockChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.highlightedBlock, store.currentSpecTypeId]);

    const handleScroll = () => {
        const scrollable = document.getElementsByClassName('ant-tabs ant-tabs-top')[0];
        if (scrollable) {
            const position = scrollable.scrollTop;
            store.setScrollPosition(position);
        }
    };

    React.useEffect(() => {
        const scrollable = document.getElementsByClassName('ant-tabs ant-tabs-top')[0];
        const throttled = _.throttle(handleScroll, 1000);
        if (scrollable.scrollTo) {
            scrollable.scrollTo(0, store.scrollPosition);
        }
        scrollable.addEventListener('scroll', throttled);
        return () => {
            scrollable.removeEventListener('scroll', throttled);
        };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.currentProject?.id]);

    const handleHighlightedBlockChange = () => {
        const containerId = `${store.projectId}-method-sheet-summary`;
        const container = document.getElementById(containerId);

        if (!container) {
            return;
        }

        if (store.highlightedBlock) {
            const cellToHighlight = container.querySelector(`td[data-cell-id='${store.highlightedBlock.blockId}'`);

            if (cellToHighlight) {
                const hasCollapsedPreview = container.querySelector('.document-preview-wrapper.collapsed') != null;
                const hasNoPreviewLoaded = container.querySelector('.react-pdf__message--loading') != null;

                if (hasCollapsedPreview || hasNoPreviewLoaded) {
                    cellToHighlight.scrollIntoView({behavior: 'auto', block: 'center'});
                }
            }
        }
    };

    const showMissingSpecMsg = () => {
        if (!store.specDocExists()) {
            message.warning('Please upload specification document for current specification type');
        }
    };

    const handleEditDataClick = (sectionId: string, sectionName: string, topic: string, val: string | null, cellType: CellType, lotType?: LotType, docId?: string) => {
        if (!store.specDocExists()) {
            showMissingSpecMsg();
            return; 
        }
        store.setEditableTopic(topic);
        store.setCurrentValue(val);
        store.setEditableCellType(cellType);
        if (cellType === CellType.Lot) {
            store.setEditableLotType(lotType!);
        }
        store.setEditableSection(sectionId, sectionName);
        store.setCurrentDocId(docId);
        setIsEditModalVisible(true);
    };


    const handleCommentDialogOpen = ( section: string, topic: string, commentType: CellType, notReadCommentsCount: number, lotType?: LotType) => {
        commentsStore.setCommentDetails(section, topic, store.currentSpecTypeId, commentType, notReadCommentsCount, lotType);
        setIsCommentsDialogVisible(true);
    };

    const tableActionsMenu = (section: string, sectionName: string, topic: string, val: string | null, 
                              cellType: CellType, notReadCommentsCount: number, isEdited: boolean, hasFieldMeta: boolean, lotType?: LotType, docId?: string) => {
        return(
            <Menu className='table-actions-menu' data-id-type="popup-menu-list-root" >
                <Menu.Item key='edit' data-id-type="popup-menu-list-item">
                    <div data-testid='open-spec-doc' className='action-container' onClick={() => handleEditDataClick(section, sectionName, topic, val, cellType, lotType, docId)}> 
                        <i className="roche-icon sm edit" />
                        <div>Edit data</div>
                    </div>
                </Menu.Item>
                {val &&
                    <>
                        {!isEdited && hasFieldMeta && 
                            <Menu.Item onClick={() => store.handleDownload(lotType, topic, sectionName)} key='download' data-id-type="popup-menu-list-item">
                                <div className='action-container' data-testid='download-spec-doc'>
                                    <i className="roche-icon md download" />
                                    <div>Download</div>
                                </div>
                            </Menu.Item>}
                        <Menu.Item onClick={() => handleCommentDialogOpen(sectionName, topic, 
                            cellType,notReadCommentsCount, lotType)} key='comments' data-id-type="popup-menu-list-item">
                            <div className='action-container' data-testid='remove-spec-doc'>
                                <i className="roche-icon md comments" />
                                <div>View comments</div>
                            </div>
                        </Menu.Item>
                    </>
                }
            </Menu>
        );
    };

    const highlightBlockHandler = (record: SummaryReportTableModel, blockId: string, lotType?: LotType | undefined, documentId?: string) => {
        if (!store.specDocExists()) {
            showMissingSpecMsg();
            return;
        }
        store.highlightBlock(record, blockId, lotType, documentId);
        const storageObj = {
            record,
            blockId,
            lotType,
            timeStamp: new Date().toISOString()
        };
        const parentStorageKey = `parent-${store.projectId}`;
        localStorage.setItem(parentStorageKey, JSON.stringify(storageObj));
    };

    const handleWarningCLick = (newVal: string | null, oldVal: string | null, comment: string, topic: string) => {
        store.setEditedDataModalNewValue(newVal);
        store.setCurrentValue(oldVal);
        store.setEditedDataModalComment(comment);
        store.setEditableTopic(topic);
        setIsDataChangeModaVisible(true);
    };

    const getCursorStyle = () => {
        return store.currentProject?.isArchived ? 'not-allowed' : 'pointer';
    };

    const getPointerEventsStyle = () => {
        return store.currentProject?.isArchived ? 'none' : 'auto';
    };

    const specValueRenderer = (
        val: SpecificationCell, 
        record: SummaryReportTableModel, 
        specCell: SpecificationCell, 
        cellBlockId: string, 
        isDifferentValueFromPreviousVersion: boolean = false,
        documentId: string
    ) => {
        const emptyValueRenderer = <span style={{ pointerEvents : getPointerEventsStyle()}} 
            className='enter-data-label' onClick={() => handleEditDataClick(record.sectionId, record.sectionName, 
                record.topic, null, CellType.Specification, undefined, documentId)}>Enter data...</span>;
        
        const renderSpecValue = () => {
            if (!val.specValue) {
                return emptyValueRenderer;
            }

            return (
                <HTMLRenderer
                    html={val.specValue!}
                    maxImageWidth={200}
                    imagePaddingRight={14}
                    conciseViewEnabled={store.isConciseViewEnabled}
                />
            );
        };
        
        return ( 
            <span>
                <Row className='table-cell' style={{width: '100%'}}>
                    {val.isEdited &&
                        <Col>
                            <Tooltip title='The data was changed. Please review'>
                                <i 
                                    style={{cursor: 'pointer'}} 
                                    className='roche-icon sm cell-warning' 
                                    onClick={() => handleWarningCLick(val.specValue, val.oldValue!, val.editComment, record.topic)}
                                />
                            </Tooltip>
                        </Col>
                    }
                    {!val.isEdited && isDifferentValueFromPreviousVersion &&
                    <Col>
                        <Tooltip title='The data differs from previous version. Please review'>
                            <i 
                                style={{cursor: 'pointer'}} 
                                className='roche-icon sm cell-warning' 
                                onClick={() => handleWarningCLick(val.specValue, val.oldValue!, val.editComment, record.topic)}
                            />
                        </Tooltip>
                    </Col>}
                    {val.overridingPackageId && val.specValue && !val.isEdited &&
                        <Col>
                            <Tooltip title='Values not extracted from uploaded PSD'>
                                <ExclamationCircleOutlined style={{color:'red', marginRight: 12}} />
                            </Tooltip>
                        </Col>
                    }
                    <Col lg={15} xl={17} xxl={19}  style={{cursor: getCursorStyle()}} className='result-value-container'>
                        {renderSpecValue()}
                    </Col>

                    <div className='hidden-items'>
                        {!val.isEdited && val.specValue && !val.overridingPackageId &&
                            <Tooltip title={val.fieldMetas?.length ? 'View in document' : 'Constant'}>
                                {val.fieldMetas?.length 
                                    ? <i className='roche-icon sm view-in-doc' onClick={() => highlightBlockHandler(record, cellBlockId, undefined, documentId)}/>
                                    : <i className='roche-icon sm gear' style={{height: 16}} />}
                            </Tooltip>}

                        <SpecValueDropdown
                            val={val}
                            record={record}
                            store={store}
                            cellBlockId={cellBlockId}
                            getCursorStyle={getCursorStyle}
                            showMissingSpecMsg={showMissingSpecMsg}
                            tableActionsMenu={tableActionsMenu}
                        />
                    </div>
                    {!!val.commentsCount && 
                        <Col 
                            className={val.notReadCommentsCount > 0 ? 'comments-count red' : 'comments-count'}
                            onClick={() => handleCommentDialogOpen(record.sectionName, record.topic, CellType.Specification, val.notReadCommentsCount)} 
                        >
                            <i className={val.notReadCommentsCount > 0 ? 'roche-icon md not-read-comment' : 'roche-icon md comment'}/>
                            <div>{val.commentsCount > 9 ? '+9' : val.commentsCount}</div>
                        </Col>}
                </Row>
            </span>);
    };

    // const getColPropsForResult = (isEdited: boolean) => {
    //     return isEdited ? { xl: 13, xxl:16, lg:10} : {xl: 17, xxl: 19};
    // };

    // const handleSetFinalResultClick = (section: string, topic: string, type: LotType, isFinalResult: boolean) => {
    //     store.setFinalResultParams(section, topic, type, isFinalResult);
    //     setIsFinalResultModaltVisible(true);
    // };

    // const lotCellRenderer = (val: LotCell, record: SummaryReportTableModel, lotType: LotType, cellData: LotCell) => {
    //     const commentContainer = 
    //         (!!cellData.commentsCount && 
    //         <Col 
    //             className={cellData.notReadCommentsCount > 0 ? 'comments-count red' : 'comments-count'}
    //             onClick={() => handleCommentDialogOpen(record.sectionName, record.topic, CellType.Lot, cellData.notReadCommentsCount, lotType)}>
    //             <i className={cellData.notReadCommentsCount > 0 ? 'roche-icon md not-read-comment' : 'roche-icon md comment'}/>
    //             <div>{cellData.commentsCount > 9 ? '+9' : cellData.commentsCount}</div>
    //         </Col>);

    //     const emptyValueRenderer = 
    //         <span className='enter-data-label' style={{ pointerEvents : getPointerEventsStyle()}} 
    //             onClick={() => handleEditDataClick(record.sectionId, record.sectionName, 
    //                 record.topic, null, CellType.Lot, lotType)}>Enter data...</span>;
            
    //     const lotValueRenderer = () => {
    //         if (!val.lotValue) {
    //             return emptyValueRenderer;
    //         }

    //         return (
    //             <HTMLRenderer
    //                 html={val.lotValue}
    //                 maxImageWidth={200}
    //                 imagePaddingRight={14}
    //                 conciseViewEnabled={store.isConciseViewEnabled}
    //             />
    //         );
    //     };
    
    //     return !cellData.unavailable ? ( 
    //         <span>
        
    //             <Row className='table-cell' style={{width: '100%'}}>
    //                 {/* <Col>
    //                     {!!val.lotValue && lotType !== 'MethodSheet' && <i className={`roche-icon sm ${cellData.isFinalResult ? 'circled-check-checked' :'circled-check'}`} 
    //                         onClick={() => handleSetFinalResultClick(record.sectionName, record.topic, lotType, cellData.isFinalResult)} 
    //                         style={{pointerEvents: getPointerEventsStyle()}} />} 
    //                 </Col> */}
    //                 <Col>
    //                     {val.isEdited && 
    //                         <Tooltip title='The data was changed. Please review'>
    //                             <i 
    //                                 style={{cursor: 'pointer'}} 
    //                                 className='roche-icon sm cell-warning' 
    //                                 onClick={() => handleWarningCLick(val.lotValue, cellData.oldLotValue, val.editComment, record.topic)}
    //                             />
    //                         </Tooltip>}
    //                 </Col>
    //                 <Col {...getColPropsForResult(val.isEdited)} style={{cursor: getCursorStyle()}} className='result-value-container'>
    //                     {lotValueRenderer()}
    //                 </Col>
    //                 <div className='hidden-items'> 
    //                     {!val.isEdited && val.lotValue && <Tooltip title='View in document'>
    //                         {val.fieldMetas?.length 
    //                             ? <i className='roche-icon sm view-in-doc' onClick={() => highlightBlockHandler(record, record.sectionId + record.topic + lotType, lotType)}/>
    //                             : <i className='roche-icon sm gear' style={{height: 16}} />}
    //                     </Tooltip>}
    //                     <Dropdown
    //                         getPopupContainer={() => document.getElementById(`${record.sectionId}${record.topic}${lotType}`) as HTMLElement}
    //                         overlayStyle={{width: 170, zIndex: 999}}
    //                         trigger={['click']}
    //                         disabled={store.currentProject?.isArchived}
    //                         overlay={tableActionsMenu(record.sectionId, record.sectionName, record.topic, 
    //                             val.lotValue, CellType.Lot, val.notReadCommentsCount, val.isEdited, !!val.fieldMetas?.length, lotType
    //                         )}
    //                     >
    //                         <i className='roche-icon sm action-more-dots' style={{cursor: getCursorStyle()}}/>
    //                     </Dropdown>
    //                 </div>
    //                 {commentContainer}
    //             </Row>
    //         </span>) :
    //         (<Row className='table-cell' style={{width: '100%'}}>            
    //             <Col>
    //                 <span className='not-available'>N/A</span>
    //             </Col>
    //             {commentContainer}
    //         </Row>);
    // };

    const columnWidth = 300;

    const getCellBlockId = (record: SummaryReportTableModel, documentId: string) => record.sectionId + record.topic + documentId + 'specification';

    const isValueDifferentOfPrevious = (val: string | null, oldVal: string | null) => {
        return val !== oldVal;
    };

    const columns: ColumnProps<SummaryReportTableModel>[] = [
        {
            title: 'Topic',
            key: 'topic',
            dataIndex: 'topic',
            fixed: 'left',
            width: columnWidth
        },
        ...store.protocolDocs.map((doc, index) => {
            return {
                title: `Protocol v.${index + 1}`,
                key: `protocol${index + 1}`,
                dataIndex: `protocol${index + 1}`,
                width: columnWidth,
                onCell: (record: SummaryReportTableModel) => {
                    const cellBlockId = getCellBlockId(record, doc.documentId);
                    return {
                        className: store.highlightedBlock?.blockId === cellBlockId ? 'highlighted-block-cell' : '',
                        'data-cell-id': cellBlockId,
                        id: cellBlockId,
                        width: columnWidth
                    };
                },
                render: (__: unknown, val: SummaryReportTableModel) => {
                    var cellProtocolData = val.protocolData.find(p => p.documentId === doc.documentId);
                    if (!cellProtocolData) {
                        return null;
                    }

                    let isDifferentVal = false;
                    if (index !== 0) {
                        let prevDoc = store.protocolDocs[index - 1];
                        let prevProtocolData = val.protocolData.find(p => p.documentId === prevDoc.documentId);
                        isDifferentVal = prevProtocolData ? isValueDifferentOfPrevious(cellProtocolData.specValue, prevProtocolData.specValue) : false;
                    }

                    const cellBlockId = getCellBlockId(val, doc.documentId);
                    return specValueRenderer(cellProtocolData, val, cellProtocolData, cellBlockId, isDifferentVal, doc.documentId);
                }
            };
        })
        // {
        //     title: 'Protocol v.1',
        //     key: 'specification',
        //     dataIndex: 'specification',
        //     width: columnWidth,
        //     onCell: (record: SummaryReportTableModel) => { 
        //         const cellBlockId = getCellBlockId(record);
        //         return {
        //             className: store.highlightedBlock?.blockId === cellBlockId ? 'highlighted-block-cell' : '',
        //             'data-cell-id': cellBlockId,
        //             id: cellBlockId,
        //             width: columnWidth
        //         };
        //     },
        //     render: (val, record) => {
        //         const cellBlockId = getCellBlockId(record);
        //         return specValueRenderer(val, record, record.specification, cellBlockId);
        //     }
        // },
        // ...store.currentProjectResultRange.map(resultNumber => {
        //     const lotType = ResultHelpers.createLotType(resultNumber);
        //     const protocolVersion = `v.${resultNumber + 1}`;
        //     return {
        //         title: `Protocol ${protocolVersion}`,
        //         key: `protocol${protocolVersion}`,
        //         dataIndex: `protocol${protocolVersion}`,
        //         width: columnWidth,
        //         onCell: (record: SummaryReportTableModel) => {
        //             return getOnCell(record, lotType);
        //         },
        //         render: (_val: any, record: SummaryReportTableModel) => {
        //             const lot = record.resultLots.find(l => l.lotType === lotType);

        //             if (!lot) {
        //                 return null;
        //             }

        //             return lotCellRenderer(lot.lotCell, record, lotType, lot.lotCell);
        //         }
        //     };
        // }
        // )
    ];

    // const getOnCell = (record: SummaryReportTableModel, lotType: LotType) => {
    //     const cellBlockId = record.sectionId + record.topic + lotType;
    //     return {
    //         className: store.highlightedBlock?.blockId === cellBlockId ? 'highlighted-block-cell' : '',
    //         'data-cell-id': cellBlockId,
    //         id: cellBlockId,
    //         width: undefined
    //     };
    // };

    const getTableClassName = () => {
        let className = store.isConciseViewEnabled
            ? 'concise roche-table without-cell-scrollbar'
            : 'roche-table without-cell-scrollbar';

        return className;
    };

    const renderSummaryReportTable = (specType: string) => {
        if (store == null) { 
            return null;
        }

        const sections = store.getSectionsForSpecType(specType);

        if (sections == null || sections.length === 0) {
            return null;
        }

        return (
            <Collapse activeKey={store.activeSections} onChange={store.setActiveSections} style={{ width: tabsWidth }}>
                {sections.map(s=> 
                    <Panel
                        key={s.sectionId}
                        showArrow={false}
                        header={
                            <div className="panel-header">
                                <RightOutlined rotate={store.activeSections.includes(s.sectionId) ? 90 : 0} />
                                <span>{s.sectionName}</span>
                            </div>
                        }
                    >
                        <Table
                            loading={store.isLoading}
                            className={getTableClassName()}
                            columns={columns}
                            dataSource={store.tableDataSourceWithHiddenParts?.filter(t=> t.sectionId === s.sectionId) || []}
                            pagination={false}
                            rowKey={r => r.topic}
                            bordered
                        />
                    </Panel>
                )}
            </Collapse>
        );
    };

    const [tabsWidth, setTabsWidth] = React.useState(0);

    const calculateWidth = () => {
        const scrollable = document.getElementsByClassName('ant-tabs ant-tabs-top')[0];

        setTabsWidth(scrollable.scrollWidth);

        const tabsNav = document.getElementsByClassName('ant-tabs-nav')[0] as HTMLDivElement;
        
        tabsNav.style.width = `${scrollable.scrollWidth}px`;
    };

    React.useEffect(() => {
        calculateWidth();
        window.addEventListener('resize', calculateWidth);
        return () => window.removeEventListener('resize',calculateWidth);
    }, []);
    
    if (store == null) {
        return null;
    }

    const closeViewDataModal = () => {
        setIsEditModalVisible(false);
        setIsDataChangeModaVisible(false);
        store.setCurrentDocId(undefined);
        store.setEditedDataModalComment('');
    };

    const handleCommentDialogClose = () => {
        setIsCommentsDialogVisible(false);
        store.markReadComments(userProfileStore.userInfo.userId);
    };

    const scrollToTop = () => {
        const scrollable = document.getElementsByClassName('ant-tabs ant-tabs-top')[0];
        scrollable.scrollTo(0, 0);
    };

    return (
        <div data-testid={'summary-report-view'} id={`${store.projectId}-method-sheet-summary`} className='table-container summary-report'>
            <SummaryReportHeaderControls store={store}/>
            <CommentsDialog 
                commentsStore={commentsStore}
                isModalVisible={isCommentsDialogVisible} 
                closeDialog={handleCommentDialogClose}
                handleCommentSave={store.createComment}
            />
            <EditValueDialog 
                store={store} 
                isModalVisible={isEditModalVisible} 
                isDataChangeModalVisible={isDataChangeModalVisible} 
                closeDialog={closeViewDataModal}
            />
            <SetFinalResultDialog store={store} isModalVisible={isFinalResultModaltVisible} closeDialog={() => setIsFinalResultModaltVisible(false)}/>
            <div className="summary-report-content">
                <UpCircleOutlined className='scroll-to-top' onClick={scrollToTop}/>
                <Tabs 
                    activeKey={store.currentSpecTypeId} 
                    defaultActiveKey={SpecificationTypes.Protocol} 
                    onChange={(id) => store.setCurrentSpecType(id)}
                    tabBarStyle={{display: 'block'}}
                >
                    <TabPane 
                        // tab={
                        //     <span className='spec-name'>
                        //         {'Protocol'}
                        //         <div className={`unread-comment-indicator ${store.reagentHasUnreadComment ? 'visible': ''}`}/>
                        //     </span>}
                        tab={'Protocol'}
                        key={SpecificationTypes.Protocol}>
                        {renderSummaryReportTable(SpecificationTypes.Protocol)}
                    </TabPane>
                    <TabPane 
                        // tab={
                        //     <span className='spec-name'>
                        //         {'Amendments'}
                        //         <div className={`unread-comment-indicator ${store.calibratorsHasUnreadComment ? 'visible': ''}`}/>
                        //     </span>}
                        tab={'Amendments'} 
                        key={SpecificationTypes.Amendments}
                    >
                        {renderSummaryReportTable(SpecificationTypes.Amendments)}
                    </TabPane>
                </Tabs>
                <DocumentPreview store={store} />
            </div>
        </div>
    );

};

export default observer(SumaryReportView);