import { RcFile } from 'antd/lib/upload';
import { LotType } from '../../summary_report/types/TopicLotResult';
import appClient from '../../common/services/AppClient';
import { ReportStatus } from '../types/ApplicationReport';
import { ReportDocument } from '../types/Document';
import security from '../../common/services/SecurityService';
import { AlphaPackage } from '../../common/types/AlphaPackage';
import { DocumentTopicsResult } from '../types/DocumentTopicsResult';
import { CommentData } from '../types/CommentData';

export default class ApplicationReportsService {
    async uploadDocument(
        projectId: string, 
        file: RcFile, 
        reportStatus: ReportStatus, 
        lot: LotType, 
        section: string, 
        topic: string, 
        specTypeId: string,
        // eslint-disable-next-line no-unused-vars
        skipAnalysis: boolean = false
    ) {
        const formData = new FormData();
        formData.append('projectId', projectId);
        formData.append('file', file);
        formData.append('reportStatus', reportStatus);
        formData.append('lot', lot);
        formData.append('section', section);
        formData.append('topic', topic);
        formData.append('specificationTypeId', specTypeId);
        
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/documents`;
        return await appClient.post<ReportDocument>(url, formData);
    }

    async addExisitingDocument(projectId: string, packageId: string, reportStatus: ReportStatus, specTypeId: string, lot: LotType, section: string, topic: string) {
        const request = {
            projectId,
            packageId,
            reportStatus,
            specificationTypeId: specTypeId,
            lot,
            section,
            topic
        };
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/documents/${packageId}`;
        return appClient.post<ReportDocument>(url, request);
    }

    async getDocuments(projectId: string) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/documents`;
        const resp = await appClient.get<ReportDocument[]>(url);
        return resp.unwrapOr(Array<ReportDocument>());
    }

    async deleteDocument(projectId: string, docId: string) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/documents/${docId}`;
        return appClient.delete(url);
    }

      
    downloadDocument(projectId: string, docId: string) {
        const encodedDocId = encodeURIComponent(docId);
        const encodedProjId = encodeURIComponent(projectId);
        security.invoke((token) => {
            const t = '?access_token=' + encodeURIComponent(token);
            const url = `${process.env.REACT_APP_SERVICE_URL}projects/${encodedProjId}/documents/${encodedDocId}/download${t}&useOverrideDocument=true`;
            window.open(url);
            return Promise.resolve();
        });
    }

    async getAllPackages(projectId: string) {
        const url = `${process.env.REACT_APP_MANAGE_URL}projects/${projectId}/documents?unique=true`;
        const resp = await appClient.get<AlphaPackage[]>(url);
        return resp.unwrapOr(Array<AlphaPackage>());
    }
    
    async getTopics(projectId: string) {
        const url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/documents/topics_statistics`;
        const resp = await appClient.get<DocumentTopicsResult>(url);
        return resp.unwrapOr(null);
    }

    setLotStatus(projectId: string, specificationTypeId: string, section: string, topic: string, lot: string, status: ReportStatus, comment: string, documentId?: string) {
        const request = {
            specificationTypeId,
            section,
            topic,
            lot,
            status,
            comment,
            documentId
        };
       
        let url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/documents/update_status`;
        return appClient.post<CommentData>(url, request);
    }

    setSectionStatus(projectId: string, specificationTypeId: string, section: string, status: ReportStatus, comment: string) {
        const request = {
            specificationTypeId,
            section,
            status,
            comment
        };
       
        let url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/documents/update_section_status`;
        return appClient.post<CommentData[]>(url, request);
    }

    setTopicStatus(projectId: string, specificationTypeId: string, section: string, topic: string, status: ReportStatus, comment: string) {
        const request = {
            specificationTypeId,
            section,
            topic,
            status,
            comment
        };
       
        let url = `${process.env.REACT_APP_SERVICE_URL}projects/${projectId}/documents/update_topic_status`;
        return appClient.post<CommentData[]>(url, request);
    }
}