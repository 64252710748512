import * as React from 'react';
import { observer } from 'mobx-react-lite';
import FormItem from 'antd/lib/form/FormItem';
import ReactQuill from 'react-quill';
import { Utils } from '../../common/misc/Utils';
import { FormInstance } from 'antd';

type Props = {
    label: string;
    form: FormInstance;
    value?: string;
    isRequired?: boolean;
    isDisabled?: boolean
};
const CommentForm: React.FC<Props> = ({label, isRequired, isDisabled, value, form}) => {
    const handleOnChange = (val: string)=> {
        if (val === '<p><br></p>') { 
            form.setFieldsValue({comment: ''});
        }
    };
    return (
        <div className="comment-input-container comment-form-item">
            <div className="comment-input-wrapper" data-id={'input-comment'}>
                <FormItem
                    colon={false}
                    label={label}
                    name="comment"
                    initialValue=""
                    required={!!isRequired}
                    rules = {[{ required: !!isRequired, message: 'Please input comment!', whitespace: true }]}
                >
                    {!isDisabled ?<ReactQuill
                        onChange={handleOnChange}
                        modules={Utils.getQuilModules()}
                        className={'comment-input alpha-quill'}
                    /> : 
                        <div dangerouslySetInnerHTML={{__html: value!}}/>}
                </FormItem>
            </div>
        </div>
    );
};

export default observer(CommentForm);

