import { Button, Card, Dropdown, Menu, Table, Tooltip, Upload, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { RcFile } from 'antd/lib/upload';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import ConfirmationDialog from '../../common/components/ConfirmationDialog';
import NewContractDialog from '../../application_report/components/NewContractDialog';
import { Utils } from '../../common/misc/Utils';
import { PackageState } from '../../common/types/PackageState';
import { ProjectDashboardStore } from '../stores';
import { SpecificationDocument } from '../types/SpecificationDocument';
// import { UploadCommentDialog } from '../../summary_report/components';

type Props = {
    store: ProjectDashboardStore
};

const SpecificationDocumentTable: React.FC<Props> = ({store}) => {
    const [isSelectDocDialogVisible, setIsSelectDocDialogVisible] = React.useState<boolean>(false);
    const [isConfirmationDialogVisibile, setIsConfirmationDialogVisibile] = React.useState(false);
    // const [isUploadCommentDialogVisible, setIsUploadCommentDialogVisible] = React.useState(false);
    // const [fileToUploadWithoutAnalysis, setFileToUploadWithoutAnalysis] = React.useState<RcFile | null>(null);
    // const [currentRecord, setCurrentRecord] = React.useState<SpecificationDocument | null>(null);

    React.useEffect(() => {
        if (!isSelectDocDialogVisible) {
            store.resetNewContractDialogState();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },              [isSelectDocDialogVisible]);

    const getStatus = (state: string, errorMessage: string | null) => {
        switch (state) {
        case PackageState.Completed:
            return (
                <span data-id-name="Status" className="status completed">Completed</span>
            );
        case PackageState.Broken:
            return ( 
                <Tooltip title={errorMessage}>
                    <span data-id-name="Status" className="status broken">Broken</span>
                </Tooltip>
            );
        case PackageState.NotUploaded:
            return (
                <span data-id-name="Status" className="status notUploaded">Not uploaded</span>
            );
        case PackageState.Uploading:
            return (
                <span data-id-name="Status" className="status notUploaded">Uploading</span>
            );
        case PackageState.Importing:
            return (
                <span data-id-name="Status" className="status importing">Importing</span>
            );
        case PackageState.Processing:
            return (
                <span data-id-name="Status" className="status processing">Processing</span>
            );
        case PackageState.UploadedWithoutAnalysis:
            return (
                <span data-id-name="Status" className="status without-analysis">Completed without extraction</span>
            );
        default: return '';
        }
    };

    const handleBeforeUpload = async(file: RcFile, skipAnalysis: boolean = false, comment?: string) => {
        const specTypeId = 'protocol-document';
        await store.updateSpecification({
            projectId: store.project!.id,
            documentId: '',
            status: PackageState.Uploading,
            specificationTypeId: specTypeId,
            uploadedBy: '',
            uploadedTime: '',
            errorMessage: null
        });
        const resp = await store.uploadSpecDoc(specTypeId, file, skipAnalysis, comment);

        if (resp.isErr()) {
            await store.updateSpecification({
                projectId: store.project!.id,
                documentId: '',
                status: PackageState.Broken,
                specificationTypeId: specTypeId,
                uploadedBy: '',
                uploadedTime: '',
                errorMessage: null
            });

            message.error(resp.error.data?.title ?? 'Failed to upload document');
        }

        return false;
    };

    // const handleBeforeUploadWithoutAnalysis = (file: RcFile, record: SpecificationDocument) => {
    //     setCurrentRecord(record);
    //     setFileToUploadWithoutAnalysis(file);
    //     setIsUploadCommentDialogVisible(true);
    //     return false;
    // };

    // const handleUploadCommentSubmit = (comment: string) => {
    //     if (currentRecord && fileToUploadWithoutAnalysis) {
    //         handleBeforeUpload(fileToUploadWithoutAnalysis, currentRecord, true, comment);
    //     }
    //     setIsUploadCommentDialogVisible(false);
    // };

    // const handleDocumentSelect = (spectTypeId: string) => {
    //     store.setUploadSpecType(spectTypeId);
    //     setIsSelectDocDialogVisible(true);
    // };

    const handleDocumentRemove = (docId: string) => {
        store.setSpecDocToRemove(docId);
        setIsConfirmationDialogVisibile(true);
    };

    const tableActionsMenu = (record: SpecificationDocument) => {
        const {projectId, id} = record;
        const docName = record.overridingPackageName ?? record.name;
        return(
            <Menu className='table-actions-menu' data-id-type="popup-menu-list-root" data-id-name={record.specificationTypeName}>
                <Menu.Item key='open' className='bordered' data-id-type="popup-menu-list-item">
                    <a 
                        href={`${process.env.PUBLIC_URL}/projects/${projectId}/specification_documents/${encodeURIComponent(id)}/${encodeURIComponent(docName)}`} 
                        target="_blank" rel="noreferrer"
                    >
                        <div data-testid='open-spec-doc' className='action-container'>
                            <i className="roche-icon md doc" />
                            <div>Open</div>
                        </div>
                    </a>
                </Menu.Item>
                <Menu.Item key='download' data-id-type="popup-menu-list-item">
                    <div className='action-container' data-testid='download-spec-doc' onClick={() => store.handleSpecDownload(projectId, id, true)}>
                        <i className="roche-icon md download" />
                        <div>Download</div>
                    </div>
                </Menu.Item>
                {/* <Observer>
                    {() => (
                        <Upload
                            showUploadList={false}
                            beforeUpload={(file: RcFile) => handleBeforeUpload(file)}
                            headers={store.headers}
                            style={{width: '100%'}}
                        >
                            <Menu.Item key='upload' data-id-type="popup-menu-list-item">
                                <div className='action-container'>
                                    <i className="roche-icon md upload" />
                                    <div>Upload</div>
                                </div>
                            </Menu.Item>
                        </Upload>
                    )}
                </Observer>
                <Menu.Item key='select-doc' data-id-type="popup-menu-list-item" onClick={() => handleDocumentSelect(record.specificationTypeId)}>
                    <div className='action-container'> 
                        <i className="roche-icon md raw-doc" />
                        <div>Select document</div>
                    </div>
                </Menu.Item> */}
                {/* {record.specificationTypeId !== 'MethodSheet' && (
                    <Observer>
                        {() => (
                            <Upload
                                showUploadList={false}
                                beforeUpload={(file: RcFile) => handleBeforeUploadWithoutAnalysis(file, record)}
                                headers={store.headers}
                                style={{width: '100%'}}
                                disabled={![PackageState.UploadedWithoutAnalysis, PackageState.Completed].includes(record.status)}
                            >
                                <Menu.Item 
                                    key='upload' 
                                    data-id-type="popup-menu-list-item" 
                                    disabled={![PackageState.UploadedWithoutAnalysis, PackageState.Completed].includes(record.status)}
                                >
                                    <div className='action-container'>
                                        <i className="roche-icon md upload" />
                                        <div>Upload document without analysis</div>
                                    </div>
                                </Menu.Item>
                            </Upload>
                        )}
                    </Observer>
                )} */}
                <Menu.Item  className='bordered-top' key='remove' data-id-type="popup-menu-list-item">
                    <div className='action-container' data-testid='remove-spec-doc' onClick={() => handleDocumentRemove(record.id)}>
                        <i className="roche-icon md remove" />
                        <div className='remove'>Remove</div>
                    </div>
                </Menu.Item>
            </Menu>
        );
    };

    // const uploadActionsMenu = (record: SpecificationDocument, isProcessing: boolean) => {
    //     return(
    //         <Menu className='table-actions-menu' style={{minWidth: 180}} data-id-type="popup-menu-list-root" data-id-name={record.specificationTypeName}>
    //             <Observer>
    //                 {() => (
    //                     <Upload
    //                         showUploadList={false}
    //                         beforeUpload={(file: RcFile) => handleBeforeUpload(file, record)}
    //                         headers={store.headers}
    //                         disabled={isProcessing}
    //                     >
    //                         <Menu.Item key='upload-doc' data-id-type="popup-menu-list-item">
    //                             <div data-testid='open-spec-doc' className='action-container'> 
    //                                 <i className="roche-icon md checked-doc" />
    //                                 <div>Upload document</div>
    //                             </div>
    //                         </Menu.Item>                                    
    //                     </Upload>
    //                 )}
    //             </Observer>
    //             <Menu.Item key='select-doc' data-id-type="popup-menu-list-item" onClick={() => handleDocumentSelect(record.specificationTypeId)}>
    //                 <div className='action-container'> 
    //                     <i className="roche-icon md raw-doc" />
    //                     <div>Select document</div>
    //                 </div>
    //             </Menu.Item>
    //         </Menu>);
    // };

    const getCursorStyle = () => {
        return store.project!.isArchived ? 'not-allowed' : 'pointer';
    };

    const columns: ColumnProps<SpecificationDocument>[] = [
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            sorter: (a: SpecificationDocument, b: SpecificationDocument) => Utils.safeStringLocaleCompare(a.overridingPackageName ?? a.name, b.overridingPackageName ?? b.name),
            width: 300,
            render: (value, record: SpecificationDocument) => {
                return (
                    <Tooltip title={record.overridingPackageName ?? value }>
                        <div className='name' data-id-cells='name'>
                            {record.overridingPackageName ?? value 
                                ? <i className='roche-icon sm doc' style={{verticalAlign: 'text-top', marginRight: 16, backgroundSize: 'contain'}} /> 
                                : null}
                            {record.overridingPackageName ?? value}
                        </div>
                    </Tooltip>);
            }
        },
        {
            title: 'Type',
            key: 'specificationTypeName',
            dataIndex: 'specificationTypeName',
            sorter: (a: SpecificationDocument, b: SpecificationDocument) => Utils.safeStringLocaleCompare(a.specificationTypeName, b.specificationTypeName),
            render: (value) => {
                return <span data-id-cells='type'>{value}</span>;
            }
        },
        {
            title: 'Date Uploaded',
            key: 'uploadedTime',
            dataIndex: 'uploadedTime',
            sorter: (a: SpecificationDocument, b: SpecificationDocument) => Utils.safeDateCompare(new Date(a.uploadedTime) , new Date(b.uploadedTime)),
            render: (value) => {
                return (<span data-id-cells='uploadedDate'>{Utils.formatDateStringShort(value, true, true)}</span>);
            }
        },
        {
            title: 'Uploaded By',
            key: 'uploadedBy',
            dataIndex: 'uploadedBy',
            sorter: (a: SpecificationDocument, b: SpecificationDocument) => Utils.safeStringLocaleCompare(a.uploadedBy, b.uploadedBy),
            render: (value) => {
                return <span data-id-cells='uploadedBy'>{value}</span>;
            }
        },
        {
            title: 'Version',
            key: 'version',
            dataIndex: 'version',
            render: (_, __, index) => {
                return <span data-id-cells='version'>{index + 1}</span>;
            }
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            sorter: (a: SpecificationDocument, b: SpecificationDocument) => Utils.safeStringLocaleCompare(a.status, b.status),
            render: (value, record) => {
                return <span data-id-cells='status'>{getStatus(value, record.errorMessage)}</span>;
            }
        },
        {
            key: 'actions',
            dataIndex: 'actions',
            render: (_: string, record: SpecificationDocument) => {
                if ([PackageState.Completed, PackageState.Broken, PackageState.UploadedWithoutAnalysis].includes(record.status)) {
                    return  (
                        <div data-id-cells='actions'>
                            <Dropdown
                                trigger={['click']}
                                overlay={tableActionsMenu(record)}
                                disabled={store.project!.isArchived}
                            >
                                <i data-testid="spec-actions" className="roche-icon md action-more-dots" style={{cursor: getCursorStyle()}}/>
                            </Dropdown>
                        </div>
                    );
                } else {
                    return null;
                }
            }
        },

    ];

    const documentRemoveConfirmation = {
        confirmButtonText: 'Remove document',
        cancelHandler: () => setIsConfirmationDialogVisibile(false)
    };

    const getDeleteDocConfirmationMessage = () => {
        const doc = store.getSpecDocById(store.specDocToRemove);
        return doc ? 
            <> Are you sure you want to remove {<span>{`${doc.name} `}</span>} 
            from {<span>Protocols and Amendments</span>}: {<span>{doc.specificationTypeName}</span>}.</> : <></>;
    };

    const getProtocolUploadButton = () => {
        return (
            <Upload
                showUploadList={false}
                beforeUpload={(file: RcFile) => handleBeforeUpload(file)}
                headers={store.headers}
            >
                <Button>
                    Upload document
                </Button>                                    
            </Upload>
        );
    };

    return (
        <>
            {isSelectDocDialogVisible && <NewContractDialog 
                addExistingDocument={store.addExistingDocument}
                alphaPackagesStore={store.alphaPackageStore}
                visible={isSelectDocDialogVisible} 
                closeDialog={() => setIsSelectDocDialogVisible(false)}
            />}
            <ConfirmationDialog 
                text={getDeleteDocConfirmationMessage()}
                confirmation={documentRemoveConfirmation} 
                visible={isConfirmationDialogVisibile} 
                confirmHandler={() => { 
                    store.deleteSpecificationDocument(); 
                    setIsConfirmationDialogVisibile(false);
                }} 
            />
            <Card 
                data-id='PSDs-and-Method-Sheet' 
                className='specifiaction-container' 
                title='Protocols and Amendments' 
                extra={getProtocolUploadButton()}
                bordered={false}
            >
                <div className='table-container-content'>
                    <Table
                        className='roche-table specification-table'
                        columns={columns}
                        dataSource={store.specTableModel}
                        pagination={false}
                        rowKey={r => r.id}
                        loading={store.isLoadingSpecifications}
                    />
                </div>
            </Card>
        </>
    );

};


export default observer(SpecificationDocumentTable);