import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu, Row, Col, Tooltip } from 'antd';
import { useNavigate } from 'react-router';
import _ from 'lodash';
import { Tab, TabType } from '../../common/stores/TabsStore';
import TabsStore from '../../common/stores/TabsStore';

type Props = {
    tabsStore: TabsStore
};

export const DocumentTabs: React.FC<Props> = ({tabsStore}) => {
    const navigate = useNavigate();
    const [hiddenTabsCount, setHiddenTabsCount] = React.useState(0);

    const calculateHiddenTabs = React.useCallback(() => {
        const visibleTabsContainer = document.getElementsByClassName('document-tabs-container');
        let visibleTabsCount = 0;

        if (visibleTabsContainer && visibleTabsContainer[0]) {
            const tabsContainer = visibleTabsContainer[0];
            const tabs = tabsContainer.getElementsByClassName('document-tab');
            visibleTabsCount = Array.from(tabs).filter(x => (x as HTMLElement).style.opacity !== '0').length;
        }   
        setHiddenTabsCount(tabsStore.tabs.length - visibleTabsCount);
    }, [tabsStore.tabs.length]);

    React.useEffect(() => {
        calculateHiddenTabs();
        window.addEventListener('resize', _.debounce( calculateHiddenTabs, 200));

        return(() => {
            window.removeEventListener('resize', calculateHiddenTabs);
        });
    },              [calculateHiddenTabs]);

    React.useEffect(() => {
        // Timeout needed to let tab be moved to submenu first
        setTimeout(calculateHiddenTabs, 100);
    },              [calculateHiddenTabs, tabsStore.tabs.length]);

    const determineMenuSelection = () => {
        if (tabsStore.selectedTab) {
            return [`tab-${tabsStore.tabs.map(t=> t.tabId).indexOf(tabsStore.selectedTab)}`];
        }

        return [];
    };

    
    const selectTab = (projectId: string) => {
        if (!projectId) {
            navigate('projects');
        } else {
            tabsStore.setSelectedTab(projectId);
            navigate(`projects/${projectId}`);
        }
    };

    if (!tabsStore.tabs || tabsStore.tabs.length === 0) {
        return null;
    }

    
    const getTabTitle = (t: Tab) => {
        if (t.type === TabType.Project) {
            return (
                <>
                    <i className="roche-icon md middle folder" />
                    <span className="title-text">{t.name}</span> 
                </>
            );
        }

        return (
            <>
                <i className="roche-icon md middle doc" />
                <span className="title-container">
                    <div className="main-title-text">{t.name}</div> 
                    <div className="subtitle-text">{t.subTitle}</div> 
                </span>
            </>
        );
    };

    return (
        <Menu
            theme="light"
            mode="horizontal"
            subMenuCloseDelay={1}
            style={{ lineHeight: '50px' }}
            className="document-tabs-container"
            selectedKeys={determineMenuSelection()}
            overflowedIndicator={<span style={{display: 'inline-block', width: 150}}><i className="alpha-icon xs overflow-indicator" /> {hiddenTabsCount} more...</span>}
        >
            {tabsStore.tabs && tabsStore.tabs.length > 0 && tabsStore.tabs.map((t, i) => (
                <Menu.Item
                    key={`tab-${i}`}
                    className={`document-tab ${tabsStore.isSelected(t) ? 'selected' : ''}`}
                    style={{ height: 50, lineHeight: '50px' }}
                >
                    <Tooltip placement='bottom' title={t.name}>
                        <Row align="middle" justify='space-between'>
                            <Col span={19}  onClick={() => selectTab(t.tabId)}>
                                <div className={`${t.type === TabType.Project ? 'tab-title' : 'tab-title report'}`}>
                                    {getTabTitle(t)}
                                </div>
                            </Col>
                            <Col span={4} style={{ textAlign: 'right' }}>
                                <span className="btn-container">
                                    <span className="document-tab-btn" onClick={() => tabsStore.closeTab(t.tabId, selectTab)}>
                                        <i className="roche-icon md close-tab middle" />
                                    </span>
                                </span>
                            </Col>
                        </Row>
                    </Tooltip>
                </Menu.Item>
            ))}
        </Menu>
    );
};

export default observer(DocumentTabs);