import { Button, Modal, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

type Props = {
    visible: boolean;
    handleCancel: () => void;
    handleSubmit: (type: string) => void
};

const DocumentTypeDialog: React.FC<Props> = ({visible, handleCancel, handleSubmit}) => {
    const types = ['Type 1', 'Type 2'];
    const [selectedType, setSelectedType] = React.useState(types[0]);

    React.useEffect(() => {
        setSelectedType(types[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    
    return (
        <Modal 
            title="Select document type"
            className="roche-client-dialog"
            onCancel={handleCancel}
            onOk={() => handleSubmit(selectedType)}
            visible={visible}
            closable={false}
            maskClosable={false}
            width={500}
            footer={[(
                <Button 
                    key="cancel-button"
                    className="light"
                    size="large"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            ), (
                <Button 
                    data-testid="project-upload-document-submit"
                    key="submit-button"
                    type="primary" 
                    size="large"
                    onClick={() => handleSubmit(selectedType)}
                >
                    Upload document
                </Button>
            )
            ]}
        >
            <Select 
                onChange={setSelectedType}
                value={selectedType}
                style={{width: '100%'}}
                options={types.map(t => { 
                    return { label: t, value: t}; 
                })}
            />
        </Modal>
    );
};

export default observer(DocumentTypeDialog);