import { ProjectStatus } from '../method_sheet_report/models/types';
import { CommentData } from '../projects/types/CommentData';

export type HistoryResult = {
    projectItems: ProjectHistoryItem[];
    totalCount: number
};

export type ProjectHistoryItem = {
    projectId: string;
    projectFullId: string;
    name: string;
    creationDate: string;
    developmentLead: string;
    status: ProjectStatus;
    analyzers: AnalyzerData[];
    topicHistoryItems: TopicHistoryItem[]
};

export type TopicHistoryItem = {
    topic: string;
    oldValue: string;
    currentValue: string;
    section: string;
    lot: string | null;
    specification: string;
    topicHistoryItemType: TopicHistoryItemType;
    updateDate: string;
    isEdited: boolean;
    comments: CommentData[];
    topicHistorySources: TopicHistorySource[]
};

export type TopicHistorySource = {
    isManual: boolean;
    userName: string;
    changeDate: string;
    projectId: string;
    documentId: string;
    page: string;
    source: string;
    documentUrl: string;
    value: string | null
};

export enum TopicHistoryItemType {
    PSD = 'PSD',
    AR1 = 'AR1',
    AR2 = 'AR2',
    AR3 = 'AR3',
    MS = 'MS'
}

type AnalyzerData = {
    id: string;
    name: string
};