import * as React from 'react';
import security from '../services/SecurityService';

export default class NoRolesError extends Error {
    errorScreen: React.ReactElement;
    
    constructor() {
        super();
        this.errorScreen = (
            <div className="no-access-to-portal">You do not have permission to access Alpha.<br/>
            Please request access via CIDM or contact support.
            </div>);
    }

    logout() {
        security.inst.logout();
    }
}