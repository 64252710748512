import {  Popconfirm, Spin, Table, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { Utils } from '../../common/misc/Utils';
import { ProjectsStore } from '../stores';
import { Project } from '../types';
import ProjectCreateDialog from './ProjectCreateDialog';
import ProjectListHeaderControls from './ProjectListHeaderControls';
import DuplicateProjectDialog from './DuplicateProjectDialog';

type Props = {
    store: ProjectsStore
};

const ProjectsList: React.FC<Props> = ({store}) => {
    const [createDialogVisible, setCreateDialogVisible] = React.useState(false);
    const [duplicateDialogVisible, setDuplicateDialogVisible] = React.useState(false);
    
    const navigate = useNavigate();

    const handleOpenProject = (projectId: string) => {
        navigate(`${projectId}`);
    };

    React.useEffect(() => {
        store.fetchLayoutConfig().then(() => {
            // Do nothing
        }).catch((err) => {
            console.error(err);
        });

        store.getProjects(undefined, store.isArchivedViewEnabled, undefined).then(() => {
            // Do nothing
        }).catch((err) => {
            console.error(err);
        });
    }, [store]);

    // const generateStatusIcon = (state: string | null) => {
    //     switch (state) {
    //     case 'Completed':
    //         return (
    //             <span data-id-name="Status" className="project-status" style={{background: '#9BA0AA', fontSize: 11}}>Completed</span>
    //         );
    //     case 'InReview':
    //         return (
    //             <span data-id-name="Status" className="project-status" style={{background: '#FA8C16', fontSize: 11}}>In review</span>
    //         );
    //     case 'NotStarted':
    //         return (
    //             <span data-id-name="Status" className="project-status" style={{background: '#9BA0AA', fontSize: 11}}>Not started</span>
    //         );
    //     case 'InProgress':
    //         return (
    //             <span data-id-name="Status" className="project-status" style={{background: '#162242', fontSize: 11}}>In progress</span>
    //         );
    //     default: return;
    //     }
    // };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleTableChange = (pag: any, filt: any, sorter: any) => {
        store.setSortOrder({field: sorter.field, order: sorter.order});
    };

    const handleEditClick = (e:  React.MouseEvent, projectId: string) => {
        e.stopPropagation();
        store.setSelectedProject(projectId);
        setCreateDialogVisible(true);
    };

    const handleRestoreClick = (e:  React.MouseEvent, projectId: string) => {
        e.stopPropagation();
        store.restoreProject(projectId);
    };

    const columns = [{
        dataIndex: 'id',
        title: 'ID',
        sorter: (a: Project, b: Project) => Utils.safeStringLocaleCompare(a.id, b.id),
        render: (id: string, project: Project) => project.shortId ?? id
    },{
        dataIndex: 'name',
        title: 'Project Name',
        sortOrder: store.getSortOrder('name'),
        sorter: (a: Project, b: Project) => Utils.safeStringLocaleCompare(a.name, b.name),
    },{
        dataIndex: 'protocolTitle',
        title: 'Protocol Title',
        sortOrder: store.getSortOrder('protocolTitle'),
        sorter: (a: Project, b: Project) => Utils.safeStringLocaleCompare(a.protocolTitle, b.protocolTitle),
    }, {
        dataIndex: 'sponsorProtocolId',
        title: 'Sponsor Protocol ID',
        sortOrder: store.getSortOrder('sponsorProtocolId'),
        sorter: (a: Project, b: Project) => Utils.safeStringLocaleCompare(a.sponsorProtocolId, b.sponsorProtocolId),
    },{
        dataIndex: 'responsibleParty',
        title: 'Responsible Party',
        sortOrder: store.getSortOrder('responsibleParty'),
        sorter: (a: Project, b: Project) => Utils.safeStringLocaleCompare(a.responsibleParty, b.responsibleParty),
    },{
        dataIndex: 'indication',
        title: 'Indication',
        sortOrder: store.getSortOrder('indication'),
        sorter: (a: Project, b: Project) => Utils.safeStringLocaleCompare(a.indication, b.indication),
    },{
        dataIndex: 'intervention',
        title: 'Intervention',
        sortOrder: store.getSortOrder('intervention'),
        sorter: (a: Project, b: Project) => Utils.safeStringLocaleCompare(a.intervention, b.intervention),
    },{
        dataIndex: 'phase',
        title: 'Phase',
        sortOrder: store.getSortOrder('phase'),
        sorter: (a: Project, b: Project) => Utils.safeStringLocaleCompare(a.phase, b.intervention),
    },
    {
        dataIndex: 'createdBy',
        title: 'Created by',
        sortOrder: store.getSortOrder('createdBy'),
        sorter: (a: Project, b: Project) => Utils.safeStringLocaleCompare(a.createdBy, b.createdBy),

    },{
        dataIndex: 'createdDate',
        title: 'Creation date',
        sortOrder: store.getSortOrder('createdDate'),
        sorter: (a: Project, b: Project) => Utils.safeDateCompare(new Date(a.createdDate) , new Date(b.createdDate)),
        render: (dateString: string) => {
            return Utils.formatDateStringShort(dateString, true, true);
        }
    },{
        dataIndex: 'changedBy',
        title: 'Updated by',
        sortOrder: store.getSortOrder('changedBy'),
        sorter: (a: Project, b: Project) => Utils.safeStringLocaleCompare(a.changedBy, b.changedBy),
    },{
        dataIndex: 'changedDate',
        title: 'Update date',
        sortOrder: store.getSortOrder('changedDate'),
        sorter: (a: Project, b: Project) => Utils.safeDateCompare(new Date(a.changedDate) , new Date(b.changedDate)),
        render: (dateString: string) => {
            return Utils.formatDateStringShort(dateString, true, true);
        }
    }, {
        title: 'Actions',
        key: 'actions',
        sortOrder: store.getSortOrder('actions'),
        width: 110,
        render: (_: unknown, record: Project) => {
            return (
                <div className='table-actions'>
                    <div style={{ display: 'flex' }}>
                        {store.isArchivedViewEnabled ? 
                            <Popconfirm 
                                title="Are you sure that you want to restore this project?" 
                                okText="Yes" 
                                cancelText="No" 
                                onConfirm={(e) => handleRestoreClick(e!, record.id)}
                                onCancel={(e) => e!.stopPropagation() }
                                placement='bottom'
                            >
                                <Tooltip title="Restore">     
                                    <i className='roche-icon md restore' onClick={(e) => e.stopPropagation()}/>
                                </Tooltip>
                            </Popconfirm> :
                            <>
                                <Tooltip title="Edit">     
                                    <i className='roche-icon md edit' onClick={(e) => handleEditClick(e, record.id)}/>
                                </Tooltip>
                                <Popconfirm 
                                    title="Are you sure that you want to archive this project?" 
                                    okText="Yes" 
                                    cancelText="No" 
                                    onConfirm={(e) => {
                                        store.archiveProject(record.id); e!.stopPropagation(); 
                                    }}
                                    onCancel={(e) => e!.stopPropagation() }
                                    placement='bottom'
                                    
                                >
                                    <Tooltip title="Archive">     
                                        <i className='roche-icon md archive' onClick={(e) => e.stopPropagation()}/>
                                    </Tooltip>
                                </Popconfirm>
                                <Tooltip title="Duplicate">
                                    <CopyOutlined
                                        onClick={e => {
                                            e.stopPropagation();
                                            store.setSelectedProject(record.id);
                                            setDuplicateDialogVisible(true);
                                        }}
                                    />
                                </Tooltip>
                            </>}
                    </div>
                </div>
            );
        }
    }];

    const loadingLayout = (
        <div data-testid='projects-layout-loader' style={{textAlign: 'center'}}>
            <Spin />
        </div>
    );

    return (
        <div className='table-container'>
            {createDialogVisible && <ProjectCreateDialog setVisible={setCreateDialogVisible} store={store} />}
            <DuplicateProjectDialog store={store} visible={duplicateDialogVisible} setVisible={setDuplicateDialogVisible} />
            <ProjectListHeaderControls store={store} setCreateDialogVisible={setCreateDialogVisible} />
            <div className='table-container-content'>
                {store.isLoadingLayout ? loadingLayout 
                    : <Table
                        data-testid='projects-table'
                        className='roche-table projects-table lg-title no-border-top cursor-pointer'
                        dataSource={store.projects}
                        columns={store.tableColumns.length ? store.tableColumns
                            .filter(x=> x.isVisible)
                            .map(x=> columns.find(col => col.title === x.name)!) : columns}
                        loading={store.isLoadingProjects}
                        rowKey={r => r.id}
                        onRow={(r) => ({onClick:() => handleOpenProject(r.id)})}
                        pagination={{
                            showSizeChanger: false,
                            pageSize: 20,
                            hideOnSinglePage: true
                        }}
                        onChange={handleTableChange}
                    />}
            </div>
        </div>
    );
};

export default observer(ProjectsList);