import * as React from 'react';
// import LocalStorageWorker from '../misc/StorageHelper';
import { useLocalObservable } from 'mobx-react-lite';
import UserProfileStore from './UserProfileStore';
import TabStore from './TabsStore';
import { UserService } from '../services/UserService';
import { AuditService } from '../../audit/services/AuditService';
import AuditStore from '../../audit/stores/AuditStore';

function createStore() {
    const userService = new UserService();
    const userProfileStore = new UserProfileStore(userService);
    const tabsStore = new TabStore();
    const auditService = new AuditService();
    const auditStore = new AuditStore(auditService);

    return {
        userProfileStore,
        tabsStore,
        auditStore
    };
}

const storeContext = React.createContext<ReturnType<typeof createStore> | null>(null);
type Props = {
    children: React.ReactNode
};

export const StoreProvider: React.FC<Props> = ({ children }) => {
    const store = useLocalObservable(createStore);
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
};

export const useStore = () => {
    const store = React.useContext(storeContext);
    if (!store) {
        // this is especially useful in TypeScript so you don't need to be checking for null all the time
        throw new Error('You have forgot to use StoreProvider, shame on you.');
    }
    
    return store;
};